import React, { useEffect, useState } from 'react';
import { API_URL } from '../settings';
import authHeader from '../services/authHeader';
import {
    Divider, Button, IconButton, Container, makeStyles, Paper, Slide,
    Typography, Card, CardContent, CardActions, CardHeader, Collapse, TextField
} from '@material-ui/core';
import clsx from 'clsx';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as fDate from '../services/dateFormatter.js';
import axios from 'axios';
import '../fonts/font.css';

const useStyles = makeStyles((theme) => ({
    userCard: {
        width: "100%",
        margin: "5px",
    },
    cardHeader: {
        padding: "5px",
        paddingBottom: "0px",
    },
    cardActions: {
        padding: "0px",
    },
    userInfo: {
        fontFamily: "Barlow"
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        padding: "5px",
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    expandBody: {
        paddingTop: "0px",
    },
    expandContent: {
        paddingTop: "0px",
        display: "flex",
    },
    expandColumn: {
        paddingTop: "5px",
    },
    expandButtons: {
        display: "flex",
        justifyContent: "right",
    },
    reasoningContainer: {
        width: "95%",
        margin: "auto"
    },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Create a card representing an account request that the admin can accept or deny - TRC
 * 
 * @author Thomas Campbell
 * @param {*} props All required prop information {listed below}
 *      fullname: Applicant's Fullname
        email: Applicant's Email
        role: Applicant's Role
        affiliation: Applicant's Affiliation
        timestamp: Submission Timestamp
 * @returns An instance of an Account Request Card
 */
export default function AdminAccountRequestCard(props) {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const [reason, setReason] = useState('');
    // Date variable
    const date = fDate.formatTimeDate12Hr(new Date(props.timestamp['$date']));
    // Email link (HTML) - TRC
    const emailHtml = ("mailto:" + props.email);

    let cardTitle = ((props.fullname == "") ? 
                        (<Typography variant="subtitle1" className={classes.userInfo} color="secondary">NO_NAME_ERROR - Requested Username: {props.username}</Typography>):
                        (<Typography variant="subtitle1" className={classes.userInfo}>{props.fullname} - User Request</Typography>)
                    );
    let subTitle = (<Typography variant="subtitle2">Submitted: <b>{date}</b></Typography>);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    // Return true if the admin has provided a reason - TRC
    function checkReason() {
        if(reason != "") {
            return false;
        }
        return true;
    }

    // Handle approval of an account request - TRC
    function handleApprove() {
        setExpanded(false);
        props.approveFunc(props.uuid);
    }

    // Handle denial of an account request - TRC
    function handleDeny() {
        setExpanded(false);
        props.denyFunc(props.uuid, reason);
    }

    // Set reason variable to match textfield on change - TRC
    function handleSetReason(e) {
        setReason(e.target.value);
    }

    // Master return statement - TRC
    return (
        <Slide direction="up" in={true}>
            <Card component={Paper} elevation={0} variant="outlined" className={classes.userCard}>
                <CardHeader
                    className={classes.cardHeader}
                    avatar={<PersonAddOutlinedIcon color="primary" size="large" />}
                    title={cardTitle}
                    subheader={subTitle}
                />
                <CardActions className={classes.cardActions}>
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded,
                        })}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent className={classes.expandBody}>
                        <Typography variant="subtitle1" className={classes.userInfo} align="center">Account Request Information:</Typography>
                        <Container className={classes.expandContent} >
                            <Container className={classes.expandContent}>
                                <Container className={classes.expandColumn}>
                                    <Typography variant="subtitle2" className={classes.userInfo}>Full Name: <b>{props.fullname}</b></Typography>
                                    <Typography variant="subtitle2" className={classes.userInfo}>Username: <b>{props.username}</b></Typography>
                                    { /* Provide link to email - TRC */ }
                                    <Typography variant="subtitle2" className={classes.userInfo}>Email: <b><a href={emailHtml}>{props.email}</a></b></Typography>
                                </Container>
                                <Container className={classes.expandColumn}>
                                    <Typography variant="subtitle2" className={classes.userInfo}>Role: <b>{props.role}</b></Typography>
                                    <Typography variant="subtitle2" className={classes.userInfo}>Affiliation: <b>{props.affiliation}</b></Typography>
                                    <Typography variant="subtitle2" className={classes.userInfo}>Application Date: <b>{fDate.formatDate(new Date(props.timestamp['$date']))}</b></Typography>
                                </Container>
                            </Container>
                        </Container>
                    </CardContent>
                    <Divider />
                    <Container className={classes.reasoningContainer}>
                        <br />
                        <Typography variant="subtitle2" className={classes.userInfo} align="left"><b>Admin Reasoning (<i>Required to Deny Request</i>):</b></Typography>
                        <TextField
                            margin="dense"
                            id="denialReasoning"
                            label="Reason for Denial"
                            type="string"
                            onChange={handleSetReason}
                            value={reason}
                            variant="outlined"
                            color="primary"
                            autoComplete="off"
                            multiline
                            minRows={3}
                            fullWidth
                        />
                    </Container>
                    <CardActions className={classes.expandButtons}>
                        <Button variant="contained" color="primary" onClick={handleApprove}><b>APPROVE</b></Button>
                        <Button variant="contained" color="secondary" onClick={handleDeny} disabled={checkReason()}><b>DENY</b></Button>
                    </CardActions>
                </Collapse>
            </Card>
        </Slide>
    )
}