import React, { useEffect, useState } from 'react';
import { API_URL, TOTAL_HF_HEIGHT } from '../settings';
import authHeader from '../services/authHeader';
import {
    Divider, Button, IconButton, Container, makeStyles, Paper, Slide,
    Typography, Card, CardContent, CardActions, CardHeader, Collapse, Checkbox
} from '@material-ui/core';
import clsx from 'clsx';
import * as fDate from '../services/dateFormatter.js';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import '../fonts/font.css';

const useStyles = makeStyles((theme) => ({
    userCard: {
        width: "100%",
        margin: "5px",
        padding: "5px"
    },
    cardHeader: {
        padding: "5px",
        paddingBottom: "0px",
    },
    cardActions: {
        padding: "0px",
    },
    userInfo: {
        fontFamily: "Barlow"
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        padding: "5px",
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    expandBody: {
        paddingTop: "0px",
    },
    expandContent: {
        paddingTop: "0px",
        display: "flex",
    },
    expandColumn: {
        paddingTop: "5px",
    },
    expandFooter: {
        width: "95%",
    }
}))

// Slide up transition component
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Create a card representing the most recent run of a specific cronjob from the database - TRC
 * 
 * @author Thomas Campbell
 * @editor Kaan Turkmen
 * @param {*} props All required prop information {listed below}
 *      title: The title of the cronjob
 *      timestamp: Execution timestamp
 * @returns An instance of a cronjob execution card
 * 
 * @note [04/30/2022] TRC - This card does not currently have expansion ability since
 *                          it doesnt have any information associated with it. 
 */
export default function AdminCronjobCard(props) {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const timestamp = new Date(props.timestamp['$date']);
    let lastrun = fDate.formatTimeDate12Hr(timestamp);

    // Declare title of card and subtitle of card (easier to do up here) - TRC
    let cardTitle = ( <Typography variant="subtitle1" className={classes.userInfo}>{props.title}</Typography>);
    let subTitle = (<Typography variant="subtitle2" >Last Run: {lastrun}</Typography>);

    // Handle expansion of the card [NOT CURRENTLY IN USE] - TRC
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Slide direction="up" in={true}>
            <Card component={Paper} elevation={0} variant="outlined" className={classes.userCard}>
                <CardHeader
                    className={classes.cardHeader}
                    avatar={<LiveHelpIcon color="secondary" fontSize="large" />}
                    title={cardTitle}
                    subheader={subTitle}
                />
            </Card>
        </Slide>
    )
}