import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, TextField, Button,
    Box, AppBar, Toolbar,
    FormControl, Typography,
    FormControlLabel, Switch,
    Paper, Popover, Grid, Container
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import axios from 'axios';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { InlineMath, BlockMath } from 'react-katex';
import LikelihoodSettings from '../components/LikelihoodSettings';
import ExposureSettings from '../components/ExposureSettings';
import { SettingsContext } from '../contexts/SettingsContext';
import { API_URL } from '../settings';
import authHeader from '../services/authHeader';
import { Redirect, useHistory } from 'react-router-dom';
import { generatedRankingURL } from '../urls';
import { checkBoundaries, checkNumerical } from '../services/helper';
import '../fonts/font.css';

const useStyles = makeStyles((theme) => ({
    content: {
        justifyContent: "center",
        // backgroundColor: "red"
    },
    title: {
        fontWeight: "bold",
        marginTop: "2%",
        marginBottom: "3%",
        fontFamily: "Barlow",
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
    inputBox: {
        // marginLeft: "10%",
        display: "flex",
        justifyContent: "center"
    },
    buttonBox: {
        // display: "flex",
        // marginRight: "10%",
        // justifyContent: "flex-end"
        display: "flex",
        justifyContent: "center",
        marginTop: "2%"
    },
    timeframePaper: {
        // width: "60%",
        marginLeft: "20%",
        marginRight: "20%",
        padding: 20
    },
    gridTitle: {
        fontWeight: "bold",
        margin: "2%",
        marginBottom: "3%",
        fontFamily: "Barlow",
    }
}))

/**
 * Custom Rankings Generation Page allowing user to put in custom settings and get custom rankings.
 * Located at: https://mvsf.gmu.edu/custom
 * 
 * @author Kaan Turkmen
 * @editor Thomas Campbell
 * @comments Thomas Campbell
 * @returns Custom Ranking Generation Page
 */
export default function Generate() {
    const classes = useStyles();
    // Settings Values - TRC
    const settings = useContext(SettingsContext);
    // Timeframe warning control bool - TRC
    const [timeframeWarning, setTimeframeWarning] = useState(null);
    // Status Texts - TRC
    const [successText, setSuccessText] = useState(null);
    const [warningText, setWarningText] = useState(null);
    // Button Variables and Validity Variables - TRC
    const [submitEnabled, setSubmitEnabled] = useState(true);
    const [timeframeValid, setTimeframeValid] = useState(false);
    const [parametersValid, setParametersValid] = useState(false);
    // Parameter Values - TRC
    const [startDate, setStartDate] = useState("2018-12-01");
    const [endDate, setEndDate] = useState("2020-12-01");
    const [alpha, setAlpha] = useState(0.75);
    const [beta, setBeta] = useState(0.25);
    const [gamma, setGamma] = useState(0);
    const [delta, setDelta] = useState(0);
    const [c, setC] = useState(0.5);
    // History Variable - TRC
    const history = useHistory();

    const updateStartDate = (event) => setStartDate(event.target.value);
    const updateEndDate = (event) => setEndDate(event.target.value);

    // Update settings from user input - TRC
    function updateSettings() {
        settings.generatedAlpha = parseFloat(alpha);
        settings.generatedBeta = parseFloat(beta);
        settings.generatedGamma = parseFloat(gamma);
        settings.generatedDelta = parseFloat(delta);
        settings.generatedC = parseFloat(c);
        settings.generatedStartDate = startDate;
        settings.generatedEndDate = endDate;
    }

    // Check and make sure the user's input timeframe is valid - TRC
    function validateTimeframe() {
        const startDateObj = new Date(...startDate.split('-'));
        const endDateObj = new Date(...endDate.split('-'));
        
        if (startDateObj < endDateObj) {
            setTimeframeWarning(null);
            return true;
        } else {
            setTimeframeWarning('Invalid timeframe');
            return false;
        }
    }

    // Validate the parameters passed in - TRC
    function validateParameters() {
        let infinityWarn = checkBoundaries(parseFloat(alpha), 0, Infinity) && checkBoundaries(parseFloat(beta), 0, Infinity) && checkBoundaries(parseFloat(c), 0, Infinity);
        let rangeWarn = checkBoundaries(parseFloat(gamma), 0, 1) && checkBoundaries(parseFloat(delta), 0, 1);
        let numericalWarn = checkNumerical(alpha) && checkNumerical(beta) && checkNumerical(gamma) && checkNumerical(delta) && checkNumerical(c);

        if (!infinityWarn && !rangeWarn) {
            setSuccessText("");
            setWarningText("Parameter values are invalid")
        } else if (!numericalWarn) {
            setSuccessText("");
            setWarningText("Please only use numerical values");
        } else if (!infinityWarn) {
            setSuccessText("");
            setWarningText("Parameters cannot have values smaller than 0");
        } else if (!rangeWarn) {
            setSuccessText("");
            setWarningText("Gamma and Delta variables must be in the range of 0 to 1");
        } else {
            setWarningText("");
            setSuccessText("");
            return true;
        }

        return false;
    }

    // Return timeframe - TRC
    function getTimeframe() {
        updateSettings();
        history.push(generatedRankingURL);
    }

    // Called onReset (Sets all values to default) - TRC
    function onReset() {
        setAlpha(0.75);
        setBeta(0.25);
        setC(0.5);
        setGamma(0);
        setDelta(0);
    }

    // Date change useEffect - TRC
    useEffect(() => {
        const res = validateTimeframe();
        setTimeframeValid(res);
    }, [startDate, endDate]);

    // Parameter Change useEffect - TRC
    useEffect(() => {
        const res = validateParameters();
        setParametersValid(res);
    }, [alpha, beta, gamma, delta, c]);

    // Validity Check useEffect - TRC
    useEffect(() => {
        const valid = timeframeValid && parametersValid;
        setSubmitEnabled(valid);
    }, [timeframeValid, parametersValid]);

    // MASTER Return Statement - TRC
    return (
        <Box>
            <Navbar />
            <Typography variant="h5" align="center" className={classes.title} paragraph>Generate Custom Ranking</Typography>
            <Container spacing={3} className={classes.content}>
                { timeframeWarning ? <Alert severity='warning'>{timeframeWarning}</Alert> : null}
                { successText ? <Alert severity='success' >{successText}</Alert> : null}
                { warningText ? <Alert severity='warning' >{warningText}</Alert> : null}
                <Typography variant="subtitle2" paragraph style={{ textAlign: "center" }}>To get the ranking for a specific timeframe, please specify the parameters below.</Typography>
                <Paper className={classes.timeframePaper} elevation={3}>
                    <Box>
                        <Typography align="center" className={classes.gridTitle}>Timeframe</Typography>
                    </Box>
                    <Box className={classes.inputBox}>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="date"
                            label="Start Date"
                            type="date"
                            defaultValue="2018-12-01"
                            className={classes.textField}
                            variant="outlined"
                            onChange={updateStartDate}
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="date"
                            label="End Date"
                            type="date"
                            defaultValue="2020-12-01"
                            className={classes.textField}
                            variant="outlined"
                            onChange={updateEndDate}
                        />
                    </Box>
                </Paper>
                <br />
                <LikelihoodSettings 
                    alpha={alpha} 
                    beta={beta} 
                    gamma={gamma} 
                    setAlpha={setAlpha} 
                    setBeta={setBeta} 
                    setGamma={setGamma} 
                    setWarning={setWarningText}
                    setSuccess={setSuccessText}
                />
                <br />
                <ExposureSettings 
                    c={c} 
                    setC={setC} 
                    delta={delta} 
                    setDelta={setDelta} 
                    setWarning={setWarningText} 
                    setSuccess={setSuccessText} 
                />
                <Box className={classes.buttonBox}>
                    <Button variant="outlined" style={{ marginRight: "2%" }} onClick={() => onReset()}>Reset</Button>
                    <Button variant="contained" color="primary" disabled={!submitEnabled} onClick={() => getTimeframe()}>Rank</Button>
                </Box>
            </Container>
            <br /><br />
            <Footer />
        </Box>
    )
}