import React, { useEffect, useState, useContext } from 'react';
import {
    Box, Container, makeStyles, Typography,
    Paper, TableContainer, Table, TableHead,
    TableRow, TableCell, TableBody, Button,
    Popover, LinearProgress, Backdrop, Grid,
    CircularProgress, IconButton, Checkbox
} from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { green, red, yellow } from '@material-ui/core/colors';
import Navbar from '../components/Navbar';
import axios from 'axios';
import { API_URL, TOTAL_HF_HEIGHT } from '../settings';
import authHeader from '../services/authHeader';
import Footer from '../components/Footer';
import ArchivedHistorical from './ArchivedHistorical';
import { findCWE } from '../services/helper';
import { SettingsContext } from '../contexts/SettingsContext';
import { Close } from '@material-ui/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import KeyBoardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyBoardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { archiveListURL, historicalRankingsURL } from '../urls';
import '../fonts/font.css';

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const useStyles = makeStyles((theme) => ({
    container: {
        alignItems: "center",
        // Statement below makes footer anchor to bottom 
        // (Only needed on pages where the information would result in an incorrect footer )- TRC
        minHeight: "calc(100vh - " + TOTAL_HF_HEIGHT + ")",
    },
    title: {
        fontWeight: "bold",
        marginTop: "2%",
        marginBottom: "2%",
        fontFamily: "Barlow",
    },
    subtitle: {
        fontWeight: "bold"
    },
    archiveBox: {
        textAlign: "center",
        margin: 'auto'
    },
    listText: {
        textAlign: "center"
    },
    paper: {
        margin: 'auto',
        width: "30%",
        marginBottom: "5%"
    },
    table: {
        minWidth: 650,
        maxWidth: 2600,
    },
    tableContainer: {
        marginBottom: "2%",
        marginTop: "1%",
        backgroundColor: "#eeeeee",
    },
    header: {
        fontWeight: "bold",
        whiteSpace: 'nowrap',
        padding: "8px",
    },
    headerCheck: {
        height: "20px",
        boxSizing: "border-box",
        padding: "0px",
    },
    headerLink: {
        fontWeight: "bold",
        whiteSpace: 'nowrap',
        "&:hover": {
            color: "blue",
            textDecoration: "underline",
            cursor: "pointer",
        }
    },
    headerGrid: {
        marginTop: "2%",
        marginBottom: "1%",
    },
    cell: {
        fontSize: 13,
        padding: 0,
        "&:hover": {
            backgroundColor: "#f8ff21 !important"
        }
    },
    cellHighlighted: {
        fontSize: 13,
        padding: 0,
        backgroundColor: "#f8ff21 !important"
    },
    tableTitle: {
        fontWeight: "bold",
        marginTop: "1%",
    },
    loadMoreButton: {
        left: "45%",
        marginBottom: "2%"
    },
    intervalButton: {
        width: "85%",
        // justifyContent: "center"
    },
    intervalPaper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: "space-around",
        padding: "2%",
        paddingRight: "3%",
        paddingLeft: "%3",
        width: "100%",
        height: "100%",
    },
    intervalGrid: {
        justifyContent: "center",
        alignItems: "center",
    },
    upIcon: {
        fontSize: "medium",
        color: green[500],
        justifyContent: "center",
        paddingLeft: 5
    },
    downIcon: {
        fontSize: "medium",
        color: red[500],
        justifyContent: "center",
        paddingLeft: 5
    },
    // Just a horizotal flex container
    flexDiv: {
        padding: '0px',
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'space-between',
        margin: 'auto'
    },
    toggleContainer: {
        marginLeft: "auto",
        marginRight: "0px",
        paddingRight: "0px",
        width: "fit-content",
    },
    toggleText: {
        fontFamily: "Barlow",
        fontWeight: "bold",
    }
}))

/**
 * Historical Rankings Page
 * Located at: https://mvsf.gmu.edu/historical-rankings
 * 
 * @author Kaan Turkmen
 * @editor Thomas Campbell
 * @returns The historical rankings
 */
export default function HistoricalRankings({ history, location }) {
    const classes = useStyles();
    const settings = useContext(SettingsContext);
    const [links, setLinks] = useState({});
    const [headers, setHeaders] = useState([]);
    const [rows, setRows] = useState([]);
    const [maxRowSize, setMaxRowSize] = useState(150);
    const [backdrop, showBackdrop] = useState(false);
    const [loadSize, setLoadSize] = useState(25);
    // Corresponds to the toggle state of the master reset checkbox - TRC
    const [masterToggle, setMasterToggle] = useState(false);
    // Corresponds to the currently "hovered over" CWE - TRC
    const [highlightedCWE, setHighlightedCWE] = useState("");
    // Corresponds to the currently "locked" CWE - TRC
    const [lockedCWE, setLockedCWE] = useState([]);
    // Below variable not used currently, why? -TRC
    const [highlightedCell, setHighlightedCell] = useState([0, 0]);
    // Below variable not used currently, why? -TRC
    const [selectedCWE, setSelectedCWE] = useState("");
    const [selectedCell, setSelectedCell] = useState([0, 0]);
    const [popoverAnchor, setPopoverAnchor] = useState(null);
    const [tooltip, showTooltip] = useState(false);
    const [loadingTooltip, setLoadingTooltip] = useState(true);
    const [details, setDetails] = useState({});
    const [historicalYear, setHistoricalYear] = useState(new Date().getFullYear() - 1);
    const splittedLocation = location["pathname"].split(historicalRankingsURL + "/")[1];
    // Variable to control the displayed version of rankings - TRC [CURRENT DEFAULT IS VERSION 1]
    const [rankVersion, setRankVersion] = useState(1);

    // 
    async function transformData(data) {
        let rows = [];
        let headers = [];
        let rowSize = 150;
        console.log("Historical Transform Data:");
        console.log(data);

        data.forEach(record => rowSize = Math.min(record["ranking"].length, rowSize));

        headers = data.map(record => record["timeframe"])

        headers = data.map(data => {
            let year = data["timeframe"].split("-")[0];
            let month = data["timeframe"].split("-")[1];
            return year + "-" + month.padStart(2, "0");
        });

        // Sort the headers - TRC
        headers.sort((a, b) => {
            let aYear = parseInt(a.split("-")[0]);
            let bYear = parseInt(b.split("-")[0]);
            // Compare years before comparing months
            if (aYear < bYear) {
                return -1;
            } else if (bYear < aYear) {
                return 1;
            }
            // Compare months if years are equal
            let aMonth = parseInt(a.split("-")[1]);
            let bMonth = parseInt(b.split("-")[1]);
            // Compare months
            if (aMonth < bMonth) {
                return -1;
            } else if (bMonth < aMonth) {
                return 1;
            }
            // Return 0 if somehow the same
            return 0;
        });

        setHeaders(headers);
        console.log(`Size: ${rowSize}`)

        for (let i = 0; i < rowSize; i++) {
            let row = {};
            data.forEach((rec, index) => {
                let timeframe = rec["timeframe"]
                let year = rec["timeframe"].split("-")[0];
                let month = rec["timeframe"].split("-")[1];
                let header = year + "-" + month.padStart(2, "0");
                row[header] = rec["ranking"][i]["CWE ID"]
            })

            // Get the current month - TRC
            const d = new Date();
            let month = "" + (d.getMonth());
            month = month.padStart(2, "0");

            // Get the change in the CWE Ranking - TRC
            row["Change"] = findCWE(data, row[`${historicalYear + 1}-${month}`]);
            rows.push(row);
        }

        console.log(rows);
        setRows(rows);
        setMaxRowSize(rowSize);
        showBackdrop(false);
    }

    async function fetchData() {
        axios.get(API_URL + `/get/historical/current/${historicalYear + 1}`, { headers: authHeader() })
            .then(res => {
                transformData(res.data);
            })
            .catch(err => {
                console.log(err);
            });
    }

    function handleCellHover(row, header, rowIndex, colIndex) {
        setHighlightedCWE(row[header]);
        //setHighlightedCell([rowIndex, colIndex]);
    }

    function handleCellClick(row, header, rowIndex, colIndex) {
        // setSelectedCWE(row[header]);
        // setSelectedCell([rowIndex, colIndex]);
    }

    function updateClickedCell(event) {
        setPopoverAnchor(event.currentTarget);
    }

    function handleTooltipOpen() {
        // getDetails();
    }

    // Function to add or remove a CWE from the hightlist tracking list - TRC
    function handleLockToggle(row) {
        // Get the current month - TRC
        // EXPLAIN: Logic runs off of the idea that the most recent month is the current month - 1 (since that is the most recent data) due to this
        //          there will be 12 months displayed, meaning the first month displayed will be the previous years' current month (for example
        //          if it were currently March of 2022 then the first month displayed in the table will be March of 2021)
        const d = new Date();
        let month = "" + (d.getMonth() + 1);
        month = month.padStart(2, "0");

        // Decrement year by 1 (IFF the current month isnt january) - TRC
        let year = settings.endYear;
        if((d.getMonth() + 1) != 1) {
            year -= 1;
        }

        if (lockedCWE.includes(row[historicalYear + "-" + month])) {
            setLockedCWE(lockedCWE.filter(CWE => CWE !== row[historicalYear + "-" + month]));
        } else {
            setLockedCWE(lockedCWE => [...lockedCWE, row[historicalYear + "-" + month]]);
        }
        console.log(lockedCWE);
    }

    // Handle the master checkbox being toggled - TRC
    function handleMasterToggle() {
        if (masterToggle) {
            setLockedCWE([]);
        } else {
            return
        }
    }

    // UseEffect: Check if ANY checkboxes are checked and update the master toggle - TRC
    useEffect(() => {
        setMasterToggle(lockedCWE.length >= 1);
    }, [lockedCWE]);

    // Return true if checkmark's corresponding CWE is in the lockedCWE list - TRC
    function isClicked(row) {
        // Get the current month - TRC
        // EXPLAIN: Logic runs off of the idea that the most recent month is the current month - 1 (since that is the most recent data) due to this
        //          there will be 12 months displayed, meaning the first month displayed will be the previous years' current month (for example
        //          if it were currently March of 2022 then the first month displayed in the table will be March of 2021)
        const d = new Date();
        let month = "" + (d.getMonth() + 1);
        month = month.padStart(2, "0");

        return lockedCWE.includes(row[historicalYear + "-" + month]);
    }

    // Function to specifically unset the hovered cell for visual appeal - TRC
    function removeHoveredCell() {
        setHighlightedCWE(null);
    }

    // Add one to the year - KT
    function nextYear() {
        setHistoricalYear(historicalYear + 1);
    }

    // Subtract one from the year - KT
    function previousYear() {
        setHistoricalYear(historicalYear - 1);
    }

    // Navigate the user to the historical page for the month contained in the link - KT
    function navigateToHistorical(header) {
        const year = header.split('-')[0];
        const month = header.split('-')[1];
        const link = `${historicalRankingsURL}/${year}-${months[month - 1].toLowerCase()}`;
        history.push(link);
    }

    // On page load useEffect - KT
    useEffect(() => {
        let obj = {}
        let currentMonth = new Date().getMonth();
        fetchData();

        for (let i = 2021; i >= 2020; i--) {
            for (let j = currentMonth; j >= 1; j--) {
                let key = `${months[j - 1]} ${i} Ranking`;
                let value = `${i}-${months[j - 1].toLowerCase()}`;
                obj[key] = value;
            }
        }

        // axios.get(API_URL + '/get/all/historical', { headers: authHeader() })
        //     .then(res => console.log(res.data))

        console.log(obj);
        setLinks(obj);
    }, [])


    useEffect(() => fetchData(), [historicalYear]);

    if (splittedLocation) {
        const month = splittedLocation.split('-')[1];
        const year = splittedLocation.split('-')[0];
        const monthIndex = months.indexOf(month.charAt(0).toUpperCase() + month.slice(1));

        return <ArchivedHistorical month={monthIndex} year={year} />
    }

    // Handle the change of the ranking version toggle - TRC
    function handleAlignment(event, newRanking) {
        // Only run if the value is not null
        if(newRanking !== null) {
            console.log("Displaying new ranking verison: " + newRanking);
            setRankVersion(newRanking);
        }
    };

    // MASTER Return statement - TRC
    return (
        <Box>
            <Navbar />
            <Backdrop className={classes.backdrop} open={backdrop}>
                <CircularProgress />
            </Backdrop>
            <Container className={classes.container} disableGutters>
                <Grid container className={classes.headerGrid} spacing={3}>
                    <Grid item xs={2} className={classes.intervalGrid}>
                        <IconButton onClick={() => previousYear()} className={classes.intervalButton} disableRipple disabled={historicalYear <= 1999}>
                            <Paper className={classes.intervalPaper}>
                                <KeyBoardArrowLeftIcon fontSize="large" />
                                <Typography variant="button">Previous</Typography>
                            </Paper>
                        </IconButton>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="h5" align="center" className={classes.tableTitle}>
                            Historical Rankings
                        </Typography>
                    </Grid>
                    <Grid item xs={2} className={classes.intervalGrid}>
                        <IconButton onClick={() => nextYear()} variant="outlined" className={classes.intervalButton} disableRipple disabled={historicalYear >= new Date().getFullYear() - 1}>
                            <Paper className={classes.intervalPaper}>
                                <Typography variant="button">Next</Typography>
                                <KeyBoardArrowRightIcon fontSize="large" />
                            </Paper>
                        </IconButton>
                    </Grid>

                </Grid>
                <Container className={classes.flexDiv}>
                    <Typography variant="subtitle2">Click on month headers for more information</Typography>
                    <Container className={classes.toggleContainer}>
                        {/* Toggle button to select which ranking versions are displayed - TRC [VERSION 2 CURRENTLY DISABLED] */}
                        <ToggleButtonGroup
                            value={rankVersion}
                            exclusive
                            size="small"
                            onChange={handleAlignment}
                            aria-label="Displayed Ranking Version"
                        >
                            <ToggleButton value="1" aria-label="Version 1.0" selected={rankVersion == 1} color="primary">
                                <Typography variant="body" className={classes.toggleText}>Ranking V1.0</Typography>
                            </ToggleButton>
                            <ToggleButton value="2" aria-label="Version 2.0" selected={rankVersion == 2} color="primary" disabled>
                                <Typography variant="body" className={classes.toggleText}><strike>Ranking V2.0</strike></Typography>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Container>
                </Container>
                <TableContainer component={Paper} className={classes.tableContainer}>
                    <Table key="table" className={classes.table} size="medium" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell size="small" align="center" className={classes.header}>Rank</TableCell>
                                { /* Column header for locking checkboxes, includes master checkbox - TRC */}
                                <TableCell size="small" align="left" padding="none" className={classes.header}>
                                    {/* Lock<br /> */}
                                    <Checkbox className={classes.headerCheck} size="small" checked={masterToggle} onChange={() => handleMasterToggle()} />
                                </TableCell>
                                {headers.map((header) => (
                                    /* Make each header a link to the archive for that month [KT]. - TRC */
                                    <TableCell key={header} className={classes.headerLink} align="center" variant="head" onClick={() => navigateToHistorical(header)}>{header}</TableCell>
                                ))}
                                <TableCell size="small" align="center" className={classes.header}>Rank</TableCell>
                                <TableCell size="small" align="center" className={classes.header}>Change</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.slice(0, loadSize).map((row, rowIndex) => (
                                <TableRow size="small" key={row.cweId}>
                                    <TableCell component="th" scope="row" align="center" padding="none">{rowIndex + 1}</TableCell>
                                    { /* Checkbox to allow user to lock a CWE selection - TRC */}
                                    <TableCell size="small" padding="none"><Checkbox size="small" checked={isClicked(row)} onChange={() => handleLockToggle(row)} /></TableCell>
                                    {headers.map((header, colIndex) => (
                                        <TableCell align="center" key={header} className={((row[header] === highlightedCWE) || (lockedCWE.indexOf(row[header]) > -1)) ? classes.cellHighlighted : classes.cell}
                                            onClick={(event) => {
                                                handleCellClick(row, header, rowIndex, colIndex);
                                                updateClickedCell(event)
                                            }}
                                            onMouseOver={() => handleCellHover(row, header, rowIndex, colIndex)}
                                            onMouseOut={() => removeHoveredCell()}>
                                            {selectedCell[0] === rowIndex && selectedCell[1] === colIndex ? (
                                                <div>
                                                    <Button onClick={handleTooltipOpen}>{row[header]}</Button>
                                                    <Popover
                                                        className={classes.tooltip}
                                                        open={tooltip}
                                                        onClose={() => showTooltip(false)}
                                                        anchorEl={popoverAnchor}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'left'
                                                        }}
                                                    >
                                                        {loadingTooltip ? <LinearProgress /> : (
                                                            <Box style={{ padding: 10 }}>
                                                                <Box className={classes.closeButton}>
                                                                    <Close onClick={() => showTooltip(false)} />
                                                                </Box>
                                                                <Box>
                                                                    <Typography style={{ fontSize: 14 }}>CWE ID: {details["CWE ID"]}</Typography>
                                                                    <Typography style={{ fontSize: 14 }}>Number of CVEs: {details["# of CVEs"]?.toFixed(0)}</Typography>
                                                                    <Typography style={{ fontSize: 14 }}>Avg Exploitability V2: {details["Avg Exploitability V2"]?.toFixed(2)}</Typography>
                                                                    <Typography style={{ fontSize: 14 }}>Avg Days Since Published: {details["Avg Days Since Published"]?.toFixed(2)}</Typography>
                                                                    <Typography style={{ fontSize: 14 }}>Avg Impact Score V2: {details["Avg Impact Score V2"]?.toFixed(2)}</Typography>
                                                                    <Typography style={{ fontSize: 14 }}>Avg Exposure Factor: {details["Avg Exposure Factor"]?.toFixed(2)}</Typography>
                                                                    <Typography style={{ fontSize: 14 }}>Avg Base Score: {details["Avg Base Score"]?.toFixed(2)}</Typography>
                                                                    <Typography style={{ fontSize: 14 }}>Avg Exploitation Likelihood: {details["Avg Exploitation Likelihood"]?.toFixed(2)}</Typography>
                                                                    <Typography style={{ fontSize: 14 }}>Known IDS Rules: {details["Known IDS Rules"]}</Typography>
                                                                    <Typography style={{ fontSize: 14 }}>Deployed IDS Rules: {details["Deployed IDS Rules"]}</Typography>
                                                                    <Typography style={{ fontSize: 14 }}>Overall Score: {details["Our Overall Score"]?.toFixed(0)}</Typography>
                                                                    <Typography style={{ fontSize: 14 }}>
                                                                        <a href={`https://cwe.mitre.org/data/definitions/${details["CWE ID"].split('-')[1]}.html`} target="_blank">
                                                                            CWE Details
                                                                            <FontAwesomeIcon icon={faExternalLinkAlt} className={classes.linkIcon} size="xs" />
                                                                        </a>
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                        )
                                                        }
                                                    </Popover>
                                                </div>
                                            ) : row[header]}

                                        </TableCell>
                                    ))}
                                    <TableCell size="small" align="center">{rowIndex + 1}</TableCell>
                                    <TableCell size="small" align="center">
                                        {row["Change"]}
                                        {row["Change"] < 0 ? <ArrowDownwardIcon className={classes.downIcon} /> : (row["Change"] > 0 ? <ArrowUpwardIcon className={classes.upIcon} /> : null)}

                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Grid container spacing={3}>
                    <Grid item xs={2}>
                        <IconButton className={classes.leftScrollButton} onClick={() => window.scrollTo(0, 0)}>
                            <ArrowUpwardIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={8} style={{ justifyContent: "center" }}>
                        <Button variant="outlined" color="primary" className={classes.loadMoreButton} onClick={() => setLoadSize(loadSize + 25)} disabled={loadSize >=
                            maxRowSize}>Load More</Button>
                    </Grid>
                    <Grid item xs={2} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <IconButton className={classes.rightScrollButton} onClick={() => window.scrollTo(0, 0)}>
                            <ArrowUpwardIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Container>
            <Footer />
        </Box>
    )

}