import axios from "axios";
import { API_URL } from "../settings";
import { loginURL } from "../urls";

// Attempt user login - Author: KT, Comments: TRC
export const login = (username, password) => {
    return axios.post(API_URL + "/api/login", {
        username, password
    }).then((response) => {
        console.log(response.data["access_token"])
        if (response.data["access_token"]) {
            localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
    }).catch((error) => {
        return error;
    })
};

// Log User Out - Author: KT, Comments: TRC
export const logout = () => {
    localStorage.removeItem("user");
    window.location.href = loginURL;
}

// Get Current session user - Author: KT, Comments: TRC
export const getUser = () => {
    return JSON.parse(localStorage.getItem("user"));
};

