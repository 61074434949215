import axios from "axios";
import jwt_decode from "jwt-decode";

// Authorize user session - Author: KT, Comments: TRC
export default function authHeader() {
    const user = JSON.parse(localStorage.getItem('user'));

    if (user && user['access_token']) {
        return { Authorization: 'Bearer ' + user['access_token'] };
    } else {
        return {};
    }
}

// Check if session is valid - Author: KT, Comments: TRC
export function tokenExists() {
    const user = JSON.parse(localStorage.getItem('user'));

    return (user && user['access_token']);
}

// Check if user is admin - Author: KT, Comments: TRC
export const isAdmin = () => {
    const user = JSON.parse(localStorage.getItem('user'));

    const token = jwt_decode(user['access_token']);

    return (token && token['admin'])
}
