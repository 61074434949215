import React, { useContext, useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, 
    DialogActions, Select, MenuItem, DialogContentText,
    FormControl, makeStyles } from '@material-ui/core';
import { SettingsContext } from '../contexts/SettingsContext';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    }
}))

/**
 * Year Selection Dialog 
 * 
 * @author Kaan Turkmen
 * @editor Thomas Campbell
 * @comments Thomas Campbell
 * @param {*} props
 *      visible: Boolean controlling dialog visibility
 *      onClose: OnClose Function Callback
 * @returns An instance of a Year Selection Dialog
 */
export default function YearSelectionDialog(props) {
    const [selectedYear, setSelectedYear] = useState(2020);
    const settings = useContext(SettingsContext);
    const classes = useStyles();

    // Declare year arrays - Comments: TRC, Author: KT
    let years = [...Array(22).keys()].map(year => year + 1999);

    // Update timeframe years - Comments: TRC, Author: KT
    const updateYears = () => {
        settings.endYear = selectedYear;
        settings.startYear = selectedYear - 2;
        props.onClose(false);
    }

    // Master Return Statement - Comments: TRC, Author: KT
    return (
        <div>
            <Dialog open={props.visible} onClose={() => props.onClose(false)} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Select Year</DialogTitle>
                <DialogContent>
                    <DialogContentText>Choose the year you want to view</DialogContentText>
                    <FormControl className={classes.formControl}>
                        <Select
                            value={selectedYear}
                            labelId="select-year-label"
                            onChange={(e) => setSelectedYear(e.target.value)}
                            variant="outlined"
                        >
                            {years.map(year => <MenuItem value={year}>{year}</MenuItem>)}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={() => props.onClose(false)}>
                        Close
                    </Button>
                    <Button color="primary" onClick={() => updateYears()}>
                        View Year
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}