import React, { useState } from 'react';
import axios from 'axios';
import { API_URL, TOTAL_HF_HEIGHT } from '../settings';
import { makeStyles } from '@material-ui/core/styles';
import {
    Box, Container, Typography, Grid, Button, TextField, Paper,
    InputAdornment
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import CVEResultCard from '../components/CVEResultCard';
import { InlineMath } from 'react-katex';
import '../fonts/font.css';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    container: {
        width: "80%",
        // Statement below makes footer anchor to bottom 
        // (Only needed on pages where the information would result in an incorrect footer )- TRC
        minHeight: "calc(100vh - " + TOTAL_HF_HEIGHT + ")",
    },
    title: {
        fontWeight: "bold",
        marginTop: "3%",
        marginBottom: "1%",
        fontSize: 26,
        fontFamily: "Barlow",
    },
    barlowFont: {
        fontFamily: "Barlow",
    },
    tableContainer: {
        textAlign: "center",
        fontFamily: "Barlow",
        marginTop: "1rem",
        padding: "0px",
        "& table": {
            width: "30%",
            margin: "auto",
            borderCollapse: "collapse",
            borderRadius: "10px",
            border: "2px solid black",
        },
    },
    searchContainer: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
    },
    searchBar: {
        width: "60%",
        margin: "0px",
    },
    searchButton: {
        height: "100%",
        marginLeft: "1rem",
        borderRadius: "2rem",
    },
    resultsContainer: {
        width: "75%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        background: "#eeeeee",
        borderRadius: "1rem",
        marginTop: "1rem",
    },
    emptyResContainer: {
        margin: "auto",
    },
    settingsContainer: {
        textAlign: 'center',
        marginTop: '1%'
    },
    settingsInfo: {
        marginRight: "1%",
    },
    catTableHeader: {
        backgroundColor: "#cfd8dc",
        borderBottom: "2px solid black",
    },
    redBackgroundRow: {
        padding: "0.3rem",
        backgroundColor: "#ffcdd2",
    },
    greenBackgroundRow: {
        padding: "0.3rem",
        backgroundColor: "#c8e6c9",
    },
    orangeBackgroundRow: {
        padding: "0.3rem",
        backgroundColor: "#ffe0b2",
    },
    yellowBackgroundRow: {
        padding: "0.3rem",
        backgroundColor: "#fff9c4",
    }
}));

/**
 * CVE Search Page 
 * Located at: https://mvsf.gmu.edu/CVE-search
 * 
 * @author Thomas Campbell
 * @comments Thomas Campbell
 * @param {*} props - UNUSED
 * @returns CVE Search Page Function
 */
export default function CVESearch(props) {
    const classes = useStyles();
    const [searchTerm, setSearchTerm] = useState("");
    // Contains results from search - TRC
    const [searchResult, setSearchResult] = useState(null);
    const [showError, toggleError] = useState(false);

    // Build CVE Card using result data - TRC
    function buildCVEcard() {
        if (searchResult && Object.keys(searchResult).length != 0) {
            return (
                <CVEResultCard
                    cveID={searchResult["CVE ID"]}
                    likelihood={searchResult["Exploitation Likelihood"]}
                    impact={searchResult["Impact Score"]}
                    MVSF={searchResult["Overall Score"]}
                    CWElist={searchResult["CWE ID"]}
                    baseScore={searchResult["Base Score"]}
                    daysSincePub={searchResult["Days Since Published"]}
                    exploitability={searchResult["Exploitability"]}
                    dIDSrules={searchResult["Deployed IDS Rules"]}
                    kIDSrules={searchResult["Known IDS Rules"]}
                    exposureFactor={searchResult["Exposure Factor"]}
                    pubDate={searchResult["Published Date"]}
                />
            )
        } else if(searchResult == "") {
            { /* This is to catch and STOP a pre-search display of "No Search Result Found" - TRC */}
        } else {
            return (
                <Container className={classes.emptyResContainer}>
                    <br />
                    <br />
                    <Typography variant='body1' align="center" className={classes.barlowFont}>NO CVE FOUND</Typography>
                    <Typography variant='body2' align="center" className={classes.barlowFont}><i>Review your search query for any typos</i></Typography>
                    <br />
                    <br />
                </Container>
            )
        }
    }

    // Handle submit button being checked, input checking - TRC
    function handleSearchSubmit() {
        let searchRE = /CVE-\d*-\d*/;
        if (searchTerm.match(searchRE)) {
            toggleError(false);
            sendQuery();
        } else {
            toggleError(true);
        }
    }

    // Handle submission of search query and retrieve results from backend - TRC
    function sendQuery() {
        // If a current card exists, clear it...
        setSearchResult("");
        buildCVEcard();

        // Send Request to backend
        axios.post(API_URL + '/api/cve/search', { cve: searchTerm })
            .then(res => {
                // Store single CVE data in state variable - TRC
                setSearchResult(res.data);
                console.log(searchResult);
            }).catch(err => {
                console.log(err);
            });
    }

    // Set the value of the search term when the user types into the search bar - TRC
    function handleInput(e) {
        setSearchTerm(e.target.value);
    }

    // MASTER Return Statement - TRC
    return (
        <Box className={classes.root}>
            <Navbar />
            <Container className={classes.container}>
                <Typography variant="h5" align="center" className={classes.title} paragraph>CVE Search</Typography>
                <hr />
                <Typography variant="subtitle2" align="center">
                    Please Note: All searches must be in the format of "CVE-XXXX-XXXXX", other formats will not be submitted.
                </Typography>
                <Container className={classes.tableContainer}>
                    { /* Use default HTML table to display color schema (Easier this way) - TRC */}
                    <table>
                        <tr className={classes.catTableHeader}>
                            <th>Data Range</th>
                            <th>Rating</th>
                        </tr>
                        { /* Row 1 - Low Ranking */ }
                        <tr className={classes.greenBackgroundRow}>
                            <td>0.1 - 0.39</td>
                            <td>LOW</td>
                        </tr>
                        { /* Row 2 - Medium Ranking */ }
                        <tr className={classes.yellowBackgroundRow}>
                            <td>0.40 - 0.69</td>
                            <td>MEDIUM</td>
                        </tr>
                        { /* Row 3 - High Ranking */ }
                        <tr className={classes.orangeBackgroundRow}>
                            <td>0.70 - 0.89</td>
                            <td>HIGH</td>
                        </tr>
                        { /* Row 4 - Medium Ranking */ }
                        <tr className={classes.redBackgroundRow}>
                            <td>0.90 - 1.0</td>
                            <td>CRITICAL</td>
                        </tr>
                    </table>
                </Container>
                <Container className={classes.settingsContainer}>
                    <Typography variant="body2" display='inline' className={classes.settingsInfo}>Settings:</Typography>
                    <Typography variant="body2" display='inline' className={classes.settingsInfo}>
                        <InlineMath>\alpha</InlineMath>: 0.75
                    </Typography>
                    <Typography variant="body2" display='inline' className={classes.settingsInfo}>
                        <InlineMath>\beta</InlineMath>: 0.25
                    </Typography>
                    <Typography variant="body2" display='inline' className={classes.settingsInfo}>
                        <InlineMath>\gamma</InlineMath>: 0
                    </Typography>
                    <Typography variant="body2" display='inline' className={classes.settingsInfo}>
                        <InlineMath>\delta</InlineMath>: 0
                    </Typography>
                    <Typography variant="body2" display='inline' className={classes.settingsInfo}>
                        <InlineMath>c</InlineMath>: 0.5
                    </Typography>
                </Container>
                <br />
                <Container className={classes.searchContainer}>
                    <TextField
                        className={classes.searchBar}
                        autoComplete="false"
                        margin="dense"
                        variant="outlined"
                        id="CVEsearchBar"
                        value={searchTerm}
                        placeholder="CVE-XXXX-XXXXX"
                        error={showError}
                        onChange={handleInput}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Button className={classes.searchButton} onClick={handleSearchSubmit} variant="contained" color="primary">Search</Button>
                </Container>
                <Container className={classes.searchContainer}>
                    {   // Display error text if the user has activated an error - TRC
                        (showError) ? (<Typography variant="subtitle2" color="secondary"><i>Incorrect input query formatting, see format rules at top of page</i></Typography>) : null
                    }
                </Container>
                <br />
                <br />
                {   // Display result card - TRC
                    (searchResult != null) ? (
                        buildCVEcard()
                    ) : null
                }
            </Container>
            <Footer />
        </Box>
    )
}
