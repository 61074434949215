import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@material-ui/core'

/**
 * A BibTex Dialog Box - TRC
 * 
 * @author Kaan Turkmen
 * @comments Thomas Campbell
 * @param {*} props All required prop information {listed below}
 *      open: boolean controlling open and close of dialog
 *      onClose: close function callback
 * @returns An instance of a BibTex Dialog
 */
export default function BibTexDialog(props) {
    return (
        <Dialog open={props.open}>
            <DialogTitle>{"BibTex"}</DialogTitle>
            <DialogContent>
                <DialogContentText>{props.text}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onClose(false)} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    )
}