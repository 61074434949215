import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { makeStyles } from '@material-ui/core/styles';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import LiveRankings from './screens/LiveRankings';
import SignIn from './screens/SignIn';
import About from './screens/About';
import Contact from './screens/Contact';
import Homepage from './screens/Homepage';
import Settings from './screens/Settings';
import Documents from './screens/Documents';
import CustomRankings from './screens/CustomRankings';
import GeneratedRanking from './screens/GeneratedRanking';
import Instructions from './screens/Instructions';
import RequestAccount from './screens/RequestAccount';
import Account from './screens/Account';
import ArchiveList from './screens/ArchiveList';
import Admin from './screens/Admin';
import CVESearch from './screens/CVESearch';
import CreatePassword from './screens/CreatePassword';
import axios from 'axios';
import { isAdmin, tokenExists } from './services/authHeader';
import { logout } from './services/authService';
import { SettingsContext } from './contexts/SettingsContext';
import { Helmet } from 'react-helmet';
import { API_URL } from './settings';
import * as urls from './urls';
import HistoricalRankings from './screens/HistoricalRankings';

console.log(`MVSF_API_URL=${API_URL}`)

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log("ERR: " + JSON.stringify(error));
    
    // If response message is "Invalid credentials", this means that the 
    // response is on the login page. No need to logout if the user is 
    // trying to login i.e. not logged in yet.
    if (error.response.data.message !== "Invalid credentials" && 
    error.response.status === 401 || error.response.data.message === "401 Unauthorized") {
      console.log("Logging out");
      logout();
    }

    return Promise.reject(error);
  }
)

// TO-DO: The start year and end year should be changed to include 
// month to be able to show 12 months going back starting from the last 
// finished month
const settings = {
  alpha: 0.75,
  beta: 0.25,
  gamma: 0,
  c: 0.5,
  delta: 0,
  startYear: new Date().getFullYear() - 2,
  endYear: new Date().getFullYear()
}

// Initalize page settings and variables - TRC
function InitContext() {
  useEffect(() => {
    console.log("InitContext loaded");
    settings.alpha = localStorage.getItem("alpha") ?? 0.75;
    settings.beta = localStorage.getItem("beta") ?? 0.25;
    settings.gamma = localStorage.getItem("gamma") ?? 0;
    settings.c = localStorage.getItem("c") ?? 0.5;
    settings.delta = localStorage.getItem("delta") ?? 0;
  }, [])

  return () => console.log("Initializing settings");
}

// Control a private page - TRC
function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        tokenExists() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: urls.loginURL,
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

// Check if user is admin - TRC
function AdminRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={props =>
        (tokenExists() && isAdmin()) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: urls.loginURL,
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}

/**
 * App Master Return Statement
 * 
 * @returns APP
 */
export default function App() {
  return (
    <div className="application">
      <Helmet>
        <script async src={`https://www.googletagmanager.com/gtag/js?id=G-NVTY83VM9W`}></script>
        <script type="text/javascript">
          {`window.dataLayer = window.dataLayer || [];
                function gtag(){ dataLayer.push(arguments); }
                gtag("js", new Date());
                gtag("config", "G-NVTY83VM9W");`}
        </script>
      </Helmet>
      <BrowserRouter>
        <SettingsContext.Provider value={{ ...settings }}>
          <InitContext />
          <Switch>
          <Route path={urls.loginURL}>
              <SignIn />
            </Route>
            <Route path={urls.requestAccountURL}>
              <RequestAccount />
            </Route>
            <Route path={urls.createPasswordURL}>
              <CreatePassword />
            </Route>
            <PrivateRoute path={urls.aboutURL} component={About} />
            <PrivateRoute path={urls.contactURL} component={Contact} />
            <PrivateRoute path={urls.liveRankingsURL} component={LiveRankings} />
            <PrivateRoute path={urls.settingsURL} component={Settings} />
            <PrivateRoute path={urls.documentsURL} component={Documents} />
            <PrivateRoute path={urls.customRankingsURL} component={CustomRankings} />
            <PrivateRoute path={urls.generatedRankingURL} component={GeneratedRanking} />
            <PrivateRoute path={urls.historicalRankingsURL} component={HistoricalRankings} />
            <PrivateRoute path={urls.archiveListURL} component={ArchiveList} />
            <PrivateRoute path={urls.instructionsURL} component={Instructions} />
            <PrivateRoute path={urls.accountURL} component={Account} />
            <PrivateRoute path={urls.cveSearchURL} component={CVESearch} />

            <AdminRoute path={urls.adminPanelURL} component={Admin} />

            <PrivateRoute path="/" component={Homepage} />
          </Switch>
        </SettingsContext.Provider>
      </BrowserRouter>
    </div>
  );
}

