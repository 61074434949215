import React, { useState, useEffect, useContext } from 'react'
import {
    Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, TextField, Button,
    Box, AppBar, Toolbar,
    IconButton, Typography,
    Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow,
    Paper, Popover, makeStyles, Container,
    CircularProgress,
    LinearProgress,
    Backdrop
} from '@material-ui/core';
import { InlineMath } from 'react-katex';
import { InfoOutlined, GetApp } from '@material-ui/icons';
import { SettingsContext } from '../contexts/SettingsContext';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import axios from 'axios';
import { API_URL, TOTAL_HF_HEIGHT } from '../settings';
import authHeader from '../services/authHeader';


const useStyles = makeStyles((theme) => ({
    table: {

    },
    container: {
        // Statement below makes footer anchor to bottom 
        // (Only needed on pages where the information would result in an incorrect footer )- TRC
        minHeight: "calc(100vh - " + TOTAL_HF_HEIGHT + ")",
    },
    tableContainer: {
        marginTop: 15,
        marginBottom: 40,
        backgroundColor: "#eeeeee",
    },
    typography: {
        padding: theme.spacing(2),
    },
    explanation: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "3%"
    },
    cell: {
        fontSize: 14,
        padding: 3,
        whiteSpace: 'nowrap',
        textAlign: "left",
        paddingLeft: "7%"
    },
    centeredCell: {
        fontSize: 14,
        padding: 3,
        whiteSpace: 'nowrap',
        textAlign: 'center',
        justifyContent: "center"
    },
    settingsInfo: {
        marginRight: "1%",
    },
    backdrop: {
        zIndex: 10
    }
}));

/**
 * Output of custom ranking generation 
 * 
 * @author Kaan Turkmen
 * @editor Thomas Campbell
 * @comments Thomas Campbell
 * @returns Results from custom generation
 */
export default function GeneratedRanking() {
    const classes = useStyles();
    const settings = useContext(SettingsContext);
    const [detailsRef, setDetailsRef] = useState(null);
    const [details, setDetails] = useState({});
    const [ranking, setRanking] = useState([]);
    const [loading, toggleLoading] = useState(false);
    const open = Boolean(detailsRef);

    // Parameter object - TRC
    const params = {
        alpha: settings.generatedAlpha,
        beta: settings.generatedBeta,
        gamma: settings.generatedGamma,
        delta: settings.generatedDelta,
        c: settings.generatedC,
    }

    // Make backend call and return the file to the user for them to download the generated data - TRC
    const downloadFile = async () => {
        axios.get(API_URL + `/download/${settings.generatedStartDate}/${settings.generatedEndDate}`, { params, responseType: 'blob', headers: { 'Accept': 'text/csv', ...authHeader() } })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'results.xlsx'); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
    }

    // Call backend to get the timeframe data from the user input timeframe - TRC
    const getTimeframe = async () => {
        toggleLoading(true);
        axios.get(API_URL + `/filter/${settings.generatedStartDate}/${settings.generatedEndDate}`, { params, headers: authHeader() })
            .then(res => {
                const tmp = res.data;
                let rows = []
                console.log(tmp);
                for (let i = 0; i < tmp["CWE ID"]?.length; i++) {
                    let row = {}
                    row["CWE ID"] = tmp["CWE ID"][i]
                    row["Our Overall Score"] = tmp["Our Overall Score"][i]
                    row["Our Ranking"] = tmp["Our Ranking"][i]
                    row["# of CVEs"] = tmp["# of CVEs"][i]
                    row["Avg Exploitability V2"] = tmp["Avg Exploitability V2"][i]
                    row["Avg Days Since Published"] = tmp["Avg Days Since Published"][i]
                    row["Avg Impact Score V2"] = tmp["Avg Impact Score V2"][i]
                    row["Avg Exposure Factor"] = tmp["Avg Exposure Factor"][i]
                    row["Avg Base Score"] = tmp["Avg Base Score"][i]
                    row["Avg Exploitation Likelihood"] = tmp["Avg Exploitation Likelihood"][i]
                    row["MITRE Overall Score"] = tmp["MITRE Overall Score"][i]
                    row["Known IDS Rules"] = tmp["Known IDS Rules"][i]
                    row["Deployed IDS Rules"] = tmp["Deployed IDS Rules"][i]
                    row["CWE Name"] = tmp["CWE Names"][i]
                    rows.push(row)
                }
                sortRanking(rows);
                setRanking(rows);
                toggleLoading(false);
            })
            .catch(err => console.log(err))
    }

    // Handle user opening the details popup - TRC
    const handleOpen = (event, rank) => {
        openDetails(event);
        setDetails(rank);
    }

    // Open details of a specific CWE - TRC
    const openDetails = (event) => {
        setDetailsRef(event.currentTarget);
    };

    // Sort passed in list of rankings - TRC
    const sortRanking = (list) => {
        list.sort((a, b) => {
            return a["Our Ranking"] - b["Our Ranking"]
        })
    };

    // On load useEffect - TRC
    useEffect(() => {
        console.log(settings);
        getTimeframe();
    }, [])

    // MASTER Return Statement - TRC
    return (
        <div>
            <Navbar>
                <Button color="inherit" onClick={() => downloadFile()}>
                    Download Results
                    <GetApp />
                </Button>
            </Navbar>
            <Container className={classes.container}>
                <Box className={classes.explanation}>
                    <Typography variant="subtitle1" paragraph>
                        Showing the ranking from {settings.generatedStartDate} to {settings.generatedEndDate}.
                    </Typography>
                </Box>
                <Box>
                    <Typography variant="caption" className={classes.settingsInfo}>Settings:</Typography>
                    <Typography variant="caption" className={classes.settingsInfo}>
                        <InlineMath>\alpha</InlineMath>: {settings.generatedAlpha}
                    </Typography>
                    <Typography variant="caption" className={classes.settingsInfo}>
                        <InlineMath>\beta</InlineMath>: {settings.generatedBeta}
                    </Typography>
                    <Typography variant="caption" className={classes.settingsInfo}>
                        <InlineMath>\gamma</InlineMath>: {settings.generatedGamma}
                    </Typography>
                    <Typography variant="caption" className={classes.settingsInfo}>
                        <InlineMath>\delta</InlineMath>: {settings.generatedDelta}
                    </Typography>
                    <Typography variant="caption" className={classes.settingsInfo}>
                        <InlineMath>c</InlineMath>: {settings.generatedC}
                    </Typography>
                    <TableContainer component={Paper} className={classes.tableContainer}>
                        <Table key="table" className={classes.table} size="medium" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" className={classes.header}>Rank</TableCell>
                                    <TableCell align="center" className={classes.header}>CWE ID</TableCell>
                                    <TableCell align="center" className={classes.header}>CWE Name</TableCell>
                                    <TableCell align="center" className={classes.header}>Score</TableCell>
                                    <TableCell align="center" className={classes.header}>Details</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(Object.keys(ranking).length != 0 || loading) ?
                                    ranking.map(rank => (
                                        <TableRow>
                                            <TableCell align="center" className={classes.centeredCell}>{rank["Our Ranking"]}</TableCell>
                                            <TableCell align="center" className={classes.centeredCell}>{rank["CWE ID"]}</TableCell>
                                            <TableCell align="center" className={classes.cell}>{rank["CWE Name"]}</TableCell>
                                            <TableCell align="center" className={classes.centeredCell}>{rank["Our Overall Score"].toFixed(0)}</TableCell>
                                            <TableCell align="center" className={classes.centeredCell}>
                                                <IconButton onClick={(event) => handleOpen(event, rank)}>
                                                    <InfoOutlined />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                    : (
                                        <TableRow>
                                            <TableCell colSpan={4} align="center">No records found!</TableCell>
                                        </TableRow>
                                    )}
                            </TableBody>

                        </Table>
                    </TableContainer>
                </Box>
            </Container>
            <Footer />
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress />
            </Backdrop>
            <Popover
                open={open}
                anchorEl={detailsRef}
                onClose={() => setDetailsRef(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Typography className={classes.typography}>Number of CVEs: {details["# of CVEs"]?.toFixed(0)}</Typography>
                <Typography className={classes.typography}>Avg Exploitability V2: {details["Avg Exploitability V2"]?.toFixed(2)}</Typography>
                <Typography className={classes.typography}>Avg Days Since Published: {details["Avg Days Since Published"]?.toFixed(0)}</Typography>
                <Typography className={classes.typography}>Avg Impact Score V2: {details["Avg Impact Score V2"]?.toFixed(2)}</Typography>
                <Typography className={classes.typography}>Avg Exposure Factor: {details["Avg Exposure Factor"]?.toFixed(2)}</Typography>
                <Typography className={classes.typography}>Avg Base Score: {details["Avg Base Score"]?.toFixed(2)}</Typography>
                <Typography className={classes.typography}>Avg Exploitation Likelihood: {details["Avg Exploitation Likelihood"]?.toFixed(2)}</Typography>
                <Typography className={classes.typography}>Known IDS Rules: {details["Known IDS Rules"]}</Typography>
                <Typography className={classes.typography}>Deployed IDS Rules: {details["Deployed IDS Rules"]}</Typography>
                <Typography className={classes.typography}>Overall Score: {details["Our Overall Score"]?.toFixed(0)}</Typography>
            </Popover>
        </div>
    )
}