import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Container, Typography, Box, makeStyles, Grid, IconButton } from '@material-ui/core'
import { PhoneOutlined, EmailOutlined, RoomOutlined, LanguageOutlined } from '@material-ui/icons';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { MAP_HEIGHT, MAP_WIDTH, TOTAL_HF_HEIGHT } from '../settings';
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import '../fonts/font.css';

import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

// Icon Declaration - TRC
let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});

L.Marker.prototype.options.icon = DefaultIcon;

const useStyles = makeStyles((theme) => ({
    container: {
        width: "100%",
        // Statement below makes footer anchor to bottom 
        // (Only needed on pages where the information would result in an incorrect footer )- TRC
        minHeight: "calc(100vh - " + TOTAL_HF_HEIGHT + ")",
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
    },
    title: {
        fontWeight: "bold",
        marginTop: "2%",
        marginBottom: "3%",
        fontFamily: "Barlow",
    },
    subtitle: {
        fontWeight: "bold",
        marginBottom: "5%",
        fontSize: 18
    },
    leftBar: {
        marginTop: "1%"
    },
    rightBar: {
        marginTop: "1%"
    },
    iconBox: {
        display: "flex",
        marginTop: "5%",
        alignItems: 'center',
    },
    icon: {
        marginRight: "5%"
    },
    iconButton: {
        marginLeft: 0,
    },
    mapGrid: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        height: "100%"
    },
    map: {
        height: MAP_HEIGHT,
        width: MAP_WIDTH,
    },
    contactInfoContainer: {
        width: MAP_WIDTH,
        paddingLeft: "2%",
        paddingRight: "2%"
    },
    company: {
        marginBottom: "10%"
    }
}));

/**
 * Contact page, containing contact information
 * Located at: https://mvsf.gmu.edu/contact
 * 
 * @author Kaan Turkmen
 * @editor Thomas Campbell
 * @comments Thomas Campbell
 * @returns Contact Page
 */
export default function Contact() {
    const classes = useStyles();

    // Master return statement - TRC
    return (
        <Grid container className={classes.container} direction='column'>
            <Navbar />
            <Typography variant="h5" align="center" className={classes.title} paragraph>Contact Us</Typography>
            <Grid item container className={classes.contactInfoContainer} spacing={2}>
                <Grid item xs className={classes.leftBar}>
                    <Box className={classes.company}>
                        <Typography variant="subtitle1" paragraph>For technical and research questions:</Typography>
                        <Typography variant="h6" className={classes.subtitle}>Center for Secure Information Systems</Typography>
                        <Box className={classes.iconBox}>
                            <RoomOutlined className={classes.icon} />
                            <Typography>Research Hall, Suite 417<br />
                                George Mason University<br />
                                4400 University Dr.<br />
                                Fairfax, VA 22030<br />
                                United States of America</Typography>
                        </Box>
                        <Box className={classes.iconBox}>
                            <EmailOutlined className={classes.icon} />
                            <a href="mailto:malbanes@gmu.edu" style={{ textDecoration: "none", color: "inherit", marginRight: "5%" }}>
                                <Typography>malbanes@gmu.edu</Typography>
                            </a>
                        </Box>
                        <Box className={classes.iconBox}>
                            <PhoneOutlined className={classes.icon} />
                            <Typography> +1 (703) 993-1629</Typography>
                        </Box>
                        <Box className={classes.iconBox}>
                            <LanguageOutlined className={classes.icon} />
                            <a href="https://csis.gmu.edu" target="_href" style={{ textDecoration: "none", color: "inherit", display: "flex" }}>
                                <Typography style={{ marginLeft: "5%" }}>csis.gmu.edu</Typography>
                            </a>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs className={classes.rightBar}>
                    <Box className={classes.company}>
                        <Typography variant="subtitle1" paragraph>For licensing and other uses:</Typography>
                        <Typography variant="h6" className={classes.subtitle}>Palo Alto Research Center (PARC), a Xerox Company</Typography>
                        <Box className={classes.iconBox}>
                            <RoomOutlined className={classes.icon} />
                            <Typography>3333 Coyote Hill Road<br />
                                Palo Alto, CA 94304<br />
                                United States of America</Typography>
                        </Box>
                        <Box className={classes.iconBox}>
                            <EmailOutlined className={classes.icon} />
                            <a href="mailto:engage.jp@parc.com" style={{ textDecoration: "none", color: "inherit", marginRight: "5%" }}>
                                <Typography>engage@parc.com (United States)</Typography>
                            </a>
                        </Box>
                        <Box className={classes.iconBox}>
                            <EmailOutlined className={classes.icon} />
                            <a href="mailto:engage@parc.com" style={{ textDecoration: "none", color: "inherit", marginRight: "5%" }}>
                                <Typography>engage.jp@parc.com (Japan)</Typography>
                            </a>
                        </Box>
                        <Box className={classes.iconBox}>
                            <PhoneOutlined className={classes.icon} />
                            <Typography>+1 650 812 4000</Typography>
                        </Box>
                        <Box className={classes.iconBox}>
                            <LanguageOutlined className={classes.icon} />
                            <a href="https://www.parc.com" target="_href" style={{ textDecoration: "none", color: "inherit", display: "flex" }}>
                                <Typography style={{ marginLeft: "5%" }}>parc.com</Typography>
                            </a>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            <Grid item container xs className={classes.mapGrid}>
                    <MapContainer center={[38.828464196298754, -77.3050450021002]} zoom={16} scrollWheelZoom={false} className={classes.map}>
                        <TileLayer
                            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        <Marker position={[38.828464196298754, -77.3050450021002]}>
                            <Popup>
                                Center for Secure Information Systems
                            </Popup>
                        </Marker>
                    </MapContainer>
                </Grid>
            <Footer />
        </Grid>
    )
}