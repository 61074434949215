import React from 'react';
import { Box, Container, Typography, Grid, makeStyles, Link } from '@material-ui/core';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import '../fonts/font.css';

const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: "bold",
        marginTop: "2%",
        marginBottom: "3%",
        fontFamily: "Barlow",
    },
}))

/**
 * DEPRECIATED (Not is use as of 05/12/2022 - TRC)
 * 
 * @author Kaan Turkmen
 * @returns Documents
 */
export default function Documents() {
    const classes = useStyles();

    return (
        <Box>
            <Navbar />
            <Typography variant="h5" align="center" className={classes.title} paragraph>Documents</Typography>
            {/* <Footer /> */}
        </Box>
    )
}