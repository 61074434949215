export function findCWE(data, cwe) {
    // console.log("findCWE -- PreSort");
    // console.log(data);

    // Sort the passed in data (if not already sorted) - TRC
    data.sort((a, b) => {
        let aYear = parseInt(a["timeframe"].split("-")[0]);
        let bYear = parseInt(b["timeframe"].split("-")[0]);
        // Compare years before comparing months
        if(aYear < bYear) {
            return -1;
        } else if(bYear < aYear) {
            return 1;
        }
        // Compare months if years are equal
        let aMonth = parseInt(a["timeframe"].split("-")[1]);
        let bMonth = parseInt(b["timeframe"].split("-")[1]);
        // Compare months
        if(aMonth < bMonth) {
            return -1;
        } else if(bMonth < aMonth) {
            return 1;
        }
        // Return 0 if somehow the same
        return 0;
    })
    // console.log("findCWE -- PostSort");
    // console.log(data);

    let firstList = data[0]["ranking"];
    let lastList = data[11]["ranking"];
    let start = 0;
    let end = 0;

    for (let i = 0; i < firstList.length; i++) {
        if (firstList[i]["CWE ID"] === cwe) {
            start = i;
            break;
        }
    }

    for (let j = 0; j < lastList.length; j++) {
        if (lastList[j]["CWE ID"] === cwe) {
            end = j;
            break;
        }
    }

    return start - end;
}

// Check if passed in value is within the range (inclusive) - TRC
export function checkBoundaries(value, min, max) {
    return (value >= min && value <= max);
}

// Validate numerical value - KT
export function checkNumerical(value) {
    console.log(`${value} is a number:  ${!isNaN(value)}`)
    return !isNaN(value);
}