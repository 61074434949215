import React, { useEffect, useState } from 'react';
import { API_URL, TOTAL_HF_HEIGHT } from '../settings';
import authHeader from '../services/authHeader';
import axios from 'axios';
import {
    Divider, Button, IconButton, Container, makeStyles, Paper, Slide,
    Typography, Card, CardContent, CardActions, CardHeader, Collapse, Dialog, DialogTitle,
    DialogContent, DialogActions,
} from '@material-ui/core';
import clsx from 'clsx';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as fDate from '../services/dateFormatter.js';
import '../fonts/font.css';

const useStyles = makeStyles((theme) => ({
    userCard: {
        width: "100%",
        margin: "5px",
    },
    cardHeader: {
        padding: "5px",
        paddingBottom: "0px",
    },
    cardActions: {
        padding: "0px",
    },
    userInfo: {
        fontFamily: "Barlow"
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        padding: "5px",
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    expandContent: {
        paddingTop: "0px",
        display: "flex",
    },
    expandColumn: {
        paddingTop: "5px",
    },
    expandButtons: {
        display: "flex",
        justifyContent: "right",
    },
}))

// Upward slide transition - TRC
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Create a card representing an already accepted and now pending account request that is awaiting user response. - TRC
 * 
 * @author Thomas Campbell
 * @param {*} props All required prop information {listed below}
 *      fullname: Applicant's Fullname
 *      email: Applicant's Email
 *      role: Applicant's Role
 *      affiliation: Applicant's Affiliation
 *      timestamp: Submission Timestamp
 *      approvalDate: Date of application approval by an admin
 * @returns An instance of an Pending Account Card
 */
export default function AdminPendingAccountCard(props) {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    // Date variables
    const approvalDateRaw = new Date(props.approvalDate['$date']);
    const approvalDate = fDate.formatTimeDate12Hr(approvalDateRaw);
    // Email link (HTML) - TRC
    const emailHtml = ("mailto:" + props.email);
    // Resend Confirmation Dialog Variables
    const [showConfirm, setShowConfirm] = useState(false);

    // Declare title and subtitle outside of html (for ease of reading) - TRC
    let cardTitle = ((props.fullname == "") ? 
                        (<Typography variant="subtitle1" className={classes.userInfo} color="secondary">NO_NAME_ERROR</Typography>):
                        (<Typography variant="subtitle1" className={classes.userInfo}>{props.fullname}</Typography>)
                    );
    let subTitle = (<Typography variant="subtitle2" >
                        Approved: <b>{approvalDate}</b> | Time Elapsed: <b>{fDate.getTimeSinceDays(approvalDateRaw)}</b> | Last Email Sent: <b>{fDate.getTimeSinceDays(new Date(props.lastEmail['$date']))} ago</b>
                    </Typography>);

    // Handle expansion of the card on click - TRC
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    // Handle the click of the resend email button - TRC
    function handleResendClick() {
        setShowConfirm(true);
    }

    // Handle backend request to send reminder email - TRC
    function handleResend() {
        // Send resend request to backend
        axios.post(API_URL + '/api/resend/approval', { 
            uuid: props.uuid
         }, { headers: authHeader() })
        .then((res) => {
            cancelResend();
        })
        .catch((err) => console.log(err));
    }

    // Handle cancellation of resend in dialog box - TRC
    function cancelResend() {
        setShowConfirm(false);
    }

    // Build the confirmation dialog box
    function buildConfirmDialog() {
        return (
            <Dialog
                open={showConfirm}
                TransitionComponent={Transition}
                keepMounted
                fullWidth
                maxWidth="xs"
            >
                <DialogTitle>{"Send Reminder Confirmation"}</DialogTitle>
                <DialogContent>
                    Are you sure you want to resend account creation email to {props.fullname}?
                    <br />
                    { props.lastEmail ? ( 
                        <Typography>Last email was sent <b>{fDate.getTimeSinceDays(new Date(props.lastEmail['$date']))} ago</b>.</Typography>
                    ) : null}
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Button variant="outlined" color="primary" onClick={handleResend}>Confirm Resend</Button>
                    <Button variant="outlined" color="secondary" onClick={cancelResend}>Cancel</Button>
                </DialogActions>
            </Dialog>
        )
    }

    // Master return statement - TRC
    return (
        <Slide direction="up" in={true}>
            <Card component={Paper} elevation={0} variant="outlined" className={classes.userCard}>
                <CardHeader
                    className={classes.cardHeader}
                    avatar={<AccountBoxIcon color="primary" size="large" />}
                    title={cardTitle}
                    subheader={subTitle}
                />
                <CardActions className={classes.cardActions}>
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded,
                        })}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <Typography variant="subtitle1" className={classes.userInfo} align="center">Account Request Information:</Typography>
                    <Typography variant="subtitle2" className={classes.userInfo} align="center">Pending User ID: <b>{props.uuid}</b></Typography>
                    <CardContent className={classes.expandContent}>
                        { /* Display user information of pending account - TRC */}
                        <Container className={classes.expandColumn}>
                            <Typography variant="subtitle2" className={classes.userInfo}>Full Name: <b>{props.fullname}</b></Typography>
                            <Typography variant="subtitle2" className={classes.userInfo}>Email: <b><a href={emailHtml}>{props.email}</a></b></Typography>
                            <Typography variant="subtitle2" className={classes.userInfo}>Role: <b>{props.role}</b></Typography>
                        </Container>
                        <Container className={classes.expandColumn}>
                            <Typography variant="subtitle2" className={classes.userInfo}>Affiliation: <b>{props.affiliation}</b></Typography>
                            <Typography variant="subtitle2" className={classes.userInfo}>Application Date: <b>{fDate.formatDate(new Date(props.timestamp['$date']))}</b></Typography>
                            <Typography variant="subtitle2" className={classes.userInfo}>Approved On: <b>{fDate.formatDate(new Date(props.approvalDate['$date']))}</b></Typography>
                            { props.lastEmail ? (
                                <Typography variant="subtitle2" className={classes.userInfo}>Last Email Sent: <b>{fDate.getTimeSinceDays(new Date(props.lastEmail['$date']))} ago</b></Typography>
                            ) : null }
                        </Container>
                    </CardContent>
                    <Divider />
                    <CardActions className={classes.expandButtons}>
                        <Button variant="contained" color="primary" onClick={handleResendClick}><b>Resend Email</b></Button>
                        {
                            /* Build confirmation dialog box */
                            buildConfirmDialog()
                        }
                    </CardActions>
                </Collapse>
            </Card>
        </Slide>
    )
}