import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, FormControlLabel, Switch, FormControl, InputAdornment, FormHelperText, OutlinedInput, makeStyles } from '@material-ui/core';
import { InlineMath, BlockMath } from 'react-katex';
// import { SettingsContext } from '../contexts/SettingsContext'; Commented out to fix warnings in console - TRC
import '../fonts/font.css'; // Barlow font import - TRC

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    container: {
        width: "100%"
    },
    gridTitle: {
        fontWeight: "bold",
        margin: "2%",
        marginBottom: "3%",
        fontFamily: "Barlow",
    },
    paper: {
        padding: theme.spacing(2),
        height: "100%",
        textAlign: "center",
        marginLeft: "20%",
        marginRight: "20%"
    },
    control: {
        margin: "1%"
    },
    defaultSwitch: {
        margin: "2%"
    },
    input: {
        width: 125
    },

}));

/**
 * Create an exposure settings element onscreen
 * 
 * @author Kaan Turkmen
 * @editor Thomas Campbell
 * @comments Thomas Campbell
 * @param {*} props All required prop information {listed below}
 *      c: C Value Variable
        setC: C Setter Callback
        delta: Delta Value Variable
        setDelta: Delta Setter Callback
        setWarning: Warning status setter Callback
        setSuccess: Success Status Setter Callback
 * @returns Exposure settings onscreen
 */
export default function ExposureSettings(props) {
    const classes = useStyles();
    // const settings = useContext(SettingsContext); Commented out to fix warnings in console - TRC
    // Boolean controlling use of exposure defaults - Comment: TRC, Author: KT
    const [exposureDefaults, toggleExposureDefaults] = useState(true);

    // Events for callback - Comment: TRC, Author: KT
    const onCUpdate = (e) => props.setC(e.target.value);
    const onDeltaUpdate = (e) => props.setDelta(e.target.value);

    // useEffect to set when defaults are activated - Comment: TRC, Author: KT
    useEffect(() => {
        if (exposureDefaults && (props.delta !== 0 || props.c !== 0.5)) {
            props.setDelta(0);
            props.setC(0.5);
        }
    }, [exposureDefaults]);

    // Master Return Statement - Comment: TRC, Author: KT
    return (
        <Paper className={classes.paper}>
            <Box>
                <Typography align="center" className={classes.gridTitle}>Exposure Factor</Typography>
            </Box>
            <Box>
                <BlockMath math="ef(v,h) = \frac{1-e^{-c\cdot impact(v)}}{e^{\delta \cdot |IDS_d(v)|}}" />
            </Box>
            <Box className={classes.defaultSwitch}>
                <FormControlLabel
                    control={<Switch color="primary" checked={exposureDefaults} onChange={() => toggleExposureDefaults(!exposureDefaults)} name="likelihoodSwitch" />}
                    label="Use default values"
                />
            </Box>
            <Box>
                <FormControl className={classes.control}>
                    <OutlinedInput
                        id="standard-adornment-weight"
                        onChange={onCUpdate}
                        startAdornment={<InputAdornment position="start"><InlineMath>c</InlineMath></InputAdornment>}
                        aria-describedby="standard-weight-helper-text"
                        inputProps={{
                            'aria-label': 'weight',
                        }}
                        disabled={exposureDefaults}
                        value={props.c}
                        margin="dense"
                        className={classes.input}
                    />
                    <FormHelperText id="standard-weight-helper-text">{"[0, +∞)"}</FormHelperText>
                </FormControl>
                <FormControl className={classes.control}>
                    <OutlinedInput
                        id="standard-adornment-weight"
                        onChange={onDeltaUpdate}
                        startAdornment={<InputAdornment position="start"><InlineMath>\delta</InlineMath></InputAdornment>}
                        aria-describedby="standard-weight-helper-text"
                        inputProps={{
                            'aria-label': 'weight',
                        }}
                        disabled={exposureDefaults}
                        value={props.delta}
                        margin="dense"
                        className={classes.input}
                    />
                    <FormHelperText id="standard-weight-helper-text">{"[0, 1]"}</FormHelperText>
                </FormControl>
            </Box>
        </Paper>
    )
}