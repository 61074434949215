import React, { useEffect, useState } from 'react';
import { API_URL, TOTAL_HF_HEIGHT } from '../settings';
import authHeader from '../services/authHeader';
import {
    Divider, Button, IconButton, Container, makeStyles, Paper, Slide,
    Typography, Card, CardContent, CardActions, CardHeader, Collapse, TextField,
} from '@material-ui/core';
import clsx from 'clsx';
import InfoIcon from '@material-ui/icons/Info';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as fDate from '../services/dateFormatter.js';
import '../fonts/font.css';

const useStyles = makeStyles((theme) => ({
    userCard: {
        width: "100%",
        margin: "5px",
    },
    cardHeader: {
        padding: "5px",
        paddingBottom: "0px",
    },
    cardActions: {
        padding: "0px",
    },
    userInfo: {
        fontFamily: "Barlow"
    },
    userInfoBox: {
        fontFamily: "Barlow",
        padding: "5px",
        outline: "1px solid black",
        borderRadius: "5px",
        width: "85%",
        margin: "auto",
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        padding: "5px",
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    expandBody: {
        paddingTop: "0px",
    },
    expandContent: {
        paddingTop: "0px",
        display: "flex",
    },
    expandColumn: {
        paddingTop: "5px",
    },
    expandButtons: {
        display: "flex",
        justifyContent: "right",
    },
    reasoningContainer: {
        width: "95%",
        margin: "auto"
    },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Create a card representing a submitted information change request awaiting admin action. - TRC
 * 
 * @author Thomas Campbell
 * @param {*} props All required prop information {listed below}
 *      requestID: ID number for the ICR
        oldFullname: Old/PreChange Fullname
        newFullname: New Fullname
        oldUsername: Old/PreChange Username
        newUsername: New Username
        oldEmail: Old/PreChange Email
        newEmail: New Email
        oldRole: Old/PreChange Role
        newRole: New Role
        oldAffiliation: Old/PreChange Affiliation
        newAffiliation: New Affiliation
        timestamp: Timestamp of ICR submission
        acceptFunc: Function passed in to accept the request
        denyFuck: Function passed in to deny the request
 * @returns An instance of an information change request card
 */
export default function AdminInfoChangeRequestCard(props) {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const [reasoning, setReasoning] = useState("");
    const submissionDate = fDate.formatTimeDate12Hr(new Date(props.timestamp['$date']));
    const arrow = "->";
    // Email links to allow for HTML placement - TRC
    const oldEmailHtml = ("mailto:" + props.oldEmail);
    const newEmailHtml = ("mailto:" + props.newEmail);

    let cardTitle = ((props.requestID == "") ? 
                        (<Typography variant="subtitle1" className={classes.userInfo} color="secondary">ERR</Typography>):
                        (<Typography variant="subtitle1" className={classes.userInfo}>REQUEST #{props.requestID}</Typography>)
                    );
    let subTitle = (<Typography variant="subtitle2">{props.oldUsername} <b>{arrow}</b> {props.newUsername}</Typography>);

    // Handle expansion of the card - TRC
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    // Handle approval of the request [CALLS PASSED IN FUNCTION] - TRC
    function handleApprove() {
        props.acceptFunc(props.requestID);
        setExpanded(false);
    }

    // Return true if the admin has provided a reason - TRC
    function checkReason() {
        if(reasoning != "") {
            return false;
        }
        return true;
    }

    // Handle denial of the ICR - TRC
    function handleDeny() {
        props.acceptFunc(props.requestID, reasoning);
        setExpanded(false);
    }

    // Set the reasoning variable with each update of the reasoning provided by admin - TRC
    function handleSetReasoning(e) {
        setReasoning(e.target.value);
    }

    // Master return statement - TRC
    return (
        <Slide direction="up" in={true}>
            <Card component={Paper} elevation={0} variant="outlined" className={classes.userCard}>
                <CardHeader
                    className={classes.cardHeader}
                    avatar={<InfoIcon color="primary" size="large" />}
                    title={cardTitle}
                    subheader={subTitle}
                />
                <CardActions className={classes.cardActions}>
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded,
                        })}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent className={classes.expandBody}>
                        <Typography variant="subtitle1" className={classes.userInfo} align="center"><b>Information Change Request Details:</b></Typography>
                        <Typography variant="subtitle2" className={classes.userInfo} align="center">User Identifier: <b>{props.uuid}</b></Typography>
                        <Typography variant="subtitle2" className={classes.userInfo} align="center">Submission Timestamp: <b>{submissionDate}</b></Typography>
                        <Container className={classes.expandContent}>
                            <Container className={classes.expandColumn}>
                                { /* Display old account information - TRC */ }
                                <Typography variant="subtitle1" className={classes.userInfo} align="center"><u>Old Information:</u></Typography>
                                <Typography variant="subtitle2" className={classes.userInfo}>Username: <b>{props.oldUsername}</b></Typography>
                                <Typography variant="subtitle2" className={classes.userInfo}>Full Name: <b>{props.oldFullname}</b></Typography>
                                <Typography variant="subtitle2" className={classes.userInfo}>Email: <b><a href={oldEmailHtml}>{props.oldEmail}</a></b></Typography>
                                <Typography variant="subtitle2" className={classes.userInfo}>Affiliation: <b>{props.oldAffiliation}</b></Typography>
                                <Typography variant="subtitle2" className={classes.userInfo}>Role: <b>{props.oldRole}</b></Typography>
                            </Container>
                            <Container className={classes.expandColumn}>
                                { /* Display new (requested) account information - TRC */ }
                                <Typography variant="subtitle1" className={classes.userInfo} align="center"><u>New Information:</u></Typography>
                                <Typography variant="subtitle2" className={classes.userInfo}>Username: <b>{props.newUsername}</b></Typography>
                                <Typography variant="subtitle2" className={classes.userInfo}>Full Name: <b>{props.newFullname}</b></Typography>
                                <Typography variant="subtitle2" className={classes.userInfo}>Email: <b><a href={newEmailHtml}>{props.newEmail}</a></b></Typography>
                                <Typography variant="subtitle2" className={classes.userInfo}>Affiliation: <b>{props.newAffiliation}</b></Typography>
                                <Typography variant="subtitle2" className={classes.userInfo}>Role: <b>{props.newRole}</b></Typography>
                            </Container>
                        </Container>
                        <br />
                        <Typography variant="subtitle2" className={classes.userInfo} align="center"><b>Reasoning Provided:</b></Typography>
                        <Typography variant="subtitle2" className={classes.userInfoBox} align="left">{props.reasoning}</Typography>
                    </CardContent>
                    <Divider />
                    <Container className={classes.reasoningContainer}>
                        <br />
                        { /* Display reasoning field to show the user if they are denied an information change request - TRC */ }
                        <Typography variant="subtitle2" className={classes.userInfo} align="left"><b>Admin Reasoning (<i>Required to Deny Request</i>):</b></Typography>
                        <TextField
                            margin="dense"
                            id="denialReasoning"
                            label="Reason for Denial"
                            type="string"
                            onChange={handleSetReasoning}
                            value={reasoning}
                            variant="outlined"
                            color="primary"
                            autoComplete="off"
                            multiline
                            minRows={3}
                            fullWidth
                        />
                    </Container>
                    <CardActions className={classes.expandButtons}>
                        <Button variant="contained" color="primary" onClick={handleApprove}><b>APPROVE</b></Button>
                        <Button variant="contained" color="secondary" onClick={handleDeny} disabled={checkReason()}><b>DENY</b></Button>
                    </CardActions>
                </Collapse>
            </Card>
        </Slide>
    )
}