import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
    Box, AppBar, Toolbar,
    IconButton, Typography, Drawer, ListItem,
    ListItemText,
    Collapse, List
} from '@material-ui/core';
import { Link, Redirect } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import { logout } from '../services/authService';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { tokenExists } from '../services/authHeader';
import * as urls from '../urls';
import '../fonts/font.css';


const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: "red",
        width: "100%",
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    title: {
        flexGrow: 1,
        fontFamily: "Barlow",
    },
    titleLink: {
        color: "white",
        fontSize: "1.2em",
        textTransform: "none",
        textDecoration: "none",
    },
    sideBar: {
        backgroundColor: "#3F50B5",
        color: "white",
        width: '100%',
        height: '100%',
        padding: "4%",
        paddingTop: "15%",
    },
    link: {
        textDecoration: "none",
        color: "white",
        width: "100%",
    },
    nested: {
        marginLeft: "10%"
    },
    sticky: {
        position: "fixed",
        top: "0",
        width: "100%",
    }
}));

/**
 * Create website-wide header
 * 
 * @author Kaan Turkmen
 * @editor Thomas Campbell
 * @comments Thomas Campbell
 * @param {*} props
 *      children: Children of this header
 * @returns Header at top of a page
 */
export default function Navbar(props) {
    const classes = useStyles();
    const [menuOpen, setMenuOpen] = useState(false);
    const [generateOpen, setGenerateOpen] = useState(false);
    const [customizableOpen, setCustomizableOpen] = useState(false);
    const [contactOpen, setContactOpen] = useState(false);

    // Handle clicking of the SignOut Button - Comments: TRC, Author: KT
    const handleSignOut = () => {
        logout();
    }

    // Master Return Statement - Comments: TRC, Author: KT
    return (
        <Box className={classes.container}>
            <AppBar position="fixed">
                <Toolbar>
                    {tokenExists() ?
                        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={() => setMenuOpen(true)}>
                            <MenuIcon />
                        </IconButton> : null
                    }
                    <Typography className={classes.title}>
                        { /* Make Text into Link based off of if the user is logged in or not - TRC */ }
                        { tokenExists() ? (
                            <Link className={classes.titleLink} to={"/"} >
                                Mason Vulnerability Scoring Framework
                            </Link>
                        ): 
                            <Link className={classes.titleLink} to={urls.loginURL} >
                                Mason Vulnerability Scoring Framework
                            </Link>
                        }
                    </Typography>
                    {props.children}
                </Toolbar>
            </AppBar>
            <Toolbar /> 
            { /* The second empty Toolbar object is to keep the Appbar from messing with the page layout. - TRC */ }
            {tokenExists() ?
                <Drawer open={menuOpen} onClose={() => setMenuOpen(false)} anchor="left">
                    <div className={classes.sideBar} role="presentation">
                        <ListItem button key="Historical Ranking">
                            <Link to="/" className={classes.link}><ListItemText>Home</ListItemText></Link>
                        </ListItem>
                        <div onMouseLeave={() => setContactOpen(false)}>
                            <ListItem button key="About" onMouseOver={() => setContactOpen(true)}>
                                <Link to={urls.aboutURL} className={classes.link}>
                                    <ListItemText>About</ListItemText>
                                </Link>
                                {contactOpen ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={contactOpen} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    {/* <Link to="/historical" className={classes.link}><ListItem button className={classes.nested}>Show Ranking</ListItem></Link> */}
                                    <Link to={urls.instructionsURL} className={classes.link}><ListItem button key="Instructions" className={classes.nested}>Instructions</ListItem></Link>
                                    <Link to={urls.contactURL} className={classes.link}><ListItem button className={classes.nested}>Contact Us</ListItem></Link>
                                </List>
                            </Collapse>
                        </div>
                        <Link to={urls.historicalRankingsURL} className={classes.link}><ListItem button key="historical"><ListItemText>Historical Rankings</ListItemText></ListItem></Link>
                        <div onMouseLeave={() => setCustomizableOpen(false)}>
                            <ListItem button key="Live" onMouseOver={() => setCustomizableOpen(true)}>
                                <Link to={urls.liveRankingsURL} className={classes.link}><ListItemText>Live Rankings</ListItemText></Link>
                                {customizableOpen ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={customizableOpen} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <Link to={urls.settingsURL} className={classes.link}><ListItem button className={classes.nested}>Settings</ListItem></Link>
                                </List>
                            </Collapse>
                        </div>
                        <Link to={urls.customRankingsURL} className={classes.link}><ListItem button key="Custom"><ListItemText>Custom Rankings</ListItemText></ListItem></Link>
                        <Link to={urls.cveSearchURL} className={classes.link}><ListItem button key="CVESearch"><ListItemText>CVE Search</ListItemText></ListItem></Link>
                        {/* <Link to="/documents" className={classes.link}><ListItem button key="Documents"><ListItemText>Documents</ListItemText></ListItem></Link> */}
                        <Link to={urls.accountURL} className={classes.link}><ListItem button key="MyAccount"><ListItemText>My Account</ListItemText></ListItem></Link>
                        <Link to={urls.loginURL} className={classes.link}><ListItem button key="SignOut" onClick={() => handleSignOut()}><ListItemText>Sign Out</ListItemText></ListItem></Link>
                    </div>
                </Drawer> : null}
        </Box>
    )
}