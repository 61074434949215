import React, { useEffect, useState } from 'react';
import { API_URL, TOTAL_HF_HEIGHT } from '../settings';
import authHeader from '../services/authHeader';
import {
    Divider, Button, Box, Container, makeStyles,
    Typography, Paper, Backdrop, List, ListItem,
    ListItemIcon, ListItemText, CircularProgress,
    Card, CardContent, Dialog, Slide, DialogActions, DialogTitle, DialogContent, DialogContentText,
} from '@material-ui/core';
// Icon import statements (these wont work as a bundled import for some reason) - TRC
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import InfoIcon from '@material-ui/icons/Info';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import HelpOutlinedIcon from '@material-ui/icons/HelpOutlined';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import * as urls from '../urls';
import axios from 'axios';
import * as fDate from '../services/dateFormatter.js'
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import { logout } from '../services/authService';
import '../fonts/font.css';
import { getAccountRequests, getPendingAccountRequests, getInformationChangeRequests, getUsers, getCronUpdates } from '../services/adminPanel';
import AdminUserCard from '../components/AdminUserCard';
import AdminAccountRequestCard from '../components/AdminAccountRequestCard';
import AdminPendingAccountCard from '../components/AdminPendingAccountCard';
import AdminInfoChangeRequestCard from '../components/AdminInfoChangeRequestCard';
import AdminCronjobCard from '../components/AdminCronjobCard';


const useStyles = makeStyles((theme) => ({
    container: {
        width: "100%",
        // Statement below makes footer anchor to bottom 
        // (Only needed on pages where the information would result in an incorrect footer )- TRC
        minHeight: "calc(100vh - " + TOTAL_HF_HEIGHT + ")",
    },
    title: {
        fontWeight: "bold",
        marginTop: "2%",
        marginBottom: "2%",
        fontFamily: "Barlow",
    },
    metrics: {
        background: "#eeeeee",
        width: "100%",
        marginTop: "10px",
        marginBottom: "10px",
    },
    metricField: {
        minHeight: "1vh",
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    navList: {
        background: "#f5f5f5",
    },
    loadingWheel: {
        zIndex: 10
    },
    infoHolder: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
    },
    leftInfo: {
        width: "55%",
    },
    navListCount: {
        color: "red",
        fontSize: "15px",
    },
    trayContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "10px",
        marginRight: "0px",
        width: "100%",
        minHeight: "75vh",
        background: "#eeeeee",
    },
    useBarlow: {
        fontFamily: "Barlow",
    },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Admin Panel Page
 * Located at: https://mvsf.gmu.edu/admin [REQUIRES ADMIN ACCESS]
 * 
 * @author Thomas Campbell
 * @comments Thomas Campbell
 * @returns Admin Page
 */
export default function Admin() {
    const classes = useStyles();
    // Loading Variable
    const [loading, toggleLoading] = useState(false);
    // Admin user account information - TRC
    const [username, setUsername] = useState('');
    const [fullname, setFullname] = useState('');
    const [email, setEmail] = useState('');
    const [permissions, setPermissions] = useState('');
    // Navigation Variables
    const [selectedIndex, setSelectedIndex] = useState(0);
    // Data variables
    const [accountRequests, setAccountRequests] = useState([]);
    const [pendingRequests, setPendingRequests] = useState([]);
    const [infoChangeRequests, setInfoChangeRequests] = useState([]);
    const [users, setUsers] = useState([]);
    const [cronUpdates, setCronUpdates] = useState([]);
    // Date variable - TRC
    let currentdate = new Date();
    const [lastUpdated, setLastUpdated] = useState("");
    // Admin Log Dialog Controller
    const [showAdminLog, toggleAdminLog] = useState(false);
    const [scroll, setScroll] = React.useState('paper');
    const [logText, setLogText] = useState(``);

    // Handle data from the backend and organize it into the correct variables - Author: KT, Comment: TRC
    async function getRequestData() {
        const accountReqs = await getAccountRequests();
        const pendingReqs = await getPendingAccountRequests();
        const infoChangeReqs = await getInformationChangeRequests();
        const userList = await getUsers();
        const cronUpdates = await getCronUpdates();
        setAccountRequests(accountReqs);
        setPendingRequests(pendingReqs);
        setInfoChangeRequests(infoChangeReqs);
        setUsers(userList);
        setCronUpdates(cronUpdates);
    }

    // Pull account information from backend, runs every refresh - TRC
    useEffect(() => {
        // Toggle the loading bar
        toggleLoading(true);
        // Pull account information from backend
        axios.get(API_URL + '/api/account', { headers: authHeader() })
            .then((res) => {
                setUsername(res.data['username']);
                setFullname(res.data['fullname']);
                setEmail(res.data['email']);
                setPermissions(res.data['admin']);

                // Update the date label
                updateTime();
                // Update all data
                getRequestData();

                if (localStorage.getItem("adPage")) {
                    setSelectedIndex(parseInt(localStorage.getItem("adPage")));
                } else {
                    localStorage.setItem("adPage", "" + selectedIndex);
                }

                toggleLoading(false);
            })
            .catch((err) => console.log(err));
    }, []);

    // Update the label to the time at function call - TRC
    function updateTime() {
        let temp = new Date();
        setLastUpdated(fDate.formatTimeDate12Hr(temp));
    }

    // Check user list and get admin count - TRC
    function adminCount() {
        let count = 0;
        for (var i = 0; i < users.length; i++) {
            if (users[i].admin == true) {
                count++;
            }
        }
        return count;
    }

    // Shows current metrics for requests requiring admin interaction - TRC
    function buildMetrics() {
        return (
            <Container component={Paper} elevation={3} className={classes.metrics}>
                <Typography variant="subtitle1" align="center">Metrics</Typography>
                <Divider />
                <Container className={classes.metricField}>
                    <Typography variant="subtitle2">Registered Users:</Typography>
                    <Typography variant="subtitle2">{users?.length}</Typography>
                </Container>
                <Container className={classes.metricField}>
                    <Typography variant="subtitle2">Admin Users:</Typography>
                    <Typography variant="subtitle2">{adminCount()}</Typography>
                </Container>
            </Container>
        )
    }

    // Set selected index on list click - TRC
    function handleListItemClick(e, index) {
        setSelectedIndex(index);
        localStorage.setItem("adPage", "" + index);
    }

    // Navigation menu list for admins to view all types of requests - TRC
    /*  NOTE: We can use custom icons if needed, not sure on the icon restrictions here. - TRC */
    function buildActionList() {
        return (
            <Container component={Paper} elevation={3} className={classes.navList}>
                <List aria-label="Main admin control lists">
                    <ListItem
                        button
                        selected={selectedIndex === 0}
                        onClick={(event) => handleListItemClick(event, 0)}
                    >
                        <ListItemIcon>
                            <PermIdentityIcon color="primary" size="large" />
                        </ListItemIcon>
                        <ListItemText primary="User List" />
                    </ListItem>
                    <ListItem
                        button
                        selected={selectedIndex === 1}
                        onClick={(event) => handleListItemClick(event, 1)}
                    >
                        <ListItemIcon>
                            <PersonAddOutlinedIcon color="primary" size="large" />
                        </ListItemIcon>
                        <ListItemText primary="Account Requests" />
                        <Typography className={classes.navListCount}>{accountRequests?.length ?? "#"}</Typography>
                    </ListItem>
                    <ListItem
                        button
                        selected={selectedIndex === 2}
                        onClick={(event) => handleListItemClick(event, 2)}
                    >
                        <ListItemIcon>
                            <AccountBoxIcon color="primary" size="large" />
                        </ListItemIcon>
                        <ListItemText primary="Pending Accounts" />
                        <Typography className={classes.navListCount}>{pendingRequests?.length ?? "#"}</Typography>
                    </ListItem>
                    <ListItem
                        button
                        selected={selectedIndex === 3}
                        onClick={(event) => handleListItemClick(event, 3)}
                    >
                        <ListItemIcon>
                            <InfoIcon color="primary" size="large" />
                        </ListItemIcon>
                        <ListItemText primary="Information Change Requests" />
                        <Typography className={classes.navListCount}>{infoChangeRequests?.length ?? "#"}</Typography>
                    </ListItem>
                    <ListItem
                        button
                        selected={selectedIndex === 4}
                        onClick={(event) => handleListItemClick(event, 4)}
                    >
                        <ListItemIcon>
                            <LiveHelpIcon color="primary" size="large" />
                        </ListItemIcon>
                        <ListItemText primary="Cronjob Executions" />
                        <Typography className={classes.navListCount}>{cronUpdates?.length ?? "#"}</Typography>
                    </ListItem>
                    <ListItem
                        button
                        selected={selectedIndex === 5}
                        onClick={(event) => handleListItemClick(event, 5)}
                    >
                        <ListItemIcon>
                            <HelpOutlinedIcon color="primary" size="large" />
                        </ListItemIcon>
                        <ListItemText primary="Support Tickets" />
                        <Typography className={classes.navListCount}>{"N/A"}</Typography>
                    </ListItem>
                </List>
            </Container>
        )
    }

    // Display the list of user cards - TRC
    function showUserList() {
        if (users) {
            return (
                <React.Fragment>
                    {users.map((element) => (
                        <AdminUserCard
                            ID={element.uuid}
                            username={element.username}
                            fullname={element.fullname}
                            email={element.email}
                            role={element.role}
                            affiliation={element.affiliation}
                            admin={element.admin}
                            joinDate={element.created_at}
                        />
                    ))}
                </React.Fragment>
            )
        }
    }

    // Display the list of Account Requests - TRC
    function showAccountRequests() {
        return (
            <React.Fragment>
                {accountRequests.map((element) => (
                    <AdminAccountRequestCard
                        fullname={element.name}
                        uuid={element.uuid}
                        username={element.username}
                        email={element.email}
                        role={element.role}
                        affiliation={element.affiliation}
                        timestamp={element.created_at}
                        approveFunc={approveAccRequest}
                        denyFunc={denyAccReuest}
                    />
                ))}
            </React.Fragment>
        )
    }

    // Handle approval of an account request - TRC
    async function approveAccRequest(uuid) {
        toggleLoading(true);

        axios.post(API_URL + '/api/approve/account/request', {
            uuid: uuid,
            approved: true,
        }, { headers: authHeader() })
            .then((res) => {
                getRequestData();
                setSelectedIndex(1);
                toggleLoading(false);
            }).catch((err) => console.log(err));
    }

    // Handle denial of an account request - TRC
    async function denyAccReuest(uuid, reason) {
        toggleLoading(true);

        axios.post(API_URL + '/api/approve/account/request', {
            uuid: uuid,
            approved: false,
            reason: reason,
        }, { headers: authHeader() })
            .then((res) => {
                getRequestData();
                setSelectedIndex(1);
                toggleLoading(false);
            }).catch((err) => console.log(err));
    }

    // Display the list of PENDING Account Requests - TRC
    function showPendingAccountRequests() {
        return (
            <React.Fragment>
                {pendingRequests.map((element) => (
                    <AdminPendingAccountCard
                        fullname={element.name}
                        uuid={element.uuid}
                        username={element.username}
                        email={element.email}
                        role={element.role}
                        affiliation={element.affiliation}
                        timestamp={element.created_at}
                        lastEmail={element.email_sent_at}
                        approvalDate={element.approved_at}
                    />
                ))}
            </React.Fragment>
        )
    }

    // Display the list of information change requests - TRC
    function showInfoChangeRequests() {
        return (
            <React.Fragment>
                {infoChangeRequests.map((element) => (
                    <AdminInfoChangeRequestCard
                        requestID={element.request_id}
                        uuid={element.uuid}
                        oldFullname={element.old_fullname}
                        newFullname={element.new_fullname}
                        oldUsername={element.old_username}
                        newUsername={element.new_username}
                        oldEmail={element.old_email}
                        newEmail={element.new_email}
                        oldRole={element.old_role}
                        newRole={element.new_role}
                        oldAffiliation={element.old_affiliation}
                        newAffiliation={element.new_affiliation}
                        reasoning={element.reasoning}
                        timestamp={element.created_at}
                        acceptFunc={accInfoChangeReq}
                        denyFunc={denyInfoChangeReq}
                    />
                ))}
            </React.Fragment>
        )
    }

    // Display cronjob execution details - TRC
    // Call AdminCronjobCard
    function showCronjobExecs() {
        console.log(cronUpdates);
        return (
            <React.Fragment>
                {cronUpdates.map((element) => (
                    <AdminCronjobCard
                        title={element._id.split('-').map(elem => elem.charAt(0).toUpperCase() + elem.slice(1)).join(' ')}
                        timestamp={element.timestamp}
                    />
                ))}
            </React.Fragment>
        )
    }

    // Handle acceptance of an infromation change request - TRC
    async function accInfoChangeReq(request_id) {
        toggleLoading(true);

        axios.post(API_URL + '/api/approve/info/change', {
            rid: request_id,
            approved: true,
            reason: "N/A",
        }, { headers: authHeader() })
            .then((res) => {
                getRequestData();
                setSelectedIndex(3);
                toggleLoading(false);
            }).catch((err) => console.log(err));
    }

    // Handle denial of an infromation change request - TRC
    async function denyInfoChangeReq(request_id, reasoning) {
        toggleLoading(true);

        axios.post(API_URL + '/api/approve/info/change', {
            rid: request_id,
            approved: false,
            reason: reasoning,
        }, { headers: authHeader() })
            .then((res) => {
                getRequestData();
                setSelectedIndex(3);
                toggleLoading(false);
            }).catch((err) => console.log(err));
    }

    // Handle the opening of the "Admin Log" dialog - TRC
    async function handleAdminOpen() {
        toggleAdminLog(true);
        toggleLoading(true);
        retrieveAdminLogs();
    }

    // Handle the closure of the "Admin Log" dialog - TRC
    function handleAdminClose() {
        toggleAdminLog(false);
    }

    // Pull the admin logs from the backend -TRC
    function retrieveAdminLogs() {
        toggleLoading(true);

        // Retrieve admin logs from backend
        axios.get(API_URL + '/api/get/admin/logs', { headers: authHeader() })
            .then((res) => {
                setLogText(`${res.data}`);
                toggleLoading(false);
            })
            .catch((err) => console.log(err))
    }

    // Refresh the admin logs in the dialog page - TRC
    function handleAdminRefresh() {
        retrieveAdminLogs();
    }

    // Build Fullscreen "Admin Log" dialog box - TRC
    function buildAdminDialog() {
        return (
            <Dialog
                maxWidth="lg"
                fullWidth
                open={showAdminLog}
                TransitionComponent={Transition}
                onClose={handleAdminClose}
            >
                <DialogTitle>
                    <Typography className={classes.useBarlow} variant="h5" align="left">Admin Action Logs</Typography>
                    <Typography className={classes.useBarlow} variant="subtitle1" align="left">Last Updated: <b>{fDate.formatTimeDate(new Date())}</b></Typography>
                </DialogTitle>

                <DialogContent dividers>
                    {
                        /* Display admin log text - Requires a split in order to respect whitespace */
                        logText.split("\n").reverse().map((line) => (
                            <Typography variant="subtitle2" align="left">{line}</Typography>
                        ))
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAdminRefresh} variant="contained" color="primary"><b>Refresh Logs</b></Button>
                    <Button onClick={handleAdminClose} variant="contained" color="secondary"><b>Close</b></Button>
                </DialogActions>
            </Dialog>
        )
    }



    // Master return statement - TRC
    return (
        <Box className={classes.box}>
            <Navbar >
                <Button color="inherit" onClick={handleAdminOpen}>Admin Logs</Button>
            </Navbar>
            { /* Loading bar while awaiting backend response and permission validation - TRC */}
            <Backdrop className={classes.loadingWheel} open={loading}>
                <CircularProgress />
            </Backdrop>
            <Container className={classes.container} disableGutters>
                <Typography variant="h5" align="center" className={classes.title} paragraph>Admin Panel</Typography>
                <Typography variant="subtitle1" align="center">Currently logged in as: <b>{username}</b></Typography>
                <Typography variant="subtitle2" align="center">Information updated as of: <b>{lastUpdated}</b></Typography>
                <Typography variant="subtitle2" align="center">Refresh the page to update information</Typography>
                <hr />
                <Container className={classes.infoHolder}>
                    <Container className={classes.leftInfo}>
                        {
                            /* Build Metrics Panel - TRC */
                            buildMetrics()
                        }
                        {
                            /* Build Navigation Pane */
                            buildActionList()
                        }
                    </Container>
                    <Container className={classes.rightInfo}>
                        <Typography variant="caption">Information is populated by/pertains to the selected list on the left. <b>NOTE:</b> ALL admin actions are recorded.</Typography>
                        <Container component={Paper} className={classes.trayContent} elevation={3}>
                            {selectedIndex == 0 ? (
                                <React.Fragment>
                                    {
                                        /* Display a list of all the users currently registered in the backend - TRC */
                                        showUserList()
                                    }
                                </React.Fragment>
                            ) : null}
                            {selectedIndex == 1 ? (
                                <React.Fragment>
                                    {
                                        /* Display a list of all account requests - TRC */
                                        showAccountRequests()
                                    }
                                </React.Fragment>
                            ) : null}
                            {selectedIndex == 2 ? (
                                <React.Fragment>
                                    {
                                        /* Display a list of all PENDING account requests - TRC */
                                        showPendingAccountRequests()
                                    }
                                </React.Fragment>
                            ) : null}
                            {selectedIndex == 3 ? (
                                <React.Fragment>
                                    {
                                        /* Display a list of all information change requests - TRC */
                                        showInfoChangeRequests()
                                    }
                                </React.Fragment>
                            ) : null}
                            {selectedIndex == 4 ? (
                                <React.Fragment>
                                    {
                                        /* Display a list of all information change requests - TRC */
                                        showCronjobExecs()
                                    }
                                </React.Fragment>
                            ) : null}
                        </Container>
                    </Container>
                </Container>
            </Container>
            {
                /* Display/Build the admin log dialog box */
                buildAdminDialog()
            }
            <Footer />
        </Box>
    )
}