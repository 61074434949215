import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { TOTAL_HF_HEIGHT } from '../settings';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Container, Typography, Grid, Button, Link } from '@material-ui/core';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import customScreenshot from '../images/screenshots/custom-rankings.png';
import liveScreenshot from '../images/screenshots/live-rankings.png';
import historicalScreenshot from '../images/screenshots/historical-rankings.png';
import cveSearchScreenshot from '../images/screenshots/cve-search.png';
import liveRankingsPopupScreenshot from '../images/screenshots/live-rankings-popup.png';
import cveCompositionScreenshot from '../images/screenshots/cve-composition.png';
import '../fonts/font.css';

import { Redirect } from 'react-router-dom';
import * as urls from '../urls';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    container: {
        width: "100%",
        // Statement below makes footer anchor to bottom - TRC
        minHeight: "calc(100vh - " + TOTAL_HF_HEIGHT + ")",
    },
    content: {
        width: "84%",
    },
    title: {
        fontWeight: "bold",
        marginTop: "3%",
        marginBottom: "3%",
        fontSize: 26,
        fontFamily: "Barlow",
    },
    subtitle: {
        fontWeight: "bold",
        marginBottom: "2%",
    },
    tempText: {
        fontStyle: "italic"
    }
}));

/**
 * Instructions page under about
 * Located at: https://mvsf.gmu.edu/instructions
 * 
 * @author Kaan Turkmen
 * @editor Thomas Campbell
 * @comments Thomas Campbell
 * @param {*} props 
 *      history: Browser History Object
 * @returns Instructions Page
 */
export default function Instructions(props) {
    const classes = useStyles();

    // MASTER Return Statement - TRC
    return (
        <Box className={classes.root}>
            <Navbar>
                <Button color="inherit" onClick={() => props.history.push(urls.contactURL)}>Contact Us</Button>
            </Navbar>
            <Container className={classes.container} disableGutters>
                <Typography variant="h5" align="center" className={classes.title} paragraph>Instructions</Typography>
                <hr />
                <Container className={classes.content}>
                    <Typography paragraph>
                        There are three main functionalities of the Mason Vulnerability Scoring Framework. The historical rankings, custom rankings and live rankings. All three will be detailed below.
                    </Typography>
                    <Typography className={classes.subtitle} variant="subtitle1"><a href={urls.historicalRankingsURL}>Historical Rankings</a></Typography>

                    <Typography paragraph>
                        The landing page of the application includes historical rankings. Historical rankings are static rankings for a particular month calculated using our optimized parameters. 
                        That means the ranking is computed using the vulnerability and IDS information at the end of the month and is not subject to any changes.
                    </Typography>
                    <img src={historicalScreenshot} width="100%" style={{ marginTop: "3%", marginBottom: "3%" }} />

                    <a href={urls.liveRankingsURL}><Typography className={classes.subtitle} variant="subtitle1">Live Rankings</Typography></a>
                    <Typography paragraph>
                        Monthly Rankings information can be found under “Live Rankings” link in the menu. This is a more high-level overview of the recent months. 
                        It shows separate rankings for a 12 months range. The parameters used for this calculation is indicated at the top of the table and can be changed using <a href={urls.settingsURL}>settings</a>.
                    </Typography>
                    <Typography paragraph>
                        To view the parameter settings, click the settings button on the top right. By default, “Use default values” switch is turned on and default values are supplied for the parameters. 
                        To use custom parameters, turn off the switch, change parameter values and click “Save Changes”. Once the parameters are updated successfully, a new “View New Ranking” button appears. 
                        This button can be used to view the new ranking. That being said, once “Save Changes” button is clicked, the new settings are saved and the “View New Ranking” button is not required to save the new parameters.
                    </Typography>
                    <img src={liveScreenshot} width="100%" style={{ marginTop: "3%", marginBottom: "3%" }} />

                    <Typography className={classes.subtitle} variant="subtitle2">CVE Composition</Typography>
                    <Typography paragraph>
                        All the information shown under <a href={urls.liveRankingsURL}>Live Rankings</a> are the outcome of a comprehensive scoring and ranking process. To get more information on this process and see what data points were 
                        involved in calculation of a particular timeframe and CWE, users can click on the cell to open a pop-up that includes various details. To dive even deeper into the ranking computation process, a user can click on 
                        the "View CVE Composition" button. When clicked, this button will show a dialog with information on each individual CVE that was used in calculating the rankings.
                    </Typography>
                    <img src={liveRankingsPopupScreenshot} width="100%" style={{ marginTop: "3%", marginBottom: "3%" }} />
                    <img src={cveCompositionScreenshot} width="100%" style={{ marginTop: "3%", marginBottom: "3%" }} />
                    
                    <a href={urls.customRankingsURL}><Typography className={classes.subtitle} variant="subtitle1">Custom Rankings</Typography></a>
                    <Typography paragraph>
                        Custom Rankings is the functionality that gives the user complete flexibility over their ranking. They can choose the parameters they want to use and the timeframe that will be used to compute the ranking. 
                        This shows one ranking based on the parameters supplied by the user and allows users to download the ranking as a read-only XLSX file.
                    </Typography>
                    <img src={customScreenshot} width="100%" style={{ marginTop: "3%", marginBottom: "3%" }} />

                    <a href={urls.cveSearchURL}><Typography className={classes.subtitle} variant="subtitle1">CVE Search</Typography></a>
                    <Typography paragraph>
                        Most of the features outlined above focus on ranking Common Weakness Enumerations (CWEs). Although these features utilize the same vulnerability scoring mechanism to rank vulnerabilities and CWEs, CVE Search, 
                        along with CVE Composition described above, allows users to view how these scores are calculated by listing individual CVE scores, including factors that influence them.
                    </Typography>
                    <Typography paragraph>
                        Enter a valid CVE into the search bar and click on the search button to use the search mechanism. Then, a card summarizing the MVSF score of the provided CVE will appear. 
                        Users can also click the arrow button at the bottom right to expand the card to view the details of the score.                    </Typography>
                    <img src={cveSearchScreenshot} width="100%" style={{ marginTop: "3%", marginBottom: "3%" }} />

                </Container>
            </Container>
            <Footer />
        </Box>
    )
}