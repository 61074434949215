import React, { useState, useEffect} from "react";
import { Box, Container, Typography, Paper, List, ListItem, ListItemText, Divider, Link, makeStyles, Button } from "@material-ui/core";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { historicalRankingsURL } from "../urls";
import '../fonts/font.css';

const useStyles = makeStyles((theme) => ({
    container: {
        alignItems: "center"
    },
    title: {
        fontWeight: "bold",
        marginTop: "2%",
        marginBottom: "2%",
        fontFamily: "Barlow",
    },
    subtitle: {
        fontWeight: "bold"
    },
    archiveBox: {
        textAlign: "center",
        margin: 'auto'
    },
    listText: {
        textAlign: "center"
    },
    paper: {
        margin: 'auto',
        width: "30%",
        marginBottom: "5%"
    }
}));

// List containing all months - TRC
const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

/**
 * ARCHIVE LIST [DEPRECIATED]
 * This isnt used (As of when im commenting this, 05/12/2022).
 * 
 * @author Kaan Turkmen
 * @comments Thomas Campbell
 * @param {*} props 
 * @returns Archive List
 */
export default function ArchiveList(props) {
    const classes = useStyles();
    const [links, setLinks] = useState({});
    
    // On load useEffect
    useEffect(() => {
        let obj = {}
        let currentMonth = new Date().getMonth();
        let currentYear = new Date().getFullYear() - 1;

        for (let i = 2021; i >= 2020; i--) {
            for (let j = currentMonth; j >= 1; j--) {
                let key = `${months[j - 1]} ${i} Ranking`;
                let value = `${i}-${months[j - 1].toLowerCase()}`;
                obj[key] = value;
            }
        }

        // axios.get(API_URL + '/get/all/historical', { headers: authHeader() })
        //     .then(res => console.log(res.data))

        console.log(obj);
        setLinks(obj);
    }, [])

    // MASTER Return Statement - TRC
    return (
        <Box>
            <Navbar />
            <Container className={classes.container} disableGutters>
                <Typography variant="h5" align="center" className={classes.title} paragraph>Archive</Typography>
                <Box className={classes.archiveBox}>
                    <Typography variant="subtitle1" align="center" className={classes.subtitle} paragraph>List of Previous Rankings</Typography>
                    <Paper className={classes.paper}>
                        <List className={classes.list}>
                            {Object.keys(links).map((key, i) => (
                                <React.Fragment>
                                    <ListItem>
                                        <ListItemText className={classes.listText}>
                                            <Link href={`${historicalRankingsURL}/${links[key]}`}>{key}</Link>
                                        </ListItemText>
                                    </ListItem>
                                    <Divider />
                                </React.Fragment>
                            ))}
                        </List>
                    </Paper>
                </Box>
            </Container>
            <Footer />
        </Box>
    )
}