// Check for a single digit time or date number and add a zero in front of it if needed - TRC
function checkDigit(intDate) {
    if(intDate < 10) {
        return ("0" + intDate);
    }
    return intDate;
}

// Format a date object to show Time then Date - TRC
export function formatTimeDate(dateObj) {
    return(dateObj.getHours() + ":" + checkDigit(dateObj.getMinutes()) + " - " + 
           checkDigit((dateObj.getMonth() + 1)) + "/" + checkDigit(dateObj.getDate()) + "/" + dateObj.getFullYear());
}

// Format a date object to show calendat Date - TRC
export function formatDate(dateObj) {
    return(checkDigit((dateObj.getMonth() + 1)) + "/" + checkDigit(dateObj.getDate()) + "/" + dateObj.getFullYear());
}

// Format a date object to show Time - TRC
export function formatTime(dateObj) {
    return(dateObj.getHours() + ":" + checkDigit(dateObj.getMinutes()) + ":" + checkDigit(dateObj.getSeconds()));
}

// Format a date object to show Time then Date (IN 12 HOUR CLOCK) - TRC
export function formatTimeDate12Hr(dateObj) {
    let hours = dateObj.getHours();
    if(hours > 12) {
        return((hours - 12) + ":" + checkDigit(dateObj.getMinutes()) + " PM" + " - " + (dateObj.getMonth() + 1) + "/" + checkDigit(dateObj.getDate()) + "/" + dateObj.getFullYear());
    } else if(hours === 12) {
        return(hours + ":" + checkDigit(dateObj.getMinutes()) + " PM" + " - " + (dateObj.getMonth() + 1) + "/" + checkDigit(dateObj.getDate()) + "/" + dateObj.getFullYear());
    }
    return(dateObj.getHours() + ":" + checkDigit(dateObj.getMinutes()) + " AM" + " - " + (dateObj.getMonth() + 1) + "/" + checkDigit(dateObj.getDate()) + "/" + dateObj.getFullYear());
}

// Format a date object to show Time (IN 12 HOUR CLOCK) - TRC
export function formatTime12Hr(dateObj) {
    let hours = dateObj.getHours();
    if(hours > 12) {
        return((hours - 12) + ":" + checkDigit(dateObj.getMinutes()) + ":" + checkDigit(dateObj.getSeconds()) + " PM");
    } else if(hours === 12) {
        return(hours + ":" + checkDigit(dateObj.getMinutes()) + ":" + checkDigit(dateObj.getSeconds()) + " PM");
    }
    return(dateObj.getHours() + ":" + checkDigit(dateObj.getMinutes()) + ":" + checkDigit(dateObj.getSeconds()) + " AM");
}

// Return the number of days from the passed in date to the current date (ROUNDED TO WHOLE DAYS) - TRC
export function getTimeSinceDays(dateObj) {
    let currentDate = new Date();
    let timeDiff = currentDate.getTime() - dateObj.getTime(); // In milliseconds
    
    let out = (Math.floor(timeDiff / (60*60*24*1000)) + " days");
    if(out < 0) {
        out = 0;
    }

    return(out);
}

// Return the number of years and days from the passed in date to the current date (ROUNDED TO WHOLE DAYS) - TRC
export function getTimeSinceYearsDays(dateObj) {
    let currentDate = new Date();
    let timeDiff = currentDate.getTime() - dateObj.getTime(); // In milliseconds
    
    let numDays = Math.floor(timeDiff / (60*60*24*1000)); // Convert to days
    if(numDays < 365) {
        return(numDays + " Days");
    } else {
        return((numDays / 365) + " Year(s) " + (numDays % 365) + " Days");
    }
}

// Return the number of years and days from an int # of days passed in - TRC
export function daysToYears(days) {
    let numDays = days;
    if(numDays < 365) {
        return(numDays + " Days");
    } else {
        return(Math.floor(numDays / 365) + " Year(s) " + (numDays % 365) + " Days");
    }
}