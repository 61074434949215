import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, TextField, Button
} from '@material-ui/core';
import { API_URL } from '../settings';
import axios from 'axios';
import authHeader from '../services/authHeader';

const useStyles = makeStyles((theme) => ({
  container: {
	display: 'flex',
	flexWrap: 'wrap',
  },
  textField: {
	marginLeft: theme.spacing(1),
	marginRight: theme.spacing(1),
	width: 200,
  },
}));

/**
 * Input Dialog for Timeframes
 *
 * @author Kaan Turkmen
 * @comments Thomas Campbell
 * @param {*} props All required prop information {listed below}
 *      visible: Boolean corresponding to displaying THIS dialog
 *      rankingDialog: Boolean corresponding to showing the Ranking Dialog
 *      onClose: OnClose Function Callback
 *      setRanking: SetRanking Function Callback
 *      setDates: SetDates Function Callback
 * @returns Exposure settings onscreen
 */
export default function InputDialog(props) {
  const classes = useStyles();
  const [startDate, setStartDate] = useState("2018-12-01");
  const [endDate, setEndDate] = useState("2020-12-01");

  // Events for setting start and end date - Comment: TRC, Author: KT
  const updateStartDate = (event) => setStartDate(event.target.value);
  const updateEndDate = (event) => setEndDate(event.target.value);

  // Get thet current timeframe - Comment: TRC, Author: KT
  async function getTimeframe() {
	axios.get(API_URL + `/filter/${startDate}/${endDate}`, { headers: authHeader() })
	.then(res => {
	  console.log(res.data);
	  props.setRanking(res.data)
	})
	.catch(err => console.log(err))
	.finally(() => {
	  props.setDates([startDate, endDate]);
	  props.rankingDialog(true);
	})
  }
  //   fetch(API_URL + `/filter/${startDate}/${endDate}`)
  //   .then(res => res.json())
  //   .then(json => props.setRanking(json))
  //   .catch(err => console.log(err))
  //   .finally(() => {
  //     props.setDates([startDate, endDate]);
  //     props.rankingDialog(true);
  //   })
  // }

  // Master Return Statement - Comment: TRC, Author: KT
  return (
	<div>
	  <Dialog open={props.visible} onClose={() => props.onClose(false)} aria-labelledby="form-dialog-title">
		<DialogTitle id="form-dialog-title">Filter</DialogTitle>
		<DialogContent className={classes.container}>
		  <DialogContentText>
			To filter for a specific timeframe, please enter the start and end dates of the timeframe below.
		  </DialogContentText>
		  <TextField
			autoFocus
			margin="dense"
			id="date"
			label="Start Date"
			type="date"
			defaultValue="2018-12-01"
			className={classes.textField}
			variant="outlined"
			onChange={updateStartDate}
		  />
		  <TextField
			autoFocus
			margin="dense"
			id="date"
			label="End Date"
			type="date"
			defaultValue="2020-12-01"
			className={classes.textField}
			variant="outlined"
			onChange={updateEndDate}
		  />
		</DialogContent>
		<DialogActions>
		  <Button onClick={() => props.onClose(false)} color="primary">
			Cancel
		  </Button>
		  <Button onClick={() => getTimeframe()} color="primary">
			Filter
		  </Button>
		</DialogActions>
	  </Dialog>
	</div>
  );
}
