import React, { useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { Box, Container, Typography, Grid, Paper, FormControlLabel, Switch, FormControl, InputAdornment, FormHelperText, Input, OutlinedInput, Button } from '@material-ui/core';
import Navbar from '../components/Navbar';
import 'katex/dist/katex.min.css';
import { InlineMath, BlockMath } from 'react-katex';
import { SettingsContext } from '../contexts/SettingsContext';
import { Redirect, useHistory } from 'react-router-dom';
import { customRankingsURL, liveRankingsURL } from '../urls';
import { checkBoundaries, checkNumerical } from '../services/helper';
import '../fonts/font.css';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    container: {
        width: "100%"
    },
    title: {
        fontWeight: "bold",
        marginTop: "2%",
        marginBottom: "2%",
        fontFamily: "Barlow",
    },
    gridTitle: {
        fontWeight: "bold",
        margin: "2%",
        marginBottom: "6%",
        fontFamily: "Barlow",
    },
    paper: {
        padding: theme.spacing(2),
        height: "100%",
        textAlign: "center"
    },
    descriptionPaper: {
        padding: theme.spacing(2),
        height: "100%",
    },
    control: {
        margin: "1%"
    },
    defaultSwitch: {
        margin: "2%"
    },
    submitBox: {
        margin: "3%",
        textAlign: "center"
    },
    alert: {
        textAlign: "center",
        alignItems: "center"
    },
    input: {
        width: 125
    }

}));

/**
 * Settings control page, nested under Live Rankings in navbar
 * Located at: https://mvsf.gmu.edu/settings
 * 
 * @author Kaan Turkmen
 * @editor Thomas Campbell
 * @comments Thomas Campbell
 * @returns Settings Page
 */
export default function Settings() {
    const classes = useStyles();
    const [likelihoodDefaults, toggleLikelihoodDefaults] = useState(true);
    const [exposureDefaults, toggleExposureDefaults] = useState(true);
    const [alpha, setAlpha] = useState(0.75);
    const [beta, setBeta] = useState(0.25);
    const [gamma, setGamma] = useState(0);
    const [c, setC] = useState(0.5);
    const [delta, setDelta] = useState(0);
    const [warningText, setWarningText] = useState("");
    const [successText, setSuccessText] = useState("");
    const [redirectButton, showRedirectButton] = useState(false);
    const settings = useContext(SettingsContext);
    const history = useHistory();

    // Check paramaters to provide the correct warning text - TRC
    const evaluateWarnings = () => {
        console.log(`Gamma: ${gamma}`);
        let infinityWarn = checkBoundaries(parseFloat(alpha), 0, Infinity) && checkBoundaries(parseFloat(beta), 0, Infinity) && checkBoundaries(parseFloat(c), 0, Infinity);
        let rangeWarn = checkBoundaries(parseFloat(gamma), 0, 1) && checkBoundaries(parseFloat(delta), 0, 1);
        let numericalWarn = checkNumerical(alpha) && checkNumerical(beta) && checkNumerical(gamma) && checkNumerical(delta) && checkNumerical(c);

        if (!infinityWarn && !rangeWarn) {
            setSuccessText("");
            setWarningText("Parameter values are invalid")
        } else if (!numericalWarn) {
            setSuccessText("");
            setWarningText("Please only use numerical values");
        } else if (!infinityWarn) {
            setSuccessText("");
            setWarningText("Parameters cannot have values smaller than 0");
        } else if (!rangeWarn) {
            setSuccessText("");
            setWarningText("Gamma and Delta variables must be in the range of 0 to 1");
        } else {
            setWarningText("");
            setSuccessText("");
            return false;
        }

        return true;
    }

    // Event handlers for updating of parameter fields - TRC
    const onAlphaUpdate = (e) => setAlpha(e.target.value);
    const onBetaUpdate = (e) => setBeta(e.target.value);
    const onCUpdate = (e) => setC(e.target.value);
    const onGammaUpdate = (e) => setGamma(e.target.value);
    const onDeltaUpdate = (e) => setDelta(e.target.value);

    // UseEffect on paramter change - TRC
    useEffect(() => evaluateWarnings(), [alpha, beta, gamma, delta, c]);

    // UseEffect on likelihood default controller - TRC
    useEffect(() => {
        console.log("Likelihood defaults changed")
        if (likelihoodDefaults) {
            setAlpha(0.75);
            setBeta(0.25);
            setGamma(0);
        } else {
            setAlpha(localStorage.getItem("alpha") ?? 0.75);
            setBeta(localStorage.getItem("beta") ?? 0.25);
            setGamma(localStorage.getItem("gamma") ?? 0);
        }
    }, [likelihoodDefaults]);

    // UseEffect on exposure default controller - TRC
    useEffect(() => {
        console.log("Exposure defaults changed")
        if (exposureDefaults) {
            setDelta(0);
            setC(0.5);
        } else {
            setDelta(localStorage.getItem("delta") ?? 0);
            setC(localStorage.getItem("c") ?? 0.5);
        }
    }, [exposureDefaults]);

    // Onload UseEffect - TRC
    useEffect(() => {
        let storedLikelihodDefaults = localStorage.getItem("likelihood-defaults");
        let storedExposureDefaults = localStorage.getItem("exposure-defaults");

        toggleLikelihoodDefaults(storedLikelihodDefaults === "true");
        toggleExposureDefaults(storedExposureDefaults === "true");

        console.log(`Likelihood Defaults: ${storedLikelihodDefaults}`);
        console.log(`Exposure Defaults: ${storedExposureDefaults}`);

        // FIXME: Check if the user already has datas.
        // =========================

        // toggleLikelihoodDefaults(localStorage.getItem("likelihood-defaults") === "true");
        // toggleExposureDefaults(localStorage.getItem("exposure-defaults") === "true");

        // if (localStorage.getItem("likelihood-defaults") === "true") {
        //     toggleLikelihoodDefaults(true);
        // }

        // if (localStorage.getItem("exposure-defaults") === "true") {
        //     toggleExposureDefaults(true);
        // }

        // let alpha = parseFloat(localStorage.getItem("alpha"));
        // let beta = parseFloat(localStorage.getItem("beta"));
        // let gamma = parseFloat(localStorage.getItem("gamma"));
        // let c = parseFloat(localStorage.getItem("c"));
        // let delta = parseFloat(localStorage.getItem("delta"));

        // console.log(typeof alpha)
        // console.log(alpha)

        // console.log(typeof beta)
        // console.log(beta)

        // if (alpha !== 0.75 || beta !== 0.25 || gamma !== 0) {
        //     toggleLikelihoodDefaults(false);
        // }

        // if (delta !== 0 || c !== 0.5) {
        //     toggleExposureDefaults(false);
        // }
    }, [])

    // Move user to the live rankings page - TRC
    const redirectToHome = () => {
        history.push(liveRankingsURL);
    }

    // Save items in local storage - TRC
    const onSave = () => {
        if (!evaluateWarnings()) {
            settings.alpha = parseFloat(alpha);
            settings.beta = parseFloat(beta);
            settings.c = parseFloat(c);
            settings.delta = parseFloat(delta);
            settings.gamma = parseFloat(gamma);

            if (!likelihoodDefaults) {
                localStorage.setItem("alpha", settings.alpha);
                localStorage.setItem("beta", settings.beta);
                localStorage.setItem("gamma", settings.gamma);
            }

            if (!exposureDefaults) {
                localStorage.setItem("delta", settings.delta);
                localStorage.setItem("c", settings.c);
            }

            localStorage.setItem("likelihood-defaults", likelihoodDefaults);
            localStorage.setItem("exposure-defaults", exposureDefaults);

            setWarningText("");
            setSuccessText("Parameter changes saved");
            showRedirectButton(true);
        }
    }

    // MASTER Return Statement - TRC
    return (
        <Box>
            <Navbar />
            <Container className={classes.container} disableGutters>
                <Typography variant="h5" align="center" className={classes.title} paragraph>Settings</Typography>
                {warningText.length > 0 ? (
                    <Grid container spacing={3}>
                        <Grid item xs={3} />
                        <Grid item xs={6}>
                            <Alert severity="error" className={classes.alert}>{warningText}</Alert>
                        </Grid>
                    </Grid>
                ) : null}
                {
                    successText.length > 0 ? (
                        <Grid container spacing={3}>
                            <Grid item xs={3} />
                            <Grid item xs={6}>
                                <Alert severity="success" className={classes.alert}>{successText}</Alert>
                            </Grid>
                        </Grid>
                    ) : null
                }
                <Grid container spacing={3}>
                    <Grid item xs>
                        <Paper className={classes.paper}>
                            <Box>
                                <Typography variant="h6" align="center" className={classes.gridTitle}>Exploitation Likelihood</Typography>
                            </Box>
                            <Box>
                                <BlockMath math="\!\rho(v) \! = \! \frac{\left(1 \! -e^{-\alpha \cdot \sqrt{t(v)}}\right) \! \cdot \! \left(1 \! -e^{-\beta \cdot Exploitability(v)}\right)}{e^{\gamma \cdot |IDS_k(v)|}}\!\!\!" />
                            </Box>
                            <Box className={classes.defaultSwitch}>
                                <FormControlLabel
                                    control={<Switch color="primary" checked={likelihoodDefaults} onChange={() => toggleLikelihoodDefaults(!likelihoodDefaults)} name="likelihoodSwitch" />}
                                    label="Use default values"
                                />
                            </Box>
                            <Box>
                                <FormControl className={classes.control}>
                                    <OutlinedInput
                                        id="standard-adornment-weight"
                                        onChange={onAlphaUpdate}
                                        startAdornment={<InputAdornment position="start"><InlineMath>\alpha</InlineMath></InputAdornment>}
                                        aria-describedby="standard-weight-helper-text"
                                        inputProps={{
                                            'aria-label': 'weight',
                                        }}
                                        disabled={likelihoodDefaults}
                                        value={alpha}
                                        margin="dense"
                                        className={classes.input}
                                    />
                                    <FormHelperText id="standard-weight-helper-text">{"[0, +∞)"}</FormHelperText>
                                </FormControl>
                                <FormControl className={classes.control}>
                                    <OutlinedInput
                                        id="standard-adornment-weight"
                                        onChange={onBetaUpdate}
                                        startAdornment={<InputAdornment position="start"><InlineMath>\beta</InlineMath></InputAdornment>}
                                        aria-describedby="standard-weight-helper-text"
                                        inputProps={{
                                            'aria-label': 'weight',
                                        }}
                                        disabled={likelihoodDefaults}
                                        value={beta}
                                        margin="dense"
                                        className={classes.input}
                                    />
                                    <FormHelperText id="standard-weight-helper-text">{"[0, +∞)"}</FormHelperText>
                                </FormControl>
                                <FormControl className={classes.control}>
                                    <OutlinedInput
                                        id="standard-adornment-weight"
                                        onChange={onGammaUpdate}
                                        startAdornment={<InputAdornment position="start"><InlineMath>\gamma</InlineMath></InputAdornment>}
                                        aria-describedby="standard-weight-helper-text"
                                        inputProps={{
                                            'aria-label': 'weight',
                                        }}
                                        disabled={likelihoodDefaults}
                                        value={gamma}
                                        margin="dense"
                                        className={classes.input}
                                    />
                                    <FormHelperText id="standard-weight-helper-text">{"[0, 1]"}</FormHelperText>
                                </FormControl>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs>
                        <Paper className={classes.paper}>
                            <Box>
                                <Typography variant="h6" align="center" className={classes.gridTitle}>Exposure Factor</Typography>
                            </Box>
                            <Box>
                                <BlockMath math="ef(v,h) = \frac{1-e^{-c\cdot impact(v)}}{e^{\delta \cdot |IDS_d(v)|}}" />
                            </Box>
                            <Box className={classes.defaultSwitch}>
                                <FormControlLabel
                                    control={<Switch color="primary" checked={exposureDefaults} onChange={() => toggleExposureDefaults(!exposureDefaults)} name="likelihoodSwitch" />}
                                    label="Use default values"
                                />
                            </Box>
                            <Box>
                                <FormControl className={classes.control}>
                                    <OutlinedInput
                                        id="standard-adornment-weight"
                                        onChange={onCUpdate}
                                        startAdornment={<InputAdornment position="start"><InlineMath>c</InlineMath></InputAdornment>}
                                        aria-describedby="standard-weight-helper-text"
                                        inputProps={{
                                            'aria-label': 'weight',
                                        }}
                                        disabled={exposureDefaults}
                                        value={c}
                                        margin="dense"
                                        className={classes.input}
                                    />
                                    <FormHelperText id="standard-weight-helper-text">{"[0, +∞)"}</FormHelperText>
                                </FormControl>
                                <FormControl className={classes.control}>
                                    <OutlinedInput
                                        id="standard-adornment-weight"
                                        onChange={onDeltaUpdate}
                                        startAdornment={<InputAdornment position="start"><InlineMath>\delta</InlineMath></InputAdornment>}
                                        aria-describedby="standard-weight-helper-text"
                                        inputProps={{
                                            'aria-label': 'weight',
                                        }}
                                        disabled={exposureDefaults}
                                        value={delta}
                                        margin="dense"
                                        className={classes.input}
                                    />
                                    <FormHelperText id="standard-weight-helper-text">{"[0, 1]"}</FormHelperText>
                                </FormControl>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs className={classes.submitBox}>
                        <Button variant="contained" color="primary" size="large" onClick={() => onSave()}>Save Changes</Button>
                        {redirectButton ? (
                            <Button variant="outlined" color="secondary" size="large" style={{ margin: 10 }} onClick={() => redirectToHome()}>View New Ranking</Button>
                        ) : null}
                    </Grid>
                </Grid>
                {/* 
                <Grid container spacing={3}>
                    <Grid item xs>
                        <Paper className={classes.descriptionPaper}>
                            <Box>
                                <Typography variant="h6" align="center">Exploitation Likelihood</Typography>
                            </Box>
                            <Box>
                                <p>We define the <i>exploitation likelihood</i> (or simply <i>likelihood</i>) of a vulnerability as the probability that an attacker will attempt to exploit that vulnerability, if given the opportunity.
                                    An attacker has the <i>opportunity</i> to exploit a vulnerability if certain preconditions are met,
                                    most notably if they have access to the vulnerable host. Specific preconditions may vary
                                    depending on the specific characteristics of each vulnerability, as certain configuration
                                    settings may prevent access to vulnerable portions of the target software. We argue that
                                    the following variables are the main factors influencing an attacker’s decision to exploit a
                                    given vulnerability.
                                    <br /><br />• The vulnerability’s exploitability score, as captured by CVSS.
                                    <br />• The amount of time since information about the vulnerability became public.
                                    <br />• The number of known Intrusion Detection System (IDS) rules associated with the
                                    vulnerability.
                                </p>
                                <p>
                                    The CVSS Exploitability score captures how easy it is to exploit a vulnerability, based
                                    on different features captured by various metrics, most notably Access Vector (AV) and
                                    Access Complexity (AC). The Access Vector metric reflects the context in which a vulnerability can be exploited. Its value is higher for vulnerabilities that can be exploited
                                    remotely, and are therefore more likely to be exploited as the number of potential attackers is larger than the number of potential attackers that could exploit a vulnerability
                                    requiring physical access to the vulnerable host. The Attack Complexity metric reflects
                                    the amount of effort and resources required for a successful attack. Its value is higher for
                                    exploits that require little or no effort, and are therefore more likely to be exploited
                                </p>
                                <p>
                                    The time passed since details about the vulnerability were made public also plays
                                    a role in determining the likelihood of exploitation. In fact, the longer a vulnerability
                                    has been known, the more exploits may have been developed by the hacker community. While it is true that the likelihood that patches have been developed also increases
                                    with time, it is well-known that patches are not applied promptly and consistently across
                                    systems, thus giving attackers a window of opportunity to target known but unpatched
                                    vulnerabilities.
                                </p>
                                <p>Finally, the number of known IDS rules may influence the attacker’s choice of vulnerabilities to exploit. With systems typically exposing multiple vulnerabilities, attackers
                                    may choose to avoid exploits that are more easily detectable.
                                </p>
                                <p>
                                    Let <InlineMath>G_v = (V,E)</InlineMath> denote a vulnerability graph (e.g., the vulnerability subgraph of the
                                    SCIBORG model). Based on the considerations above, we define the exploitation likelihood as a function <InlineMath>\rho: V \rightarrow [0,1]</InlineMath> defined as
                                </p>
                                <BlockMath math="\!\rho(v) \! = \! \frac{\left(1 \! -e^{-\alpha \cdot \sqrt{t(v)}}\right) \! \cdot \! \left(1 \! -e^{-\beta \cdot Exploitability(v)}\right)}{e^{\gamma \cdot |IDS_k(v)|}}\!\!\!" />
                                <p>
                                    where <InlineMath>t(v)</InlineMath> is the time since vulnerability <InlineMath>v</InlineMath> was discovered, <InlineMath>Exploitability(v)</InlineMath> is the the
                                    CVSS <i>Exploitability</i> score of <InlineMath>v</InlineMath>, and <InlineMath>IDS_k(v)</InlineMath> is the set of known IDS rules associated with
                                    <InlineMath>~v</InlineMath>.
                                </p>
                                <p>
                                    Each variable contributes to the overall likelihood as a multiplicative factor between
                                    0 and 1 that is formulated to account for diminishing returns. Factors corresponding to
                                    variables that contribute to increasing the likelihood are of the form <InlineMath math="1-e^{-c \cdot f(x)}" />,
                                    where <InlineMath>x</InlineMath> is the variable, <InlineMath>f()</InlineMath> is a function such that
                                    <InlineMath math="x_1 < x_2 \rightarrow f(x_1) < f(x_2)" />, and <InlineMath>c</InlineMath> is a constant.
                                    Similarly, factors corresponding to variables that contribute to decreasing the likelihood are of the form
                                    <InlineMath math="~e^{-c \cdot f(x)} = \frac{1}{e^{c \cdot f(x)}}" />.
                                </p>
                                <p>
                                    This formulation provides several practical advantages: (i) the resulting likelihood is
                                    normalized between 0 and 1; (ii) accounting for the effect of additional independent variables would be straightforward; and (iii) ignoring the effect of a variable would simply
                                    entail setting the constant c such that the corresponding factor evaluates to <InlineMath>1~</InlineMath>
                                    (i.e., <InlineMath math="c=+\infty" /> for factors increasing the likelihood and <InlineMath math="c=0" /> for factors decreasing the likelihood).
                                </p>
                                <p>
                                    As for the function <InlineMath math="f()" />, in most cases we define it as the linear function <InlineMath math="f(x)=x" />, but in the case of the time
                                    <InlineMath>t</InlineMath> since the vulnerability was disclosed, we use <InlineMath math="f(t)=\sqrt{t}" /> to model a less-than-linear relationship.
                                </p>
                            </Box>
                            <Box>
                                <Typography variant="h6" align="center">Exposure Factor</Typography>
                            </Box>
                            <Box>
                                <p>
                                    The <i>exposure factor</i> (EF) - as typically defined in risk analysis terminology - represents the relative damage that an undesirable event -- the exploitation of a vulnerability in our case -- would cause to the affected asset. The single loss expectancy (SLE) of such an incident is then computed as the product between its exposure factor and the asset value (AV), that is $SLE = EF \times AV$.
                                </p>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            */}
            </Container>
        </Box>
    )
}