import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Box, Container, Typography, Button, TableContainer, Table, Paper, TableHead, TableRow, TableCell, Grid, TableBody, IconButton, Icon } from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import axios from 'axios';
import { API_URL } from '../settings';
import authHeader from '../services/authHeader';
import { ArrowUpward } from '@material-ui/icons';
import mvsfLogoWhite from '../images/mvsf-logo-white.png';
import { green, red, yellow } from '@material-ui/core/colors';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import * as urls from '../urls';
import '../fonts/font.css';


const useStyles = makeStyles((theme) => ({
    container: {
        width: "100%",
    },
    title: {
        fontWeight: "bold",
        marginTop: "2%",
        marginBottom: "2%",
        fontFamily: "Barlow",
    },
    intro: {
        fontSize: 16,
        marginLeft: "3%",
        marginRight: "7%",
    },
    gridContainer: {
        justifyContent: "center",
        marginTop: "2%",
        // marginLeft: "5%",
        // marginRight: "5%"
        // backgroundColor: "blue"
    },
    tableContainer: {
        marginBottom: "2%",
        maxWidth: 1800,
        minWidth: 1000,
        // backgroundColor: "red"
        backgroundColor: "#eeeeee"
    },
    table: {
        // minWidth: 450,
        // maxWidth: 3200,
    },
    tableTitle: {
        textAlign: "center",
        marginBottom: "3%",
    },
    loadMoreButton: {
        left: "45%",
        marginBottom: "2%"
    },
    header: {
        fontWeight: "bold",
        whiteSpace: 'nowrap',
        textAlign: "center"
    },
    cell: {
        fontSize: 14,
        padding: 3,
        whiteSpace: 'nowrap',
        paddingLeft: 40
    },
    centeredCell: {
        fontSize: 14,
        padding: 3,
        whiteSpace: 'nowrap',
        textAlign: 'center',
        justifyContent: "center"
    },
    rankCell: {
        fontWeight: "bold"
    },
    changeCell: {
        height: "30%",
        width: "20%",
        backgroundColor: "red",
    },
    leftScrollButton: {
        marginBottom: "2%"
    },
    rightScrollButton: {
        // display: "flex",
        marginBottom: "2%"
    },
    upIcon: {
        fontSize: "medium",
        color: green[500],
        justifyContent: "center",
        paddingLeft: 5
    },
    downIcon: {
        fontSize: "medium",
        color: red[500],
        justifyContent: "center",
        paddingLeft: 5
    },
    linkIcon: {
        marginLeft: "7%"
    },
    logoContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    logoText: {
        fontFamily: "Barlow",
        fontWeight: "bold",
    },
    toggleContainer: {
        paddingTop: "3%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    toggleText: {
        fontFamily: "Barlow",
        fontWeight: "bold",
    }
}));

/**
 * Landing page (homeapge) for the MVSF website, shows the rankings for the previous month.
 * Located at: https://mvsf.gmu.edu/
 * 
 * @author Kaan Turkmen
 * @editor Thomas Campbell
 * @comments Thomas Campbell
 * @param {*} props 
 *      history: Browser History Object
 * @returns Landing Page
 */
export default function Homepage(props) {
    const classes = useStyles();
    const [ranking, setRanking] = useState([]);
    const [loadSize, setLoadSize] = useState(25);
    const [maxRowSize, setMaxRowSize] = useState(150);
    // Variable to control the displayed version of rankings - TRC [CURRENT DEFAULT IS VERSION 1]
    const [rankVersion, setRankVersion] = useState(1);

    let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    let month, year;

    if (new Date().getMonth() != 0) {
        month = months[new Date().getMonth() - 1];
        year = new Date().getFullYear();
    } else {
        month = "December";
        year = new Date().getFullYear() - 1;
    }

    // On load UseEffect to organize and prepare data - TRC
    useEffect(() => {
        // Since date().getMonth() returns months with a 0 index,
        // it is not required to subtract one to get the current date.
        axios.get(API_URL + '/get/historical', { headers: authHeader() })
            .then((res) => {
                let prevRanking = res.data[0]["ranking"];
                console.log(res.data);
                let lastMonth = {};
                let currentMonth = {};
                let tableRanking = [];

                prevRanking.map((rank) => {
                    let key = rank["CWE ID"];
                    let value = rank["Our Ranking"];
                    lastMonth[key] = value;
                })
                
                // Set change by comparing data from two months ago - TRC
                res.data[1]["ranking"].map((rank) => {
                    if (!(rank["CWE ID"] in lastMonth)) {
                        currentMonth = {"Change": "N/A", ...rank}
                    } else {
                        currentMonth = {"Change": lastMonth[rank["CWE ID"]] - rank["Our Ranking"], ...rank}
                    }
                    tableRanking.push(currentMonth);
                })

                console.log(tableRanking);

                setRanking(tableRanking);
            })
            .catch((err) => console.log(err))
    }, [])

    // Handle the change of the ranking version toggle - TRC
    function handleAlignment(event, newRanking) {
        // Only run if the value is not null
        if(newRanking !== null) {
            console.log("Displaying new ranking verison: " + newRanking);
            setRankVersion(newRanking);
        }
    };

    // MASTER Return Statement - TRC
    return (
        <Box>
            <Navbar>
                <Button color="inherit" onClick={() => props.history.push(urls.historicalRankingsURL)}>Historical Rankings</Button>
            </Navbar>
            <Container className={classes.container} disableGutters>
                <Typography variant="h5" align="center" className={classes.title} paragraph>Introduction</Typography>
                {/* <hr /> */}
                <Grid container style={{ padding: "2%", border: "1px solid #0005", alignItems: "center" }}>
                    <Grid item xs={3} className={classes.logoContainer}>
                        <Typography variant="h4" className={classes.logoText}>MVSF</Typography>
                        <Typography variant="subtitle1" className={classes.logoText}>Mason Vulnerability Scoring Framework</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography className={classes.intro}>
                            The Mason Vulnerability Scoring Framework is a web application that publishes monthly rankings of all  
                            <a href="https://cwe.mitre.org" target="_blank"> Common Weaknesses Enumeration</a> (CWE) categories and 
                            allows users to generate custom rankings by tuning several parameters used in the calculation of CWE scores. 
                            Mason CWE Scores rely on vulnerability metrics <a href="https://doi.org/10.5220/0010559402590270" target="_blank">[1]</a> developed
                            in collaboration with <a href="https://www.parc.com" target="_blank">Palo Alto Research Center</a> (PARC), 
                            as part of the SCIBORG project <a href="https://doi.org/10.1109/CNS48642.2020.9162256" target="_blank">[2]</a>, within 
                            the context of the <a href="https://www.darpa.mil/program/configuration-security" target="_blank">DARPA Configuration Security</a> 
                            (ConSec) program. To learn more about MVSF, visit the <a href={urls.aboutURL}>About</a> section of this website. For more
                            information on how to use the different capabilities offered by the framework, check the <a href={urls.instructionsURL}>Instructions</a> section.
                        </Typography>
                    </Grid>
                </Grid>
                {/* <hr /> */}
                <Container className={classes.toggleContainer}>
                    {/* Toggle button to select which ranking versions are displayed - TRC [VERSION 2 CURRENTLY DISABLED] */}
                    <ToggleButtonGroup
                        value={rankVersion}
                        exclusive
                        size="small"
                        onChange={handleAlignment}
                        aria-label="Displayed Ranking Version"
                    >
                        <ToggleButton value="1" aria-label="Version 1.0" selected={rankVersion == 1} color="primary">
                            <Typography variant="body" className={classes.toggleText}>Ranking V1.0</Typography>
                        </ToggleButton>
                        <ToggleButton value="2" aria-label="Version 2.0" selected={rankVersion == 2} color="primary" disabled>
                            <Typography variant="body" className={classes.toggleText}><strike>Ranking V2.0</strike></Typography>
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Container>
                <Grid container className={classes.gridContainer}>
                    <Typography variant="h5" className={classes.tableTitle}>Mason CWE Ranking <i>V{rankVersion}.0</i> for {month} {year}</Typography>
                    <Grid item xs>
                        <TableContainer component={Paper} className={classes.tableContainer}>
                            <Table className={classes.table} aria-label="historical-ranking">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.header} variant="head">Rank</TableCell>
                                        <TableCell className={classes.header} variant="head">CWE-ID</TableCell>
                                        <TableCell className={classes.header} variant="head">Name</TableCell>
                                        <TableCell className={classes.header} variant="head">Overall Score</TableCell>
                                        <TableCell className={classes.header} variant="head">Change in Rank</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {ranking ? ranking.slice(0, loadSize).map((rank) => (
                                        <TableRow>
                                            <TableCell className={[classes.centeredCell, classes.rankCell]}>{rank["Our Ranking"]}</TableCell>
                                            <TableCell className={classes.centeredCell}>
                                                <a href={`https://cwe.mitre.org/data/definitions/${rank["CWE ID"].split('-')[1]}.html`} target="_blank">
                                                    {rank["CWE ID"]}
                                                    <FontAwesomeIcon icon={faExternalLinkAlt} className={classes.linkIcon} size="xs" />
                                                </a>
                                            </TableCell>
                                            <TableCell className={classes.cell}>{rank["CWE Name"]}</TableCell>
                                            <TableCell className={classes.centeredCell}>{rank["Our Overall Score"]?.toFixed(0)}</TableCell>
                                            <TableCell className={classes.centeredCell}>
                                                {rank["Change"]}
                                                {rank["Change"] < 0 ? <ArrowDownwardIcon className={classes.downIcon} /> : (rank["Change"] > 0 ? <ArrowUpwardIcon className={classes.upIcon} /> : null)}
                                            </TableCell>
                                        </TableRow>
                                    )) : null}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Typography variant="subtitle2" paragraph>Note: N/A indicates that this CWE was not present in the previous month's ranking.</Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={2}>
                                <IconButton className={classes.leftScrollButton} onClick={() => window.scrollTo(0, 0)}>
                                    <ArrowUpward />
                                </IconButton>
                            </Grid>
                            <Grid item xs={8} style={{ justifyContent: "center" }}>
                                <Button variant="outlined" color="primary" className={classes.loadMoreButton} onClick={() => setLoadSize(loadSize + 25)} disabled={loadSize >= maxRowSize}>Load More</Button>
                            </Grid>
                            <Grid item xs={2} style={{ display: "flex", justifyContent: "flex-end" }}>
                                <IconButton className={classes.rightScrollButton} onClick={() => window.scrollTo(0, 0)}>
                                    <ArrowUpward />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            <Footer />
        </Box>
    )
}