export const loginURL = "/login";
export const requestAccountURL = "/request-account";
export const aboutURL = "/about";
export const contactURL = "/contact";
export const liveRankingsURL = "/live-rankings";
export const settingsURL = "/settings";
export const documentsURL = "/documents";
export const customRankingsURL = "/custom";
export const generatedRankingURL = "/generated-ranking";
export const historicalRankingsURL = "/historical-rankings";
export const archiveListURL = "/list/archive";
export const instructionsURL = "/instructions";
export const accountURL = "/account";
export const adminPanelURL = "/admin";
export const createPasswordURL = "/create-password"
export const cveSearchURL = "/CVE-search"