import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Box, Container, Typography, Button, TableContainer, Table, Paper, TableHead, TableRow, TableCell, Grid, TableBody, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import axios from 'axios';
import { API_URL } from '../settings';
import authHeader from '../services/authHeader';
import { ArrowUpward } from '@material-ui/icons';
import mvsfLogoWhite from '../images/mvsf-logo-white.png';
import { green, red, yellow } from '@material-ui/core/colors';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { useHistory } from 'react-router';
import '../fonts/font.css';


const useStyles = makeStyles((theme) => ({
    container: {
        width: "100%",
    },
    title: {
        fontWeight: "bold",
        marginTop: "2%",
        marginBottom: "2%",
        fontFamily: "Barlow",
    },
    intro: {
        fontSize: 16,
        marginLeft: "3%",
        marginRight: "7%",
    },
    gridContainer: {
        justifyContent: "center",
        marginTop: "4%",
        // marginLeft: "5%",
        // marginRight: "5%"
        // backgroundColor: "blue"
    },
    tableContainer: {
        marginBottom: "2%",
        maxWidth: 1800,
        minWidth: 1000,
        backgroundColor: "#eeeeee",
    },
    table: {
        // minWidth: 450,
        // maxWidth: 3200,
    },
    tableTitle: {
        textAlign: "center",
        marginBottom: "3%",
        textDecoration: "underline"
    },
    loadMoreButton: {
        left: "45%",
        marginBottom: "2%"
    },
    header: {
        fontWeight: "bold",
        whiteSpace: 'nowrap',
        textAlign: "center"
    },
    cell: {
        fontSize: 14,
        padding: 3,
        whiteSpace: 'nowrap'
    },
    centeredCell: {
        fontSize: 14,
        padding: 3,
        whiteSpace: 'nowrap',
        textAlign: 'center',
        justifyContent: "center"
    },
    rankCell: {
        fontWeight: "bold"
    },
    changeCell: {
        height: "30%",
        width: "20%",
        backgroundColor: "red",
    },
    leftScrollButton: {
        marginBottom: "2%"
    },
    rightScrollButton: {
        // display: "flex",
        marginBottom: "2%"
    },
    upIcon: {
        fontSize: "medium",
        color: green[500],
        justifyContent: "center",
        paddingLeft: 5
    },
    downIcon: {
        fontSize: "medium",
        color: red[500],
        justifyContent: "center",
        paddingLeft: 5
    },
}));

/**
 * DEPRECIATED (Not in use at time of writing comment 05/12/2022)
 * 
 * @author Kaan Turkmen
 * @comments Thomas Campbell
 * @param {*} props 
 *      Month: Month timeframe
 *      Year: Year timeframe
 * @returns Archive Historical
 */
export default function ArchivedHistorical(props) {
    const classes = useStyles();
    const [ranking, setRanking] = useState([]);
    const [loadSize, setLoadSize] = useState(25);
    const [maxRowSize, setMaxRowSize] = useState(150);
    let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    let month = months[props.month];
    let year = props.year;
    let history = useHistory();

    useEffect(() => {
        // Since date().getMonth() returns months with a 0 index,
        // it is not required to subtract one to get the current date.
        axios.get(API_URL + `/get/historical/${year}/${props.month + 1}`, { headers: authHeader() })
            .then((res) => {
                let prevRanking = res.data[1]["ranking"];
                let lastMonth = {};
                let currentMonth = {};
                let tableRanking = [];

                prevRanking.map((rank) => {
                    let key = rank["CWE ID"];
                    let value = rank["Our Ranking"];
                    lastMonth[key] = value;
                })
                
                res.data[0]["ranking"].map((rank) => {
                    if (!(rank["CWE ID"] in lastMonth)) {
                        currentMonth = {"Change": "N/A", ...rank}
                    } else {
                        currentMonth = {"Change": lastMonth[rank["CWE ID"]] - rank["Our Ranking"], ...rank}
                    }
                    tableRanking.push(currentMonth);
                })

                console.log(tableRanking);

                setRanking(tableRanking);
            })
            .catch((err) => console.log(err))

    }, [])

    // MASTER Return Statement - TRC
    return (
        <Box>
            <Navbar>
                <Button color="inherit" onClick={() => history.goBack()}>Go Back</Button>
            </Navbar>
            <Container className={classes.container} disableGutters>
                {/* <hr /> */}
                <Grid container className={classes.gridContainer}>
                    <Typography variant="h5" className={classes.tableTitle}>Mason CWE Ranking for {month} {year}</Typography>
                    <Grid item xs>
                        <TableContainer component={Paper} className={classes.tableContainer}>
                            <Table className={classes.table} aria-label="historical-ranking">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.header} variant="head">Rank</TableCell>
                                        <TableCell className={classes.header} variant="head">CWE-ID</TableCell>
                                        <TableCell className={classes.header} variant="head">Name</TableCell>
                                        <TableCell className={classes.header} variant="head">Overall Score</TableCell>
                                        <TableCell className={classes.header} variant="head">Change in Rank</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {ranking ? ranking.slice(0, loadSize).map((rank) => (
                                        <TableRow>
                                            <TableCell className={[classes.centeredCell, classes.rankCell]}>{rank["Our Ranking"]}</TableCell>
                                            <TableCell className={classes.centeredCell}>{rank["CWE ID"]}</TableCell>
                                            <TableCell className={classes.cell}>{rank["CWE Name"]}</TableCell>
                                            <TableCell className={classes.centeredCell}>{rank["Our Overall Score"]?.toFixed(0)}</TableCell>
                                            <TableCell className={classes.centeredCell}>
                                                {rank["Change"]}
                                                {rank["Change"] < 0 ? <ArrowDownwardIcon className={classes.downIcon} /> : (rank["Change"] > 0 ? <ArrowUpwardIcon className={classes.upIcon} /> : null)}
                                            </TableCell>
                                        </TableRow>
                                    )
                                    ) : null}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Typography variant="subtitle2" paragraph>Note: N/A indicates that this CWE was not present in the previous month's ranking.</Typography>
                        <Grid container spacing={3}>
                            <Grid item xs={2}>
                                <IconButton className={classes.leftScrollButton} onClick={() => window.scrollTo(0, 0)}>
                                    <ArrowUpward />
                                </IconButton>
                            </Grid>
                            <Grid item xs={8} style={{ justifyContent: "center" }}>
                                <Button variant="outlined" color="primary" className={classes.loadMoreButton} onClick={() => setLoadSize(loadSize + 25)} disabled={loadSize >= maxRowSize}>Load More</Button>
                            </Grid>
                            <Grid item xs={2} style={{ display: "flex", justifyContent: "flex-end" }}>
                                <IconButton className={classes.rightScrollButton} onClick={() => window.scrollTo(0, 0)}>
                                    <ArrowUpward />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            <Footer />
        </Box>
    )
}