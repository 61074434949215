import React, { useEffect, useState } from 'react';
import { Avatar, Button, CssBaseline, TextField, Link, Box, Typography, Grid } from '@material-ui/core';
import axios from "axios";
import Alert from '@material-ui/lab/Alert';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Redirect } from 'react-router-dom';
import { login } from '../services/authService';
import mvsfLogoWhite from '../images/mvsf-logo-white.png';
import {
  useLocation
} from "react-router-dom";
import { requestAccountURL } from '../urls';
import '../fonts/font.css';

export const auth = {
  isAuthenticated: false,
}

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: "15%",
    paddingRight: "15%"
  },
  barlowFont: {
    fontFamily: "Barlow",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  titleGrid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: "10%"
  },
}));

/**
 * Login Page - Original Landing for Users first reaching the site 
 * Located at: https://mvsf.gmu.edu/login
 * 
 * @author Kaan Turkemn
 * @editor Thomas Campbell
 * @comments Thomas Campbell
 * @param {*} props - UNUSED
 * @returns SignIn Page
 */
export default function SignIn(props) {
  const classes = useStyles();
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [warning, showWarning] = useState(false);
  const location = useLocation();
  const { from } = location.state || { from: { pathname: "/" } };

  // Handle login attempt submission - TRC
  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Submitting")

    login(username, password)
    .then((res) => {
      if (res?.response && res.response.status === 401) {
        showWarning(true);
        setRedirectToReferrer(false);
      } else {
        showWarning(false);
        setRedirectToReferrer(true)
      }
    })

  }

  if (redirectToReferrer) {
    return <Redirect to='/' />
  }

  // MASTER Return Statement - TRC
  return (
    <Container component="main">
      <CssBaseline />
      <Grid container>
        <Grid item xs={6} className={classes.titleGrid}>
          <Typography variant="h1" className={classes.barlowFont}>MVSF</Typography>
          <Typography variant="h5" className={classes.barlowFont}>Mason Vulnerability Scoring Framework</Typography>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <form className={classes.form} noValidate>
              {warning &&
                <Alert variant="filled" severity="warning">
                  Invalid Credentials!
                </Alert>
              }
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                onChange={(e) => setUsername(e.target.value)}
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(e) => setPassword(e.target.value)}
              />
              {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={(e) => handleSubmit(e)}
              >
                Sign In
              </Button>
              <Grid container>

                <Grid item xs>
                  <Link href={requestAccountURL} variant="body2">
                    {"Don't have an account? Request a demo account."}
                  </Link>
                </Grid>
              </Grid>
            </form>
          </div>
        </Grid>
      </Grid>
      <Box mt={8}>
        {/* <Copyright /> */}
      </Box>
    </Container>
  );
}
