import react, { useEffect, useState } from 'react';
import { Container, Typography, Box, Button, Grid, TextField, Link, CssBaseline } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import mvsfLogoWhite from '../images/mvsf-logo-white.png';
import ReCAPTCHA from 'react-google-recaptcha';
import { API_URL, CAPTCHA_SITE_KEY, TOTAL_HF_HEIGHT } from '../settings';
import axios from 'axios';
import { loginURL } from '../urls';
import '../fonts/font.css';

const useStyles = makeStyles({
    root: {
        width: "100%",
    },
    container: {
        width: "100%",
        // Statement below makes footer anchor to bottom 
        // (Only needed on pages where the information would result in an incorrect footer )- TRC
        minHeight: "calc(100vh - " + TOTAL_HF_HEIGHT + ")",
    },
    content: {
        width: "84%",
        // backgroundColor: "yellow"
    },
    title: {
        fontWeight: "bold",
        marginTop: "3%",
        marginBottom: "3%",
        fontSize: 26,
        fontFamily: "Barlow",
    },
    barlowFont: {
        fontFamily: "Barlow",
    },
    titleContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "4%",
        marginTop: '4%',
    },
    requestButton: {
        marginTop: '5%'
    }
})

/**
 * Request Account Page 
 * Located at: https://mvsf.gmu.edu/request-account
 * 
 * @author Kaan Turkmen
 * @editor Thomas Campbell
 * @comments Thomas Campbell
 * @returns Request Account Form Page
 */
export default function RequestAccount() {
    const classes = useStyles();
    const [captchaStatus, toggleCaptchaStatus] = useState(false);
    const [warningText, setWarningText] = useState(null);
    const [successText, setSuccessText] = useState(null);
    // Email error status
    const [emailError, setEmailError] = useState(false);

    // Handle submission of request for account - TRC
    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        if (captchaStatus) {

            axios.post(API_URL + '/api/request/account', {
                username: data.get('username'),
                name: data.get('firstName') + ' ' + data.get('lastName'),
                email: data.get('email'),
                affiliation: data.get('affiliation'),
                role: data.get('role')
            })
                .then((res) => {
                    setSuccessText('Request sent');
                    setWarningText(null);
                })
                .catch((err) => {
                    if (err.response?.status === 400) {
                        setWarningText('An account already exists or was requested with this email');
                    } else {
                        setWarningText('Could not send the request, please try again later');
                    }
                    setSuccessText(null);
                });

        } else {
            setWarningText('Invalid captcha');
            setSuccessText(null);
        }
    }

    // UseEffect for capatcha - TRC
    useEffect(() => {
        console.log('Captcha Status: ' + captchaStatus);
    }, [captchaStatus])

    // Check if passed in string contains unicode chars - TRC
    function hasUnicode(str) {
        for (var i = 0; i < str.length; i++) {
            if (str.charCodeAt(i) > 127) return true;
        }
        return false;
    }

    // Validate Email Address input - TRC
    function checkEmailAddress(e) {
        if (hasUnicode(e.target.value)) {
            setEmailError(true);
        } else {
            setEmailError(false);
        }
    }

    // MASTER Return Statement - TRC
    return (
        <Box className={classes.root}>
            <Navbar />
            <Container className={classes.container} disableGutters>
                <Typography variant="h5" align="center" className={classes.title} paragraph>Request Account</Typography>
                <hr />
                <Container className={classes.content}>
                    <Container className={classes.titleContainer}>
                        <Typography variant="h1" className={classes.barlowFont}>MVSF</Typography>
                        <Typography variant="h5" className={classes.barlowFont}>Mason Vulnerability Scoring Framework</Typography>
                    </Container>
                    <Container component="main" maxWidth="sm">
                        <CssBaseline />
                        {warningText ? <Alert severity="error">{warningText}</Alert> : null}
                        {successText ? <Alert severity="success">{successText}</Alert> : null}
                        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="username"
                                        label="Username"
                                        name="username"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email Address"
                                        name="email"
                                        error={emailError}
                                        onChange={checkEmailAddress}
                                        autoComplete="email"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        autoComplete="given-name"
                                        name="firstName"
                                        required
                                        fullWidth
                                        id="firstName"
                                        label="First Name"
                                        variant="outlined"
                                        autoFocus
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        id="lastName"
                                        label="Last Name"
                                        name="lastName"
                                        autoComplete="family-name"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        name="affiliation"
                                        label="Affiliation"
                                        id="affiliation"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        required
                                        fullWidth
                                        name="role"
                                        label="Role"
                                        id="role"
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                            <br />
                            <ReCAPTCHA
                                sitekey={CAPTCHA_SITE_KEY}
                                onChange={() => toggleCaptchaStatus(!captchaStatus)}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                disabled={emailError}
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                className={classes.requestButton}
                                color="primary"
                            >
                                Request Account
                            </Button>
                            <Grid container justifyContent="flex-end" style={{ marginTop: '5%' }}>
                                <Grid item>
                                    <Link href={loginURL} variant="body2">
                                        Already have an account? Sign in
                                    </Link>
                                </Grid>
                            </Grid>
                        </Box>
                    </Container>
                </Container>
            </Container>
            <Footer />
        </Box>
    )
}