import React, { useEffect, useState } from 'react';
import { API_URL, TOTAL_HF_HEIGHT } from '../settings';
import authHeader from '../services/authHeader';
import { Divider, Button, Box, Container, makeStyles, 
        Typography, Checkbox, Dialog, Slide,
        DialogTitle, DialogContent, DialogContentText,
        DialogActions, TextField, Paper } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import axios from 'axios';
import * as urls from '../urls';
import '../fonts/font.css';

const useStyles = makeStyles((theme) => ({
    container: {
        width: "100%",
        // Statement below makes footer anchor to bottom 
        // (Only needed on pages where the information would result in an incorrect footer )- TRC
        minHeight: "calc(100vh - " + TOTAL_HF_HEIGHT + ")",
    },
    title: {
        fontWeight: "bold",
        marginTop: "2%",
        marginBottom: "2%",
        fontFamily: "Barlow",
    },
    infoBox: {
        align: "center",
        background: "#eeeeee",
        width: "75%",
        borderRadius: "10px",
    },
    accountField: {
        minHeight: "7vh",
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        margin: "10px",
    },
    subAccountField: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    accountFieldSingle: {
        minHeight: "4vh",
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        margin: "10px",
    },
    changeRequest: {
        textAlign: "right",
        textDecoration: "underline",
        fontSize: "1em",
        width: "75%",
        margin: "auto"
    },
    infoChangeField: {
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        margin: "5px",
    },
    infoChangeFieldSub: {
        width: "50%",
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        margin: "5px",
    },
}))

// Simple slide up transition - TRC
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
/**
 * A user's account page for account related information (Also allows admin access to the admin panel)
 * Located at: https://mvsf.gmu.edu/account
 * 
 * @author Thomas Campbell
 * @comments Thomas Campbell
 * @returns A users account page.
 */
export default function Account() {
    const classes = useStyles();
    // Main user information shown in the page - KT
    const [username, setUsername] = useState('');
    const [fullname, setFullname] = useState('');
    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');
    const [affiliation, setAffiliation] = useState('');
    // Keeps the state information to determine if the user is an admin - KT
    const [admin, setAdmin] = useState(false);
    // Keep Track of Join Date
    const [joinDate, setJoinDate] = useState('');
    /* === Below Variables for Password Change Dialog and Functionality === */
    // Password dialog box display controllers - TRC
    const [pOpen, setPOpen] = useState(false);
    const [displayNew, setDisplayNew] = useState(false);
    // Warning messages [FOR CHANGE PASSWORD] - TRC
    const [oldPWarning, setOldPWarning] = useState(false);
    const [newPWarning, setNewPWarning] = useState(false);
    // Edited textfield trackers [FOR CHANGE PASSWORD] - TRC
    const [afterOldInput, setAfterOldInput] = useState(false);
    const [afterNewInput, setAfterNewInput] = useState(false);
    // User password input [FOR CHANGE PASSWORD] - TRC
    const [oldPassword, setOldPassword] = useState("");
    const [newPass, setNewPass] = useState("");
    const [newPassCon, setNewPassCon] = useState("");
    /* === Below Variables for Information Change Dialog and Functionality === */
    // Information Change dialog box display controller - TRC
    const [infoChangeOpen, setInfoChangeOpen] = useState(false);
    // Infromation Change variables - TRC
    const [nUsername, setNUsername] = useState("");
    const [nFullname, setNFullname] = useState("");
    const [nEmail, setNEmail] = useState("");
    const [nRole, setNRole] = useState("");
    const [nAffiliation, setNAffiliation] = useState("");
    const [reasoning, setReasoning] = useState("");
    const [disableInfoSubmit, setDisableInfoSubmit] = useState(false);
    const [showSubText, setShowSubText] = useState(false);
    // Users must include a reasoning, if not they cannot submit the form -KT */
    const [reasoningFilled, setReasoningFilled] = useState(false);

    // Pull account information from backend, runs every refresh - TRC
    useEffect(() => {
        // Pull account information from backend
        axios.get(API_URL + '/api/account', { headers: authHeader() })
            .then((res) => {
                setUsername(res.data['username']);
                setFullname(res.data['fullname']);
                setEmail(res.data['email']);
                setRole(res.data['role']);
                setAffiliation(res.data['affiliation']);
                setAdmin(res.data['admin']);
                formatDate(new Date(res.data['created_at']['$date']));
            })
            .catch((err) => console.log(err))

    }, []);

    // Check if new password inputs are valid (does nothing if they are not both set) - TRC
    useEffect(() => {
        if(newPass != "" && newPassCon != "") {
            checkNewPassword();
        }
    }, [newPass, newPassCon]);

    // Format a passed in date object, return a formatted string - TRC
    function formatDate(dateObj) {
        setJoinDate((dateObj.getMonth() + 1) + "/" + dateObj.getDate() + "/" + dateObj.getFullYear());
    }

    // Open the password dialog box - TRC
    function handlePasswordOpen() {
        setPOpen(true);
    }

    // Close the password dialog without saving - TRC
    function handlePasswordClose() {
        setPOpen(false);
        setDisplayNew(false);
        // Reset warning display trackers
        setOldPWarning(false);
        setNewPWarning(false);
        // Reset Dialog box trackers
        setAfterOldInput(false);
        setAfterNewInput(false);
        // Reset instance password trackers
        setOldPassword("");
        setNewPass("");
        setNewPassCon("");
    }

    // Set the instance variable for old password field - TRC
    function handleOldPassEntry(e) {
        setOldPassword(e.target.value);
    }

    // Set the instance variable for new password field 1 - TRC
    function handleSetNewPass(e) {
        setNewPass(e.target.value);
    }

    // Set the instance variable for new password field 2 - TRC
    function handleNewPassCon(e) {
        setNewPassCon(e.target.value);
    }

    // Compare the passed in password value with the old password on the server - TRC
    function verifyOldPass() {
        // Track if old password is invalid
        let invalid = true;
        axios.post(API_URL + '/api/verify/password', { 
                password: oldPassword
             }, { headers: authHeader() })
            .then((res) => {
                console.log(res.status);
                if (res.status === 200) {
                    invalid = false;
                }
                setOldPWarning(invalid);
                setDisplayNew(!invalid);   
                // Mark field edited
                setAfterOldInput(true);
            })
            .catch((err) => {
                console.log(err);
                setOldPWarning(invalid);
                setDisplayNew(!invalid);
                setAfterOldInput(true);
            });
    }

    // Compare the two new password fields - TRC
    function checkNewPassword() {
        // Mark field edited
        setAfterNewInput(true);

        // Check if passwords match and meet specifications
        if((newPassCon == newPass) && (newPass.length >= 5)) {
            setNewPWarning(false);
        } else {
            setNewPWarning(true);
        }
    }

    // Save the new password, then close the password dialog - TRC
    function applyPasswordChange() {
        // Send new password to backend
        axios.post(API_URL + '/api/change/password', { 
            old_password: oldPassword,
            new_password: newPass,
            email: email,
            name: fullname
         }, { headers: authHeader() })
        .then((res) => {
            handlePasswordClose();
        })
        .catch((err) => console.log(err));
    }

    // Password dialog box build method, moved out of main return for readability - TRC
    function buildPasswordDialog() {
        return (
            <Dialog
                open={pOpen}
                TransitionComponent={Transition}
                keepMounted
                fullWidth
                maxWidth="xs"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"MVSF - Change Password"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please follow instructions to change password. Password changes are final.
                    </DialogContentText>
                    { oldPWarning && afterOldInput ? (
                        <Typography variant="subtitle2" color="secondary">Incorrect Old Password</Typography>
                    ): null }
                    { !oldPWarning && afterOldInput ? (
                        <Typography variant="subtitle2" color="primary">Old Password Accepted! Please Continue</Typography>
                    ): null }
                    <TextField
                        margin="dense"
                        id="oldPass"
                        label="Enter Old Password"
                        type="password"
                        error={oldPWarning}
                        value={oldPassword}
                        onChange={handleOldPassEntry}
                        disabled={displayNew}
                        fullWidth
                        variant="outlined"
                        color="primary"
                        autoComplete="off"
                    />
                    { /* Only display new password fields if the old password field is validated  - TRC */}
                    { displayNew ? (
                        <React.Fragment>
                            <Divider dark />
                            <Typography variant="subtitle2">Passwords must be at LEAST 5 characters long.</Typography>
                            { newPWarning && afterNewInput ? (
                                <React.Fragment>
                                    <Typography variant="subtitle2" color="secondary">Passwords do not match and/or do not meet requirements.</Typography>
                                </React.Fragment>
                            ): null }
                            <TextField
                                margin="dense"
                                id="newPass"
                                label="Enter New Password"
                                type="password"
                                error={newPWarning}
                                value={newPass}
                                onChange={handleSetNewPass}
                                fullWidth
                                variant="outlined"
                                color="primary"
                                autoComplete="off"
                            />
                            <TextField
                                margin="dense"
                                id="confirmNewPass"
                                label="Confirm New Password"
                                type="password"
                                error={newPWarning}
                                value={newPassCon}
                                onChange={handleNewPassCon}
                                fullWidth
                                variant="outlined"
                                color="primary"
                                autoComplete="off"
                            />
                            </React.Fragment>
                    ): null }
                </DialogContent>
                <DialogActions>
                { !displayNew ? (
                    <Button variant="outlined" color="primary" onClick={verifyOldPass}>Check</Button>
                ): null }
                { !oldPWarning && afterOldInput && !newPWarning && afterNewInput ? (
                    <Button variant="outlined" color="primary" onClick={applyPasswordChange}>Submit</Button>
                ): null }
                <Button variant="outlined" color="secondary" onClick={handlePasswordClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        )
    }

    // Handle information change request button click, open dialog box. - TRC
    function handleInfoChangeRequest() {
        // Set instance variables
        setNUsername(username);
        setNFullname(fullname);
        setNEmail(email);
        setNRole(role);
        setNAffiliation(affiliation);
        // Activate submit button
        setDisableInfoSubmit(false);
        // Disable success text, just in case
        setShowSubText(false);
        // Open Information Change Dialog
        setInfoChangeOpen(true);
    }

    // Send information change request - TRC
    function submitInfoChangeReq() {
        axios.post(API_URL + '/api/update/info', { 
            fullname: nFullname,
            email: nEmail,
            role: nRole,
            affiliation: nAffiliation,
            reason: reasoning,
        }, { headers: authHeader() })
        .then((res) => {
            setDisableInfoSubmit(true);
            setShowSubText(true);
        })
    }

    // Close the information change request dialog - TRC
    function handleInfoClose() {
        setInfoChangeOpen(false);
    }

    // Set the instance variable for new username - TRC
    function handleNUsername(e) {
        setNUsername(e.target.value);
    }

    // Set the instance variable for new fullname - TRC
    function handleNFullname(e) {
        setNFullname(e.target.value);
    }

    // Set the instance variable for new email - TRC
    function handleNEmail(e) {
        setNEmail(e.target.value);
    }

    // Set the instance variable for new role - TRC
    function handleNRole(e) {
        setNRole(e.target.value);
    }

    // Set the instance variable for new affiliation - TRC
    function handleNAffiliation(e) {
        setNAffiliation(e.target.value);
    }

    // Set the instance variable for reasoning - TRC
    function handleReasoning(e) {
        if (e.target.value === "") setReasoningFilled(false);
        else setReasoningFilled(true);
        setReasoning(e.target.value);
    }

    // Information Change Request dialog box build method - TRC
    function buildInfoChangeDialog() {
        return (
            <Dialog
                open={infoChangeOpen}
                TransitionComponent={Transition}
                keepMounted
                fullWidth
                maxWidth="lg"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"MVSF - Account Information Change Request"}</DialogTitle>
                <DialogContent dividers>
                    { showSubText ? (
                        <Alert severity="success">Infromation Change Request Submitted! We will be in contact with you as soon as possible.</Alert>
                    ): null }
                    <DialogContentText>
                        Please edit the information you would like to change. Once you are ready please make sure you have provided a reason for this change and then click submit. Information changes are handled 
                        by an admin, so please be patient and we will get back to you about your request ASAP.
                    </DialogContentText>
                    <Container className={classes.infoChangeField}>
                        <Container className={classes.infoChangeFieldSub}>
                            <Typography variant="subtitle1">Current Full Name:</Typography>
                            <Typography variant="subtitle1" color="primary">{fullname}</Typography>
                        </Container>
                        <Container className={classes.infoChangeFieldSub}>
                            <Typography variant="subtitle1">New Full Name:</Typography>
                            <TextField
                                    margin="dense"
                                    id="newFullname"
                                    label="Full Name"
                                    type="string"
                                    value={nFullname}
                                    onChange={handleNFullname}
                                    variant="outlined"
                                    color="primary"
                                    autoComplete="off"
                            />
                        </Container>
                    </Container>
                    <Container className={classes.infoChangeField}>
                        <Container className={classes.infoChangeFieldSub}>
                            <Typography variant="subtitle1">Current Email:</Typography>
                            <Typography variant="subtitle1" color="primary">{email}</Typography>
                        </Container>
                        <Container className={classes.infoChangeFieldSub}>
                            <Typography variant="subtitle1">New Email:</Typography>
                            <TextField
                                    margin="dense"
                                    id="newEmail"
                                    label="Email"
                                    type="email"
                                    value={nEmail}
                                    onChange={handleNEmail}
                                    variant="outlined"
                                    color="primary"
                                    autoComplete="off"
                            />
                        </Container>
                    </Container>
                    <Container className={classes.infoChangeField}>
                        <Container className={classes.infoChangeFieldSub}>
                            <Typography variant="subtitle1">Current Role:</Typography>
                            <Typography variant="subtitle1" color="primary">{role}</Typography>
                        </Container>
                        <Container className={classes.infoChangeFieldSub}>
                            <Typography variant="subtitle1">New Role:</Typography>
                            <TextField
                                    margin="dense"
                                    id="newRole"
                                    label="Role"
                                    type="string"
                                    value={nRole}
                                    onChange={handleNRole}
                                    variant="outlined"
                                    color="primary"
                                    autoComplete="off"
                            />
                        </Container>
                    </Container>
                    <Container className={classes.infoChangeField}>
                        <Container className={classes.infoChangeFieldSub}>
                            <Typography variant="subtitle1">Current Affiliation:</Typography>
                            <Typography variant="subtitle1" color="primary">{affiliation}</Typography>
                        </Container>
                        <Container className={classes.infoChangeFieldSub}>
                            <Typography variant="subtitle1">New Affiliation:</Typography>
                            <TextField
                                    margin="dense"
                                    id="newAffiliation"
                                    label="Affiliation"
                                    type="string"
                                    value={nAffiliation}
                                    onChange={handleNAffiliation}
                                    variant="outlined"
                                    color="primary"
                                    autoComplete="off"
                            />
                        </Container>
                    </Container>
                    <DialogContentText>
                        Please provide a reasoning below for your information change request. Admins must review the infromation change and a reasoning helps the admins to better evaluate the request. Thank you!
                        <br />NOTE: <b>If no reasoning is provided, the request will be denied.</b>
                    </DialogContentText>
                    <TextField
                        required
                        margin="dense"
                        id="reasoning"
                        label="Reason for Information Change"
                        type="string"
                        onChange={handleReasoning}
                        fullWidth
                        multiline={true}
                        minRows={5}
                        variant="outlined"
                        color="primary"
                        autoComplete="off"
                    />
                    <DialogContentText>
                        <b>IMPORTANT:</b> Please note that you may only have one pending information change request at any time. If you submit a new request, your pending request will be overwritten.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="primary" onClick={submitInfoChangeReq} disabled={disableInfoSubmit || !reasoningFilled}>Submit</Button>
                    <Button variant="outlined" color="secondary" onClick={handleInfoClose}>Cancel</Button>
                </DialogActions>
            </Dialog>
        )
    }

    // MASTER Return Statement - TRC
    return (
        <Box className={classes.box}>
            <Navbar />
            <Container className={classes.container} disableGutters>
                <Typography variant="h5" align="center" className={classes.title} paragraph>My Account</Typography>
                <Typography variant="subtitle1" align="center">Displaying account information for: <b>{username}</b></Typography>
                <hr />
                <Container component={Paper} className={classes.infoBox} elevation={10}>
                    <Container className={classes.accountField}>
                        <Typography className={classes.subAccountField} variant="h6" align="center">Username:</Typography>
                        <Typography className={classes.subAccountField} variant="h6" align="center">{username}</Typography>
                    </Container>
                    <Divider dark />
                    <Container className={classes.accountField}>
                        <Typography className={classes.subAccountField} variant="h6" align="center">Full Name:</Typography>
                        <Typography className={classes.subAccountField} variant="h6" align="center">{fullname}</Typography>
                    </Container>
                    <Divider dark />
                    <Container className={classes.accountField}>
                        <Typography className={classes.subAccountField} variant="h6" align="center">Email:</Typography>
                        <Typography className={classes.subAccountField} variant="h6" align="center">{email}</Typography>
                    </Container>
                    <Divider dark />
                    <Container className={classes.accountField}>
                        <Typography className={classes.subAccountField} variant="h6" align="center">Role:</Typography>
                        <Typography className={classes.subAccountField} variant="h6" align="center">{role}</Typography>
                    </Container>
                    <Divider dark />
                    <Container className={classes.accountField}>
                        <Typography className={classes.subAccountField} variant="h6" align="center">Affiliation:</Typography>
                        <Typography className={classes.subAccountField} variant="h6" align="center">{affiliation}</Typography>
                    </Container>
                    <Divider dark />
                    <Container className={classes.accountField}>
                        <Typography className={classes.subAccountField} variant="h6" align="center">Member Since:</Typography>
                        <Typography className={classes.subAccountField} variant="h6" align="center">{joinDate}</Typography>
                    </Container>
                    <Divider dark />
                    <Container className={classes.accountField}>
                        <Typography className={classes.subAccountField} variant="h6" align="center">Password:</Typography>
                        <Button className={classes.subAccountField} variant="outlined" color="secondary" onClick={handlePasswordOpen}>Change Password</Button>
                    </Container>
                    { /* Dialog Box Code  - TRC */ }
                    { buildPasswordDialog() }
                    { /* Show admin information  - TRC */}
                    { admin ? (
                    <React.Fragment>
                        <Divider dark />
                        <Container className={classes.accountField}>
                            <Typography className={classes.subAccountField} variant="h6" align="center">Admin Permissions:</Typography>
                            <Container className={classes.subAccountField}>
                                <Checkbox size="large" align="center" checked={admin}/>
                            </Container>
                        </Container>
                        <Divider dark />
                        <Container className={classes.accountFieldSingle}>
                            <Link to={urls.adminPanelURL}><Typography variant="h6" align="center">Access Admin Portal</Typography></Link>
                        </Container>
                    </React.Fragment>
                    ): null }
                </Container>
                { /* Link to submit an information change request - TRC */ }
                <Typography className={classes.changeRequest}><Button variant="text" size="small" onClick={handleInfoChangeRequest}>Submit an Information Change Request</Button></Typography>
                { /* Build Information Change Dialog */}
                { buildInfoChangeDialog() }
            </Container>
            <Footer />
        </Box>
    )
}