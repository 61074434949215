import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Dialog, DialogActions, DialogContent,
    DialogTitle, Button,
    IconButton, Typography,
    Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow,
    Paper, Popover
} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';


const useStyles = makeStyles((theme) => ({
    table: {

    },
    tableContainer: {
        marginTop: 40,
        marginBottom: 40
    },
    typography: {
        padding: theme.spacing(2),
    },
    noRecords: {
        backgroundColor: "red"
    }
}))

const formatDate = (date) => {
    if (date) {
        const splitted = date.split("-");
        return `${splitted[1]}/${splitted[2]}/${splitted[0]}`
    }
}

/**
 * Ranking Information Dialog 
 * 
 * @author Kaan Turkmen
 * @editor Thomas Campbell
 * @comments Thomas Campbell
 * @param {*} props
 *      visible: Boolean Controlling visibility of dialog
 *      onClose: OnClose Function Callback
 *      ranking: Ranking Information
 *      dates: Date Timeframes
 * @returns An instance of a Ranking Information Dialog
 */
export default function RankingDialog(props) {
    const classes = useStyles()
    const [ranking, setRanking] = useState([]);
    const [detailsRef, setDetailsRef] = useState(null);
    const [details, setDetails] = useState({});

    const open = Boolean(detailsRef);
    const id = open ? 'simple-popover' : undefined;
    const dates1 = formatDate(props.dates[0]);
    const dates2 = formatDate(props.dates[1]);

    // Hanlde opening of the dialog - Comments: TRC, Author: KT
    const handleOpen = (event, rank) => {
        openDetails(event);
        setDetails(rank);
    }

    // Open callback - Comments: TRC, Author: KT
    const openDetails = (event) => {
        setDetailsRef(event.currentTarget);
    };

    // Sort the ranking data - Comments: TRC, Author: KT
    const sortRanking = (list) => {
        list.sort((a, b) => {
            return a["Our Ranking"] - b["Our Ranking"]
        })
    }

    // Update ranking infromation on visibility change - Comments: TRC, Author: KT
    useEffect(() => {
        const tmp = props.ranking;
        let rows = []
        console.log(tmp);
        for (let i = 0; i < tmp["CWE ID"]?.length; i++) {
            let row = {}
            row["CWE ID"] = tmp["CWE ID"][i]
            row["Our Overall Score"] = tmp["Our Overall Score"][i]
            row["Our Ranking"] = tmp["Our Ranking"][i]
            row["# of CVEs"] = tmp["# of CVEs"][i]
            row["Avg Exploitability V2"] = tmp["Avg Exploitability V2"][i]
            row["Avg Days Since Published"] = tmp["Avg Days Since Published"][i]
            row["Avg Impact Score V2"] = tmp["Avg Impact Score V2"][i]
            row["Avg Exposure Factor"] = tmp["Avg Exposure Factor"][i]
            row["Avg Base Score"] = tmp["Avg Base Score"][i]
            row["Avg Exploitation Likelihood"] = tmp["Avg Exploitation Likelihood"][i]
            row["MITRE Overall Score"] = tmp["MITRE Overall Score"][i]
            rows.push(row)
        }
        sortRanking(rows);
        setRanking(rows);
    }, [props.visible]);

    // Master Return Statement - Comments: TRC, Author: KT
    return (
        <div>
            <Dialog open={props.visible} onClose={() => props.onClose(false)} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Ranking</DialogTitle>
                <DialogContent>
                    Showing the ranking from {dates1} to {dates2}.
                    <TableContainer component={Paper} className={classes.tableContainer}>
                        <Table key="table" className={classes.table} size="medium" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" className={classes.header}>Rank</TableCell>
                                    <TableCell align="center" className={classes.header}>CWE ID</TableCell>
                                    <TableCell align="center" className={classes.header}>Score</TableCell>
                                    <TableCell align="center" className={classes.header}>Details</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                { Object.keys(ranking).length !== 0 ? 
                                    ranking.map(rank => (
                                        <TableRow>
                                            <TableCell align="center">{rank["Our Ranking"]}</TableCell>
                                            <TableCell align="center">{rank["CWE ID"]}</TableCell>
                                            <TableCell align="center">{rank["Our Overall Score"].toFixed(2)}</TableCell>
                                            <TableCell align="center">
                                                <IconButton aria-describedby={id} onClick={(event) => handleOpen(event, rank)}>
                                                    <InfoOutlinedIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                : (
                                    <TableRow>
                                        <TableCell colSpan={4} align="center">No records found!</TableCell>
                                    </TableRow>
                                )}
                                
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={() => props.onClose(false)}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            <Popover
                id={id}
                open={open}
                anchorEl={detailsRef}
                onClose={() => setDetailsRef(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Typography className={classes.typography}>Number of CVEs: {details["# of CVEs"]?.toFixed(2)}</Typography>
                <Typography className={classes.typography}>Avg Exploitability V2: {details["Avg Exploitability V2"]?.toFixed(2)}</Typography>
                <Typography className={classes.typography}>Avg Days Since Published: {details["Avg Days Since Published"]?.toFixed(2)}</Typography>
                <Typography className={classes.typography}>Avg Impact Score V2: {details["Avg Impact Score V2"]?.toFixed(2)}</Typography>
                <Typography className={classes.typography}>Avg Exposure Factor: {details["Avg Exposure Factor"]?.toFixed(2)}</Typography>
                <Typography className={classes.typography}>Avg Base Score: {details["Avg Base Score"]?.toFixed(2)}</Typography>
                <Typography className={classes.typography}>Avg Exploitation Likelihood: {details["Avg Exploitation Likelihood"]?.toFixed(2)}</Typography>
                <Typography className={classes.typography}>Overall Score: {details["Our Overall Score"]?.toFixed(5)}</Typography>
            </Popover>
        </div>
    );
}
