import axios from "axios";
import { API_URL } from "../settings";
import authHeader from '../services/authHeader';

// Make backend request to retrieve undecided account requests - Author: KT, Comment: TRC
export function getAccountRequests () {
    return axios.get(API_URL + "/api/account/requests", { headers: authHeader() })
    .then(res => {
        return res.data
    }).catch(err => {
        console.log(err)
    });
}

// Make backend request to retrieve current account requests that are PENDING - Author: KT, Comment: TRC
export function getPendingAccountRequests() {
    return axios.get(API_URL + "/api/pending/requests", { headers: authHeader() })
    .then(res => {
        return res.data
    }).catch(err => {
        console.log(err)
    })
}

// Make backend request to retrieve current Info Change Requests - Author: KT, Comment: TRC
export function getInformationChangeRequests() {
    // Get the information change requests from the backend
    return axios.get(API_URL + "/api/info/change/requests", { headers: authHeader() })
    .then(res => {
        return res.data
    }).catch(err => {
        console.log(err)
    })
}

// Make backend request to retrieve current users - Author: KT, Comment: TRC
export function getUsers() {
    return axios.get(API_URL + '/api/get/users', { headers: authHeader() })
    .then(res => {
        return res.data
    }).catch(err => {
        console.log(err)
    })
}

// Make backend request to retrieve the cronjobs - Author: KT, Comment: TRC
export function getCronUpdates() {
    return axios.get(API_URL + '/api/cron/updates', { headers: authHeader() })
    .then(res => {
        return res.data
    }).catch(err => {
        console.log(err)
    })
}

// Make backend request to set a user (by ID) as an admin - Author: TRC, Comment: TRC
export function setAdmin(id, is_admin) {
    return axios.post(API_URL + '/set/admin', { id, 'admin': is_admin }, { headers: authHeader() })
    .then(res => {
        return res.data
    }).catch(err => {
        console.log(err)
    })
}