import React, { useEffect, useState } from 'react';
import { Avatar, Button, CssBaseline, TextField, Link, Box, Typography, Grid, Container, Paper, Divider } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect, useLocation, useHistory } from 'react-router-dom';
import authHeader from '../services/authHeader';
import * as URLS from '../urls.js'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import '../fonts/font.css';
import axios from 'axios';
import { API_URL } from '../settings';


export const auth = {
  isAuthenticated: false,
}

// DEPRECIATED
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: "15%",
    paddingRight: "15%"
  },
  barlowFont: {
    fontFamily: "Barlow",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  titleGrid: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: "10%"
  },
  passInstructions: {
    textAlign: "center",
    backgroundColor: "#e0e0e0",
    borderRadius: "10px"
  },
}));

/**
 * Password creation screenm
 * 
 * @author Kaan Turkmen
 * @editor Thomas Campbell
 * @comments Thomas Campbell
 * @param {*} props [UNUSED]
 * @returns A password creation screen
 */
export default function CreatePassword(props) {
  const classes = useStyles();
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  // Control warning state - TRC
  const [warning, showWarning] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const history = useHistory();
  const { from } = location.state || { from: { pathname: "/" } };


  // Handle submission of a password to the backend using axios - TRC
  const handleSubmit = () => {
    console.log('User ID:' + params.get('uuid'));
    if(password !== confirmPassword) {
      showWarning(true);
    } else {
      // Send request to finalize and store password as well as create user - TRC
      axios.post(API_URL + '/api/create/user', {
        rid: params.get('uuid'),
        password: password,
        confirm_password: confirmPassword,
      }, { headers: authHeader() })
      // Push the user to the login page
      history.push(URLS.loginURL);
    }
  }

  // Master Return Statement - TRC
  return (
    <Container component="main">
      <CssBaseline />
      <Grid container>
        <Grid item xs={6} className={classes.titleGrid}>
          <Typography variant="h1" className={classes.barlowFont}>MVSF</Typography>
          <Typography variant="h5" className={classes.barlowFont}>Mason Vulnerability Scoring Framework</Typography>
          <br />
          <br />
          <Container className={classes.passInstructions} component={Paper} elevation={3}>
            <Typography variant="h6" className={classes.barlowFont}>Welcome to the MVSF password creation page!</Typography>
            <Divider />
            <Typography variant="subtitle1" className={classes.barlowFont}>
              In order to create and access your account, you must create your password. Please create a password to continue.
            </Typography>
          </Container>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Create Password
            </Typography>
            <Typography component="h1" variant="subtitle1">
              Passwords must match. 
            </Typography>
            <form className={classes.form} noValidate>
              {warning &&
                <Alert variant="filled" severity="warning">
                  Passwords do not match!
                </Alert>
              }
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="password"
                label="Password"
                name="password"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="confirm-password"
                label="Confirm Password"
                type="password"
                id="confirm-password"
                autoComplete="current-password"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              {/* <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={handleSubmit}
              >
                Create Account
              </Button>
              <Container className={classes.passInstructions} component={Paper} elevation={3}>
                <Typography variant="subtitle1" className={classes.barlowFont}>
                  Redirect Notice
                </Typography>
                <Divider />
                <Typography variant="subtitle2" className={classes.barlowFont}>
                  Clicking the above button will redirect you to the sign in page.
                </Typography>
              </Container>
              <Grid container>
              </Grid>
            </form>
          </div>
        </Grid>
      </Grid>
      <Box mt={8}>
        {/* <Copyright /> */}
      </Box>
    </Container>
  );
}
