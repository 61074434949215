import React, { useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { InlineMath } from 'react-katex';
import { makeStyles } from '@material-ui/core/styles';
import {
    Box, AppBar, Toolbar,
    IconButton, Typography, Button,
    Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow,
    Paper, Container, Drawer, ListItem,
    Grid, Checkbox, LinearProgress,
    Backdrop, CircularProgress, Popover
} from '@material-ui/core';
import { ToggleButtonGroup, ToggleButton } from '@material-ui/lab';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import KeyBoardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyBoardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { Close } from '@material-ui/icons';
import Settings from '@material-ui/icons/Settings';
import { green, red, yellow } from '@material-ui/core/colors';
import InputDialog from '../components/InputDialog';
import RankingDialog from '../components/RankingDialog';
import YearSelectionDialog from '../components/YearSelectionDialog';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { API_URL, TOTAL_HF_HEIGHT } from '../settings';
import axios from 'axios';
import authHeader from '../services/authHeader';
import { SettingsContext } from '../contexts/SettingsContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { findCWE } from '../services/helper';
import { settingsURL } from '../urls';
import '../fonts/font.css';
import CWECompDialog from '../components/CWECompDialog.js';


const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    container: {
        width: "100%",
        // Statement below makes footer anchor to bottom 
        // (Only needed on pages where the information would result in an incorrect footer )- TRC
        minHeight: "calc(100vh - " + TOTAL_HF_HEIGHT + ")",
    },
    table: {
        minWidth: 650,
        maxWidth: 2600,
    },
    tableContainer: {
        marginBottom: "2%",
        marginTop: "1%",
        backgroundColor: "#eeeeee",
    },
    header: {
        fontWeight: "bold",
        whiteSpace: 'nowrap',
        padding: "8px",
    },
    headerCheck: {
        height: "20px",
        boxSizing: "border-box",
        padding: "0px",
    },
    headerGrid: {
        marginTop: "2%",
        marginBottom: "1%",
    },
    cell: {
        fontSize: 13,
        padding: 0,
        "&:hover": {
            backgroundColor: "#f8ff21 !important"
        }
    },
    cellHighlighted: {
        fontSize: 13,
        padding: 0,
        backgroundColor: "#f8ff21 !important"
    },
    tableTitle: {
        fontWeight: "bold",
        marginTop: "1%",
        fontFamily: "Barlow",
    },
    loadMoreButton: {
        left: "45%",
        marginBottom: "2%"
    },
    upIcon: {
        fontSize: "medium",
        color: green[500],
        justifyContent: "center",
        paddingLeft: 5
    },
    downIcon: {
        fontSize: "medium",
        color: red[500],
        justifyContent: "center",
        paddingLeft: 5
    },
    intervalButton: {
        width: "85%",
        // justifyContent: "center"
    },
    intervalPaper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: "space-around",
        padding: "2%",
        paddingRight: "3%",
        paddingLeft: "%3",
        width: "100%",
        height: "100%",
    },
    intervalGrid: {
        justifyContent: "center",
        alignItems: "center",
    },
    backdrop: {
        zIndex: 10
    },
    settingsInfo: {
        marginRight: "1%",
    },
    closeButton: {
        display: 'flex',
        justifyContent: 'right'
    },
    // Tooltip button in selected cell
    tooltipCellButton: {
        width: '100%',
        height: '100%',
        padding: '0rem',
    },
    tooltip: {
        pointerEvents: 'auto',
        opacity: 0.9
    },
    linkIcon: {
        marginLeft: "3%"
    },
    cveCompBtn: {
        marginTop: '2%',
        marginBottom: '2%',
    },
    // Just a horizotal flex container - TRC
    flexDiv: {
        padding: '0px',
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'space-between',
        margin: 'auto'
    },
    toggleContainer: {
        width: "16.8%",
        padding: "0%",
        alignItems: "center",
        marginRight: "0px",
    },
    toggleText: {
        fontFamily: "Barlow",
        fontWeight: "bold",
    }
}));

/**
 * Live Rankings Page
 * Located At: https://mvsf.gmu.edu/live-rankings
 * 
 * @author Kaan Turkmen
 * @editor Thomas Campbell
 * @comments Thomas Campbell
 * @param {*} props 
 *      history: browser history
 * @returns Live Rankings Page
 */
export default function LiveRankings(props) {
    const classes = useStyles();
    const settings = useContext(SettingsContext);
    const [rows, setRows] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [inputDialog, showInputDialog] = useState(false);
    const [rankingDialog, showRankingDialog] = useState(false);
    const [yearSelectionDialog, showYearSelectionDialog] = useState(false);
    const [ranking, setRanking] = useState([]);
    const [details, setDetails] = useState({});
    const [dates, setDates] = useState([]);
    const [selectedCWE, setSelectedCWE] = useState("");
    // Corresponds to the currently clicked on cell, defaulted at [-1,-1] to avoid styling issues -TRC
    const [selectedCell, setSelectedCell] = useState([-1, -1]);
    // Corresponds to the toggle state of the master reset checkbox - TRC
    const [masterToggle, setMasterToggle] = useState(false);
    // Corresponds to the currently "hovered over" CWE - TRC
    const [highlightedCWE, setHighlightedCWE] = useState("");
    // Corresponds to the currently "locked" CWE - TRC
    const [lockedCWE, setLockedCWE] = useState([]);
    // Below variable not used currently, why? -TRC
    const [highlightedCell, setHighlightedCell] = useState([0, 0]);
    const [loadSize, setLoadSize] = useState(25);
    const [maxRowSize, setMaxRowSize] = useState(150);
    // Variable controlling circular progress backdrop
    const [backdrop, showBackdrop] = useState(false);
    const [tooltip, showTooltip] = useState(false);
    const [loadingTooltip, setLoadingTooltip] = useState(true);
    const [popoverAnchor, setPopoverAnchor] = useState(null);
    const [latestUpdate, setLatestUpdate] = useState(null);
    // Control variable for displaying/calling the CVE Composition Card - TRC
    const [CWECompositionDialog, showCWECompositionDialog] = useState(null);
    // Storage Variable for raw data in order to retrieve CWE infromation - TRC
    const [rawData, setRawData] = useState({});
    // Store if the user is an admin - TRC
    const [admin, setAdmin] = useState(false);
    // Store username of user - TRC
    const [username, setUsername] = useState(null);
    // Variable to control the displayed version of rankings - TRC [CURRENT DEFAULT IS VERSION 1]
    const [rankVersion, setRankVersion] = useState(1);

    // Get data from the backend - TRC
    async function fetchData() {
        console.log(`Fetching ${settings.startYear} - ${settings.endYear}`);
        console.log(`Settings: ${JSON.stringify(settings)}`);
        // Turn on loading overlay - TRC
        showBackdrop(true);
        axios.get(API_URL + "/get/dataframes", { params: settings, headers: authHeader() })
            .then(res => transformData(res.data))
            .catch(err => console.log(err))
    }

    // Get the latest data update
    async function fetchLatestUpdate() {
        axios.get(API_URL + '/latest/update')
            .then(res => {
                const date = new Date(res.data.timeframe);
                setLatestUpdate(date.toString());
            })
            .catch(err => console.log(err))
    }

    // Get if the user is an admin - TRC
    async function fetchAdmin() {
        // Pull account information from backend
        axios.get(API_URL + '/api/account', { headers: authHeader() })
            .then((res) => {
                setUsername(res.data['username']);
                setAdmin(res.data['admin']);
            })
            .catch((err) => console.log(err))
    }

    // Download ranking data for the live ranking - TRC, KT
    async function downloadData() {
        console.log("DOWNLOAD DATA CLICKED!!!!\n");
        let currentMonth = new Date().getMonth();
        settings.month = currentMonth;
        axios.get(API_URL + '/api/download/live/rankings', { params: settings, responseType: 'blob', headers: { 'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', ...authHeader() } })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'live-rankings.xlsx');
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch(err => console.log(err));
    }

    // Organize and sanitize data from the backend containing ALL live rankings
    async function transformData(data) {
        let rows = [];
        let headers = [];
        let rowSize = 150;
        // COMMENTED OUT CONSOLE LOGS TO AVOID CLUTTER - TRC
        // console.log(data);

        // Store the raw data in the instance
        setRawData(data);

        data.forEach(record => rowSize = Math.min(record["ranking"].length, rowSize));

        headers = data.map(record => record["timeframe"])

        // Formatting from 2022-4 to 2022-04
        headers = data.map(data => {
            let year = data["timeframe"].split("-")[0];
            let month = data["timeframe"].split("-")[1];
            return year + "-" + month.padStart(2, "0");
        });

        // Sort the headers - TRC
        headers.sort((a, b) => {
            let aYear = parseInt(a.split("-")[0]);
            let bYear = parseInt(b.split("-")[0]);
            // Compare years before comparing months
            if (aYear < bYear) {
                return -1;
            } else if (bYear < aYear) {
                return 1;
            }
            // Compare months if years are equal
            let aMonth = parseInt(a.split("-")[1]);
            let bMonth = parseInt(b.split("-")[1]);
            // Compare months
            if (aMonth < bMonth) {
                return -1;
            } else if (bMonth < aMonth) {
                return 1;
            }
            // Return 0 if somehow the same
            return 0;
        })

        setHeaders(headers);
        // console.log(`Size: ${rowSize}`)

        for (let i = 0; i < rowSize; i++) {
            let row = {};
            data.forEach((rec, index) => {
                let timeframe = rec["timeframe"]
                let year = rec["timeframe"].split("-")[0];
                let month = rec["timeframe"].split("-")[1];
                let header = year + "-" + month.padStart(2, "0");
                row[header] = rec["ranking"][i]["CWE ID"]
            })

            // Get the current month - TRC
            const d = new Date();
            let month = "" + (d.getMonth());
            month = month.padStart(2, "0");

            // Get the change in the CWE Ranking - TRC
            row["Change"] = findCWE(data, row[`${settings.endYear}-${month}`]);
            rows.push(row);
        }

        // console.log(rows);
        setRows(rows);
        setMaxRowSize(rowSize);
        // Turn off backdrop
        showBackdrop(false);
    }

    // Retrieve the details of the popup - TRC
    // REFACTORED TO NOT USE A BACKEND CALL
    function getDetails() {
        setLoadingTooltip(true);
        const colIndex = selectedCell[1];

        for (let i = 0; i < rawData[colIndex]['ranking'].length; i++) {
            if (rawData[colIndex]['ranking'][i]["CWE ID"] == selectedCWE) {
                setDetails(rawData[colIndex]['ranking'][i]);
                console.log(rawData[colIndex]['ranking'][i]);
            }
        }
        setLoadingTooltip(false);
        showTooltip(true);
    }

    // Callback for user hovering over a cell - TRC
    function handleCellHover(row, header, rowIndex, colIndex) {
        setHighlightedCWE(row[header]);
        setHighlightedCell([rowIndex, colIndex]);
    }

    // Handle user action of clicking on a cell - Comment TRC, Code KT
    function handleCellClick(row, header, rowIndex, colIndex) {
        setSelectedCWE(row[header]);
        setSelectedCell([rowIndex, colIndex]);
    }

    // Function to add or remove a CWE from the hightlist tracking list - TRC
    function handleLockToggle(row) {
        // Get the current month - TRC
        // EXPLAIN: Logic runs off of the idea that the most recent month is the current month - 1 (since that is the most recent data) due to this
        //          there will be 12 months displayed, meaning the first month displayed will be the previous years' current month (for example
        //          if it were currently March of 2022 then the first month displayed in the table will be March of 2021)
        const d = new Date();
        let month = "" + (d.getMonth() + 1);
        month = month.padStart(2, "0");

        // Decrement year by 1 (IFF the current month isnt january) - TRC
        let year = settings.endYear;
        if ((d.getMonth() + 1) != 1) {
            year -= 1;
        }

        if (lockedCWE.includes(row[year + "-" + month])) {
            setLockedCWE(lockedCWE.filter(CWE => CWE !== row[year + "-" + month]));
        } else {
            setLockedCWE(lockedCWE => [...lockedCWE, row[year + "-" + month]]);
        }
    }

    // Handle the master checkbox being toggled - TRC
    function handleMasterToggle() {
        if (masterToggle) {
            resetLocked();
        } else {
            return;
        }
    }

    // UseEffect: Check if ANY checkboxes are checked and update the master toggle - TRC
    useEffect(() => {
        setMasterToggle(lockedCWE.length >= 1);
    }, [lockedCWE]);

    // Reset lockedCWE list to be empty - KT
    function resetLocked() {
        setLockedCWE([]);
    }

    // Return true if checkmark's corresponding CWE is in the lockedCWE list - TRC
    function isClicked(row) {
        // Get the current month - TRC
        // EXPLAIN: Logic runs off of the idea that the most recent month is the current month - 1 (since that is the most recent data) due to this
        //          there will be 12 months displayed, meaning the first month displayed will be the previous years' current month (for example
        //          if it were currently March of 2022 then the first month displayed in the table will be March of 2021)
        const d = new Date();
        let month = "" + (d.getMonth() + 1);
        month = month.padStart(2, "0");

        // Decrement year by 1 (IFF the current month isnt january) - TRC
        let year = settings.endYear;
        if ((d.getMonth() + 1) != 1) {
            year -= 1;
        }

        return lockedCWE.includes(row[year + "-" + month]);
    }

    // Function to specifically unset the hovered cell for visual appeal - TRC
    function removeHoveredCell() {
        setHighlightedCWE(null);
    }

    // Update the currently clicked cell - TRC
    function updateClickedCell(event) {
        setPopoverAnchor(event.currentTarget);
    }

    // CURRENTLY NOT IN USE -- Resets the clicked cell location - TRC
    function resetClickedCell() {
        setPopoverAnchor(null);
    }

    // Move the year forward
    function nextYear() {
        settings.endYear += 1;
        settings.startYear += 1;

        // Clear the hovered cell - TRC
        setHighlightedCWE("");
        // Reset the selected cell - TRC
        setSelectedCell([-1, -1]);

        fetchData();
    }

    // Move the year backward
    function previousYear() {
        settings.endYear -= 1;
        settings.startYear -= 1;

        // Clear the hovered cell - TRC
        setHighlightedCWE("");
        // Reset the selected cell - TRC
        setSelectedCell([-1, -1]);

        fetchData();
    }

    // Check if the given month contains not completed
    // Given header in the format of YYYY-MM return boolean
    function isPrediction(date) {
        const fullDate = `${date}-31`;
        const datetime = new Date(fullDate);
        return new Date() > new Date(fullDate);
    }

    // Handle the opening of the popup toolip for a specific CWE - TRC
    function handleTooltipOpen() {
        // Retrieve the details of the popup - TRC
        getDetails();
    }

    // Re-call fetch data 
    useEffect(() => {
        fetchData();
    }, [settings.startYear, settings.endYear])

    // Initial data grab once the page is loaded
    useEffect(() => {
        console.log(API_URL)
        console.log(settings);
        fetchAdmin();
        fetchData();
        fetchLatestUpdate();
    }, []);

    // Show tooltip when clicked cell changes - TRC
    useEffect(() => {
        // Enable loading overlay - TRC
        showBackdrop(true);
        if (selectedCWE != "") {
            handleTooltipOpen();
        }
        console.log("Selected CWE: " + selectedCWE);
        // Disable loading overlay - TRC
        showBackdrop(false);
    }, [selectedCWE])

    // Toggle the state of displaying the CVE Composition Dialog - TRC
    function toggleCVEComp() {
        showCWECompositionDialog(!CWECompositionDialog);
    }

    // Handle the closing of the popover - TRC
    function handlePopoverClose() {
        // Reset selected CWE information
        setSelectedCWE("");
        setSelectedCell([-1, -1]);

        // Close tooltip
        showTooltip(false);
    }

    // Handle the change of the ranking version toggle - TRC
    function handleAlignment(event, newRanking) {
        // Only run if the value is not null
        if(newRanking !== null) {
            console.log("Displaying new ranking verison: " + newRanking);
            setRankVersion(newRanking);
        }
    };

    // MASTER Return statement - TRC
    return (
        <Box>
            <Navbar showInputDialog={showInputDialog}>
                <Button color="inherit" onClick={() => showYearSelectionDialog(true)} style={{ marginRight: "1%" }}>Select Year</Button>
                <Button color="inherit" onClick={() => props.history.push(settingsURL)}>Settings</Button>
            </Navbar>
            <Backdrop className={classes.backdrop} open={backdrop}>
                <CircularProgress />
            </Backdrop>
            <Container className={classes.container} disableGutters>
                <Grid container className={classes.headerGrid} spacing={3}>
                    <Grid item xs={2} className={classes.intervalGrid}>
                        <IconButton onClick={() => previousYear()} className={classes.intervalButton} disableRipple disabled={settings.startYear - 1 < 1999}>
                            <Paper className={classes.intervalPaper}>
                                <KeyBoardArrowLeftIcon fontSize="large" />
                                <Typography variant="button">Previous</Typography>
                            </Paper>
                        </IconButton>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="h5" align="center" className={classes.tableTitle}>
                            Monthly Rankings
                            <IconButton onClick={() => props.history.push(settingsURL)}>
                                <Settings />
                            </IconButton>
                        </Typography>

                    </Grid>
                    <Grid item xs={2} className={classes.intervalGrid}>
                        <IconButton onClick={() => nextYear()} variant="outlined" className={classes.intervalButton} disableRipple disabled={settings.endYear + 1 > new Date().getFullYear()}>
                            <Paper className={classes.intervalPaper}>
                                <Typography variant="button">Next</Typography>
                                <KeyBoardArrowRightIcon fontSize="large" />
                            </Paper>
                        </IconButton>
                    </Grid>
                </Grid>
                {/* {settings.endYear === new Date().getFullYear() ?
                    <Typography variant="subtitle2" paragraph>The asterisk * means that the data is incomplete for that timeframe. Shown information are predictions.</Typography>
                : null} */}
                <Container className={classes.flexDiv}>
                    <Typography variant="subtitle2" paragraph>This information is updated every 4 hours.</Typography>
                    <Container className={classes.toggleContainer}>
                        {/* Toggle button to select which ranking versions are displayed - TRC [VERSION 2 CURRENTLY DISABLED] */}
                        <ToggleButtonGroup
                            value={rankVersion}
                            exclusive
                            size="small"
                            onChange={handleAlignment}
                            aria-label="Displayed Ranking Version"
                        >
                            <ToggleButton value="1" aria-label="Version 1.0" selected={rankVersion == 1} color="primary">
                                <Typography variant="body" className={classes.toggleText}>Ranking V1.0</Typography>
                            </ToggleButton>
                            <ToggleButton value="2" aria-label="Version 2.0" selected={rankVersion == 2} color="primary" disabled>
                                <Typography variant="body" className={classes.toggleText}><strike>Ranking V2.0</strike></Typography>
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Container>
                </Container>
                <Container className={classes.flexDiv}>
                    <Typography variant="subtitle2" paragraph>Latest Update: {latestUpdate}</Typography>
                    {admin ? (
                        // Display a download button for rankings if the user is an admin
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={downloadData}
                        >
                            Download Ranking Data
                        </Button>
                    ) : null}
                </Container>
                <Typography variant="caption" className={classes.settingsInfo}>Settings:</Typography>
                <Typography variant="caption" className={classes.settingsInfo}>
                    <InlineMath>\alpha</InlineMath>: {settings.alpha}
                </Typography>
                <Typography variant="caption" className={classes.settingsInfo}>
                    <InlineMath>\beta</InlineMath>: {settings.beta}
                </Typography>
                <Typography variant="caption" className={classes.settingsInfo}>
                    <InlineMath>\gamma</InlineMath>: {settings.gamma}
                </Typography>
                <Typography variant="caption" className={classes.settingsInfo}>
                    <InlineMath>\delta</InlineMath>: {settings.delta}
                </Typography>
                <Typography variant="caption" className={classes.settingsInfo}>
                    <InlineMath>c</InlineMath>: {settings.c}
                </Typography>
                <TableContainer component={Paper} className={classes.tableContainer}>

                    {rows.length <= 0 ? <LinearProgress /> : (
                        <Table key="table" className={classes.table} size="medium" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell size="small" align="center" className={classes.header}>Rank</TableCell>
                                    { /* Column header for locking checkboxes, contains master checkbox - TRC */}
                                    <TableCell size="small" align="left" padding="none" className={classes.header}>
                                        <Checkbox className={classes.headerCheck} size="small" checked={masterToggle} onChange={() => handleMasterToggle()} />
                                    </TableCell>
                                    {headers.map((header) => (
                                        <TableCell key={header} className={classes.header} align="center" variant="head">{isPrediction(header) ? header : `${header}*`}</TableCell>
                                    ))}
                                    <TableCell size="small" align="center" className={classes.header}>Rank</TableCell>
                                    <TableCell size="small" align="center" className={classes.header}>Change</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.slice(0, loadSize).map((row, rowIndex) => (
                                    <TableRow size="small" key={row.cweId}>
                                        <TableCell component="th" scope="row" align="center" padding="none">{rowIndex + 1}</TableCell>
                                        { /* Checkbox to allow user to lock a CWE selection - TRC */}
                                        <TableCell size="small" padding="none"><Checkbox size="small" checked={isClicked(row)} onChange={() => handleLockToggle(row)} /></TableCell>
                                        {headers.map((header, colIndex) => (
                                            <TableCell
                                                align="center"
                                                key={header}
                                                className={((row[header] === highlightedCWE) || (lockedCWE.indexOf(row[header]) > -1)) ? classes.cellHighlighted : classes.cell}
                                                onClick={(event) => {
                                                    handleCellClick(row, header, rowIndex, colIndex);
                                                    updateClickedCell(event);
                                                }}
                                                onMouseOver={() => handleCellHover(row, header, rowIndex, colIndex)}
                                                onMouseOut={() => removeHoveredCell()}
                                            >
                                                {selectedCell[0] === rowIndex && selectedCell[1] === colIndex ? (
                                                    <React.Fragment>
                                                        { /* Set selected CWE Bold - TRC */}
                                                        <b>{row[header]}</b>
                                                        <Popover
                                                            className={classes.tooltip}
                                                            open={tooltip}
                                                            onClose={handlePopoverClose}
                                                            anchorEl={popoverAnchor}
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'left'
                                                            }}
                                                        >
                                                            {loadingTooltip ? null : (
                                                                <Box style={{ padding: 10 }}>
                                                                    <Box className={classes.closeButton}>
                                                                        <Close onClick={handlePopoverClose} />
                                                                    </Box>
                                                                    <Box>
                                                                        <Typography style={{ fontSize: 14 }}>CWE ID: {details["CWE ID"]}</Typography>
                                                                        <Typography style={{ fontSize: 14 }}>
                                                                            <a href={`https://cwe.mitre.org/data/definitions/${details["CWE ID"].split('-')[1]}.html`} target="_blank">
                                                                                CWE Details
                                                                                <FontAwesomeIcon icon={faExternalLinkAlt} className={classes.linkIcon} size="xs" />
                                                                            </a>
                                                                        </Typography>
                                                                        { /* Button to allow for user to open CVE composition dialog - TRC */}
                                                                        <Typography style={{ fontSize: 14 }}>
                                                                            <Button variant="contained" size="small" onClick={() => showCWECompositionDialog(true)} className={classes.cveCompBtn}>View CVE Composition</Button>
                                                                        </Typography>
                                                                        <Typography style={{ fontSize: 14 }}>Number of CVEs: {details["# of CVEs"]?.toFixed(0)}</Typography>
                                                                        <Typography style={{ fontSize: 14 }}>Avg Exploitability V2: {details["Avg Exploitability V2"]?.toFixed(2)}</Typography>
                                                                        <Typography style={{ fontSize: 14 }}>Avg Days Since Published: {details["Avg Days Since Published"]?.toFixed(2)}</Typography>
                                                                        <Typography style={{ fontSize: 14 }}>Avg Impact Score V2: {details["Avg Impact Score V2"]?.toFixed(2)}</Typography>
                                                                        <Typography style={{ fontSize: 14 }}>Avg Exposure Factor: {details["Avg Exposure Factor"]?.toFixed(2)}</Typography>
                                                                        <Typography style={{ fontSize: 14 }}>Avg Base Score: {details["Avg Base Score"]?.toFixed(2)}</Typography>
                                                                        <Typography style={{ fontSize: 14 }}>Avg Exploitation Likelihood: {details["Avg Exploitation Likelihood"]?.toFixed(2)}</Typography>
                                                                        <Typography style={{ fontSize: 14 }}>Known IDS Rules: {details["Known IDS Rules"]}</Typography>
                                                                        <Typography style={{ fontSize: 14 }}>Deployed IDS Rules: {details["Deployed IDS Rules"]}</Typography>
                                                                        <Typography style={{ fontSize: 14 }}>Overall Score: {details["Our Overall Score"]?.toFixed(0)}</Typography>

                                                                    </Box>
                                                                </Box>
                                                            )
                                                            }
                                                        </Popover>
                                                    </React.Fragment>
                                                ) : row[header]}
                                            </TableCell>
                                        ))}
                                        { /* Display rank once more */}
                                        <TableCell size="small" align="center">{rowIndex + 1}</TableCell>
                                        { /* Display ranking change of CWE over currently displayed timeframe */}
                                        <TableCell size="small" align="center">
                                            {row["Change"]}
                                            {row["Change"] < 0 ? <ArrowDownwardIcon className={classes.downIcon} /> : (row["Change"] > 0 ? <ArrowUpwardIcon className={classes.upIcon} /> : null)}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    )}

                </TableContainer>
                <Grid container spacing={3}>
                    <Grid item xs={2}>
                        <IconButton className={classes.leftScrollButton} onClick={() => window.scrollTo(0, 0)}>
                            <ArrowUpwardIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={8} style={{ justifyContent: "center" }}>
                        <Button variant="outlined" color="primary" className={classes.loadMoreButton} onClick={() => setLoadSize(loadSize + 25)} disabled={loadSize >=
                            maxRowSize}>Load More</Button>
                    </Grid>
                    <Grid item xs={2} style={{ display: "flex", justifyContent: "flex-end" }}>
                        <IconButton className={classes.rightScrollButton} onClick={() => window.scrollTo(0, 0)}>
                            <ArrowUpwardIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Container>
            <Footer />
            { /* Declare Various Dialogs, These are only opened when their 'visible' variable is true - TRC */}
            <InputDialog 
                visible={inputDialog} 
                rankingDialog={showRankingDialog} 
                onClose={showInputDialog} 
                setRanking={setRanking} 
                setDates={setDates} 
            />
            <RankingDialog 
                visible={rankingDialog} 
                onClose={showRankingDialog} 
                ranking={ranking} 
                dates={dates} 
            />
            <YearSelectionDialog 
                visible={yearSelectionDialog} 
                onClose={showYearSelectionDialog}
            />
            {CWECompositionDialog ? (
                <CWECompDialog
                    visible={true}
                    onClose={showCWECompositionDialog}
                    CWEID={selectedCWE}
                    startYear={headers[selectedCell[1]].split("-")[0]}
                    endYear={headers[selectedCell[1]] + "-01"}
                />
            ) : null}
        </Box>
    );
}