import React, { useEffect, useState } from 'react';
import { API_URL } from '../settings';
import authHeader from '../services/authHeader';
import axios from 'axios';
import {
    Dialog, AppBar, Toolbar, IconButton, Typography, makeStyles, Slide, Button, Backdrop,
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import * as fDate from '../services/dateFormatter.js'
import '../fonts/font.css';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
        width: '100%',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    dialogBody: {
        height: '100%',
        width: '100%',
        margin: 'none',
    },
    tableContainer: {
        marginBottom: "1%",
        marginTop: "1%",
        margin: "auto",
        maxWidth: "90%",
        backgroundColor: "#bdbdbd",
    },
    header: {
        fontWeight: "bold",
        textAlign: "center",
        fontSize: "90%",
    },
    centeredCell: {
        fontFamily: "Barlow",
        fontSize: 14,
        padding: 3,
        paddingTop: '10px',
        paddingBottom: '10px',
        whiteSpace: 'nowrap',
        textAlign: 'center',
        justifyContent: "center",
        backgroundColor: "#f5f5f5",
        fontSize: "100%",
    },
    centeredCellRed: {
        fontFamily: "Barlow",
        fontSize: 14,
        padding: 3,
        paddingTop: '10px',
        paddingBottom: '10px',
        whiteSpace: 'nowrap',
        textAlign: 'center',
        justifyContent: "center",
        backgroundColor: "#ffcdd2",
    },
    centeredCellGreen: {
        fontFamily: "Barlow",
        fontSize: 14,
        padding: 3,
        paddingTop: '10px',
        paddingBottom: '10px',
        whiteSpace: 'nowrap',
        textAlign: 'center',
        justifyContent: "center",
        backgroundColor: "#c8e6c9",
    },
    centeredCellOrange: {
        fontFamily: "Barlow",
        fontSize: 14,
        padding: 3,
        paddingTop: '10px',
        paddingBottom: '10px',
        whiteSpace: 'nowrap',
        textAlign: 'center',
        justifyContent: "center",
        backgroundColor: "#ffe0b2",
    },
    centeredCellYellow: {
        fontFamily: "Barlow",
        fontSize: 14,
        padding: 3,
        paddingTop: '10px',
        paddingBottom: '10px',
        whiteSpace: 'nowrap',
        textAlign: 'center',
        justifyContent: "center",
        backgroundColor: "#fff9c4",
    },
    loadMoreButton: {
        width: "20%",
        margin: "auto",
        marginTop: "10px"
    },
    backdrop: {
        zIndex: 10
    },
}))

// Simple slide up transition - TRC
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Composition dialog showing the composition of the passed in CWE - TRC
 * 
 * @author Thomas Campbell
 * @editor Kaan Turkmen
 * @param {*} props All required prop information {listed below}
 *      visible: Boolean controlling if the dialog is open or not
 *      onClose: Function callback onClose
 *      CWEID: The ID if the selected CWE
 *      startYear: Starting year of timeframe
 *      endYear: Ending year of timeframe
 * @returns An instance of a CWE Composition Card
 */
export default function CWECompDialog(props) {
    const classes = useStyles();
    // Number of cves to be loaded in dialog - TRC
    const [loadSize, setLoadSize] = useState(50);
    // Ranking List of CVE's - TRC
    const [ranking, setRanking] = useState([]);
    // Max number of rows to display (Also corresponds to number of CVEs) - TRC
    const [cveNum, setCVENum] = useState(0);
    // Control display of a circular progress loading overlay - TRC
    const [backdrop, toggleBackdrop] = useState(true);
    
    // Convert the retrieved information to a list of objects for better manipulation - TRC
    function calcComposition(obj) {
        const objList = [];
        for(let i = 0; i < obj["CVE ID"].length; i++) {
            let out = {};
            out["CVE ID"] = obj["CVE ID"][i];
            out["Attack Vector"] = obj["Attack Vector"][i];
            out["Publish Date"] = obj["Published Date"][i];
            out["Days Since Published"] = obj["Days Since Published"][i];
            out["Exploitation Likelihood"] = obj["Exploitation Likelihood"][i];
            out["Exploitability"] = obj["Exploitability"][i];
            out["Exposure Factor"] = obj["Exposure Factor"][i];
            out["Impact Score"] = obj["Impact Score"][i];
            out["Overall Score"] = obj["Overall Score"][i];

            objList.push(out);
        }
        
        // Sort the object list by MVSF Score
        objList.sort(function(a, b) {
            var source = a["Overall Score"];
            var target = b["Overall Score"];
            if (source < target) {
              return -1;
            }
            if (source > target) {
              return 1;
            }

            // Values must be Equal
            return 0;
        });

        // Reverse the order to show the highest MVSF scores first
        setRanking(objList.reverse());
    }

    // Set the color based off of MVSF rating [FOR BACKGROUNDS] - TRC
    function getColorBackground(score) {
        if (score <= 0.39) return classes.centeredCellGreen;
        else if (score <= 0.69) return classes.centeredCellYellow;
        else if (score <= 0.89) return classes.centeredCellOrange;
        else return classes.centeredCellRed;
    }

    // Update CVE Num when ranking is updated - TRC
    useEffect(() => {
        setCVENum(ranking.length);
    }, [ranking])

    // Initial data grab once the page is loaded - TRC
    useEffect(() => {
        axios.get(API_URL + '/get/' + props.CWEID + '/' + props.endYear, { headers: authHeader() })
        .then(res => {
            let data = res.data;

            calcComposition(data);
            // Turn off loading overlay and backdrop when loaded - TRC
            toggleBackdrop(false);
        })
        .catch(err => console.log("ERROR LOG: \n" + err));
    }, []);

    // Master Return Statement - TRC
    return (
        <Dialog maxWidth="xl" fullWidth open={props.visible} onClose={() => props.onClose(false)} TransitionComponent={Transition}>
            <Backdrop className={classes.backdrop} open={backdrop}>
                <CircularProgress />
            </Backdrop>
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={() => props.onClose(false)} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        CVE Composition of {props.CWEID} ({props.endYear.substring(0, props.endYear.length - 3)})
                    </Typography>
                </Toolbar>
            </AppBar>
            <TableContainer component={Paper} className={classes.tableContainer}>
                <Table className={classes.table} aria-label="historical-ranking" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.header} variant="head">Index</TableCell>
                            <TableCell className={classes.header} variant="head">CVE ID</TableCell>
                            <TableCell className={classes.header} variant="head">Attack Vector</TableCell>
                            <TableCell className={classes.header} variant="head">Publish Date</TableCell>
                            <TableCell className={classes.header} variant="head">Days Since Published</TableCell>
                            <TableCell className={classes.header} variant="head">Exploitation Likelihood</TableCell>
                            <TableCell className={classes.header} variant="head">Exploitability Score</TableCell>
                            <TableCell className={classes.header} variant="head">Exposure Factor</TableCell>
                            <TableCell className={classes.header} variant="head">Impact Score</TableCell>
                            <TableCell className={classes.header} variant="head">MVSF Overall Score</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ranking ? ranking.slice(0, loadSize).map((rank) => (
                            <TableRow>
                                <TableCell className={classes.centeredCell}>{ranking.indexOf(rank) + 1}</TableCell>
                                <TableCell className={classes.centeredCell}>{rank["CVE ID"]}</TableCell>
                                <TableCell className={classes.centeredCell}><i>{rank["Attack Vector"]}</i></TableCell>
                                <TableCell className={classes.centeredCell}>{rank["Publish Date"]}</TableCell>
                                <TableCell className={classes.centeredCell}>{rank["Days Since Published"]}</TableCell>
                                <TableCell className={classes.centeredCell}>{rank["Exploitation Likelihood"].toFixed(2)}</TableCell>
                                <TableCell className={classes.centeredCell}>{rank["Exploitability"]}</TableCell>
                                <TableCell className={classes.centeredCell}>{rank["Exposure Factor"].toFixed(2)}</TableCell>
                                <TableCell className={classes.centeredCell}>{rank["Impact Score"]}</TableCell>
                                <TableCell className={getColorBackground(rank["Overall Score"].toFixed(2))}>{rank["Overall Score"].toFixed(2)}</TableCell>
                            </TableRow>
                        )) : null}
                    </TableBody>
                </Table>
            </TableContainer>
            <Typography align='center' variant="subtitle1"><i>Currently displaying: <b>{loadSize}</b> CVE's out of {cveNum}</i></Typography>
            <Button variant="outlined" color="primary" className={classes.loadMoreButton} onClick={() => setLoadSize(loadSize + 100)} disabled={loadSize >= cveNum}>Load More</Button>
            <Button variant="outlined" color="primary" className={classes.loadMoreButton} onClick={() => setLoadSize(cveNum)} disabled={loadSize >= cveNum}>Load ALL</Button>
            <br />
        </Dialog>
    )
}