import React, { useEffect, useState } from 'react';
import { API_URL, TOTAL_HF_HEIGHT } from '../settings';
import authHeader from '../services/authHeader';
import {
    Divider, IconButton, Container, makeStyles, Paper, Slide,
    Typography, Card, CardContent, CardActions, CardHeader, Collapse, Checkbox
} from '@material-ui/core';
import clsx from 'clsx';
import * as fDate from '../services/dateFormatter.js';
import BugReportIcon from '@material-ui/icons/BugReport';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import '../fonts/font.css';

const useStyles = makeStyles((theme) => ({
    resultCard: {
        width: "80%",
        margin: "auto",
    },
    cardHeader: {
        padding: "5px",
        paddingBottom: "0px",
    },
    cardActions: {
        padding: "0px",
    },
    cveInfo: {
        fontFamily: "Barlow"
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        padding: "5px",
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    expandBody: {
        paddingTop: "0px",
    },
    expandContent: {
        paddingTop: "0px",
        display: "flex",
    },
    expandColumn: {
        paddingTop: "5px",
    },
    expandFooter: {
        width: "95%",
        fontFamily: "Barlow",
    },
    checkbox: {
        margin: "0px",
        padding: "0px",
    },
    redIcon: {
        color: "#f44336"
    },
    greenIcon: {
        color: "#4caf50"
    },
    orangeIcon: {
        color: "#ff9800"
    },
    yellowIcon: {
        color: "#ffeb3b"
    },
    primaryIcon: {
        color: "#3f51b5"
    },
    redBackgroundTint: {
        borderRadius: "10px",
        padding: "0.3rem",
        backgroundColor: "#ffcdd2",
    },
    greenBackgroundTint: {
        borderRadius: "10px",
        padding: "0.3rem",
        backgroundColor: "#c8e6c9",
    },
    orangeBackgroundTint: {
        borderRadius: "10px",
        padding: "0.3rem",
        backgroundColor: "#ffe0b2",
    },
    yellowBackgroundTint: {
        borderRadius: "10px",
        padding: "0.3rem",
        backgroundColor: "#fff9c4",
    }
}))

// Default slide transition (up) - TRC
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Create a result card for the CVE Search feature - TRC
 * 
 * @author Thomas Campbell
 * @editor Kaan Turkmen
 * @param {*} props All required prop information {listed below}
 *      cveID: CVE ID
 *      likelihood: CVE Likelihood Score
 *      impact: CVE Impact Score
 *      MVSF: CVE MVSF Rating
 *      CWElist: List of CWEs containing this CVE
 *      baseScore: Base Score of this CVE
 *      daysSincePub: Days since this CVE was published
 *      exploitability: CVE Exploitability Score
 *      dIDSrules: Count for CVE Deployed IDS Rules
 *      kIDSrules: Count for CVE Known IDS Rules
 *      exposureFactor: CVE Exposure Factor Score
 *      pubDate: CVE Publication Date
 * @returns An instance of a CVE Search Result Card
 */
export default function CVEResultCard(props) {
    const classes = useStyles();
    // Control expansion of card - TRC
    const [expanded, setExpanded] = useState(false);
    // Control what rating the CVE is - TRC
    const [ratingText, setRatingText] = useState("NONE");
    const [ratingColor, setRatingColor] = useState("primary");

    // Card title and subtitle declaration and formatting - TRC
    let cardTitle = (<Typography variant="subtitle1" className={classes.cveInfo}>{props.cveID}</Typography>);
    let subTitle = (
        <React.Fragment>
            <Typography variant="subtitle2">
                <span className={getColorBackground()}><b>MVSF Score: {props.MVSF.toFixed(2)}</b>{'\t'}
                | Rating: {ratingText}</span>
            </Typography>
        </React.Fragment>);

    // Handle expansion of the card on click - TRC
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    // Convert a list to a string with specific formatting - TRC
    function listToString(list) {
        let out = "";
        for(let i = 0; i < list.length; i++) {
            out += list[i];
            if(i + 1 != list.length) {
                out += ", ";
            }
        }
        return out;
    }
    
    // Set the color of the icon based off of MVSF rating - KT
    function getColor() {
        if (ratingColor === "red") return classes.redIcon;
        else if (ratingColor === "green") return classes.greenIcon;
        else if (ratingColor === "orange") return classes.orangeIcon;
        else if (ratingColor === "yellow") return classes.yellowIcon;
        else return classes.primaryIcon;
    }

    // Set the color based off of MVSF rating [FOR BACKGROUNDS] - TRC
    function getColorBackground() {
        if (ratingColor === "red") return classes.redBackgroundTint;
        else if (ratingColor === "green") return classes.greenBackgroundTint;
        else if (ratingColor === "orange") return classes.orangeBackgroundTint;
        else if (ratingColor === "yellow") return classes.yellowBackgroundTint;
        else return null;
    }

    // Calculate the rating color and text depending on the MVSF score passed in - TRC
    // Colors are calcualted here to avoid using a materialUI theme, this could be changed in the future if needed - TRC
    function calcRating() {
        let score = props.MVSF;
        if(score < 0.01) {
            setRatingText("NONE");
            setRatingColor("primary");
        } else if(score <= 0.39) {
            setRatingText("LOW");
            setRatingColor("green");
        } else if(score <= 0.69) {
            setRatingText("MEDIUM");
            setRatingColor("yellow");
        } else if(score <= 0.89) {
            setRatingText("HIGH");
            setRatingColor("orange");
        } else {
            setRatingText("CRITICAL");
            setRatingColor("red");
        }
    }

    // Calculate rating and color when card is created. - TRC
    useEffect(() => {
        calcRating();
    }, [])

    // Master return statement - TRC
    return (
        <Slide direction="up" in={true}>
            <Card component={Paper} elevation={0} variant="outlined" className={classes.resultCard}>
                <CardHeader
                    className={classes.cardHeader}
                    avatar={<BugReportIcon className={getColor()} fontSize="large" />}
                    title={cardTitle}
                    subheader={subTitle}
                />
                <CardActions className={classes.cardActions}>
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded,
                        })}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                {   
                    /*
                    BELOW ARE THE PASSED IN PROPS FOR THE CARD - TRC
                        cveID: CVE ID
                        likelihood: CVE Likelihood Score
                        impact: CVE Impact Score
                        MVSF: CVE MVSF Rating
                        CWElist: List of CWEs containing this CVE
                        baseScore: Base Score of this CVE
                        daysSincePub: Days since this CVE was published
                        exploitability: CVE Exploitability Score
                        dIDSrules: Count for CVE Deployed IDS Rules
                        kIDSrules: Count for CVE Known IDS Rules
                        exposureFactor: CVE Exposure Factor Score
                        pubDate: CVE Publication Date
                    */
                }
                    <CardContent className={classes.expandBody}>
                        <Typography variant="subtitle1" className={classes.cveInfo} align="center">CVE Data:</Typography>
                        <Typography variant="subtitle2" className={classes.cveInfo} align="center">CVE ID: <b>{props.cveID}</b></Typography>
                        <Container className={classes.expandContent}>
                            <Container className={classes.expandColumn}>
                                <Typography variant="subtitle2" className={classes.cveInfo}>CWE Category: <b>{listToString(props.CWElist)}</b></Typography>
                                <Typography variant="subtitle2" className={classes.cveInfo}>Base Score: <b>{props.baseScore.toFixed(2)}</b></Typography>
                                <Typography variant="subtitle2" className={classes.cveInfo}>Exploitability Score: <b>{props.exploitability.toFixed(2)}</b></Typography>
                                <Typography variant="subtitle2" className={classes.cveInfo}>Impact Score: <b>{props.impact.toFixed(2)}</b></Typography>
                            </Container>
                            <Container className={classes.expandColumn}>
                                <Typography variant="subtitle2" className={classes.cveInfo}>Publication Date: <b>{props.pubDate}</b></Typography>
                                <Typography variant="subtitle2" className={classes.cveInfo}>Days Since Publication: <b>{fDate.daysToYears(props.daysSincePub)}</b></Typography>
                                <Typography variant="subtitle2" className={classes.cveInfo}>Known IDS Rules: <b>{props.kIDSrules}</b></Typography>
                            </Container>
                        </Container>
                        <Container className={classes.expandFooter}>
                            <Typography variant="subtitle1" className={classes.cveInfo} align="center">CVE Metrics:</Typography>
                            <Typography variant="subtitle2" className={classes.cveInfo} align="center">Exploitation Likelihood: <b>{props.likelihood.toFixed(2)}</b></Typography>
                            <Typography variant="subtitle2" className={classes.cveInfo} align="center">Exposure Factor: <b>{props.exposureFactor.toFixed(2)}</b></Typography>
                            <Divider />
                            <br />
                            { /* Color results based off of severity score - TRC */ }
                            <Typography variant="h6" className={classes.cveInfo} align="center">
                                <span className={getColorBackground()}>Overall MVSF Score: <b>{props.MVSF.toFixed(2)}</b></span>
                            </Typography>
                        </Container>
                    </CardContent>
                </Collapse>
            </Card>
        </Slide>
    )
}