import React, { useEffect, useState } from 'react';
import { API_URL, TOTAL_HF_HEIGHT } from '../settings';
import authHeader from '../services/authHeader';
import {
    Divider, Button, IconButton, Container, makeStyles, Paper, Slide,
    Typography, Card, CardContent, CardActions, CardHeader, Collapse, Checkbox,
    Dialog, DialogTitle, DialogContent, DialogActions
} from '@material-ui/core';
import clsx from 'clsx';
import * as fDate from '../services/dateFormatter.js';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import '../fonts/font.css';
import { setAdmin } from '../services/adminPanel';

const useStyles = makeStyles((theme) => ({
    userCard: {
        width: "100%",
        margin: "5px",
    },
    cardHeader: {
        padding: "5px",
        paddingBottom: "0px",
    },
    cardActions: {
        padding: "0px",
    },
    userInfo: {
        fontFamily: "Barlow"
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        padding: "5px",
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    expandBody: {
        paddingTop: "0px",
    },
    expandContent: {
        paddingTop: "0px",
        display: "flex",
    },
    expandColumn: {
        paddingTop: "5px",
    },
    expandFooter: {
        width: "95%",
    },
    checkbox: {
        margin: "0px",
        padding: "0px",
    },
}))

// Upward slide transition - TRC
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * Create a card representing a User containing all of their information - TRC
 * 
 * @author Thomas Campbell
 * @editor Kaan Turkmen
 * @param {*} props All required prop information {listed below}
 *      ID: User's ID
        username: User's Fullname
        fullname: User's Fullname
        email: User's Email
        role: User's Role
        affiliation: User's Affiliation
        admin: Boolean representing if the user is an admin or not
        joinDate: Date corresponding to the day that the user finalized creation of their account
 * @returns An instance of an Account Card
 */
export default function AdminUserCard(props) {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const date = new Date(props.joinDate['$date']);
    // Email link (HTML) - TRC
    const emailHtml = ("mailto:" + props.email);
    // Admin Rights Confirmation Dialog Controller -TRC
    const [showConfirm, toggleConfirm] = useState(false);
    // The pending admin state (FOR WHEN TOGGLING ADMIN) - TRC
    const [pendingState, setPendingState] = useState(null);

    // Card title information (seperated for readsbility) - TRC
    let cardTitle = ((props.fullname == "") ? 
                        (<Typography variant="subtitle1" className={classes.userInfo} color="secondary">NAMELESS USER - USERNAME: "{props.username}"</Typography>):
                        (<Typography variant="subtitle1" className={classes.userInfo}>{props.fullname}</Typography>)
                    );
    let subTitle = (<Typography variant="subtitle2" >User ID: {props.ID}</Typography>);

    // Handle expansion of card - TRC
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    // Handle toggling the admin checkbox - TRC
    function handleAdminToggle(e) {
        setPendingState(e.target.checked);
        toggleConfirm(true);
    }

    // Handle the closure of the "Admin Confirm" dialog - TRC
    function handleAdminConfirmClose(decision) {
        // Check if accepted or cancelled
        if(decision) {
            // SET ADMIN VALUE ON BACKEND TO EQUAL "pendingState" THROUGH BACKEND CALL
            setAdmin(props.ID, pendingState)
            .then(res => console.log(res))
            .catch(err => console.log(err));

            window.location.reload(false);
        }
        // Clear pending state and close dialog box - TRC
        setPendingState(null);
        toggleConfirm(false);
    }

    // Build confirmation dialog for Ordaining Admin Rights - TRC
    function buildAdminConfirm() {
        return (
            <Dialog
                maxWidth="sm"
                fullWidth
                open={showConfirm}
                TransitionComponent={Transition}
            >
                <DialogTitle>
                    { pendingState ? (
                        <Typography className={classes.useBarlow} variant="h5" align="left">Give "<b>{props.username.toUpperCase()}</b>" admin rights?</Typography>
                    ) : (
                        <Typography className={classes.useBarlow} variant="h5" align="left">Revoke "<b>{props.username.toUpperCase()}</b>" admin rights?</Typography>
                    )}
                </DialogTitle>
                <DialogContent dividers>
                    <Typography variant="subtitle2" align="left">Admin rights will give the user FULL control to this admin panel and all users! This is the FINAL warning before changing.</Typography>
                    <Typography variant="subtitle2" align="left"><b>Please keep in mind that admins can:</b></Typography>
                    <Typography variant="subtitle2" align="left"> - Give and revoke a user's admin rights</Typography>
                    <Typography variant="subtitle2" align="left"> - Accept and deny account requests</Typography>
                    <Typography variant="subtitle2" align="left"> - Accept and deny info change requests</Typography>
                    <Typography variant="subtitle2" align="left"> - Resend emails to users</Typography>
                    <Typography variant="subtitle2" align="left"> - Access user information</Typography>
                </DialogContent>
                <DialogActions>
                    { pendingState ? (
                        <Button onClick={() => handleAdminConfirmClose(true)} variant="contained" color="primary"><b>Confirm ENABLE</b></Button>
                    ) : (
                        <Button onClick={() => handleAdminConfirmClose(true)} variant="contained" color="primary"><b>Confirm DISABLE</b></Button>
                    )}
                    <Button onClick={() => handleAdminConfirmClose(false)} variant="contained" color="secondary"><b>Cancel</b></Button>
                </DialogActions>
            </Dialog>
        )
    }

    // Master return statement - TRC
    return (
        <Slide direction="up" in={true}>
            <Card component={Paper} elevation={0} variant="outlined" className={classes.userCard}>
                <CardHeader
                    className={classes.cardHeader}
                    avatar={(props.admin == true) ? ( <AccountBoxIcon color="secondary" fontSize="large" /> ):( <AccountBoxIcon color="primary" fontSize="large" /> )}
                    title={cardTitle}
                    subheader={subTitle}
                />
                <CardActions className={classes.cardActions}>
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded,
                        })}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent className={classes.expandBody}>
                        <Typography variant="subtitle1" className={classes.userInfo} align="center">User Information:</Typography>
                        <Typography variant="subtitle2" className={classes.userInfo} align="center">User ID: <b>{props.ID}</b></Typography>
                        <Container className={classes.expandContent}>
                            <Container className={classes.expandColumn}>
                                <Typography variant="subtitle2" className={classes.userInfo}>Username: <b>{props.username}</b></Typography>
                                <Typography variant="subtitle2" className={classes.userInfo}>Full Name: <b>{props.fullname}</b></Typography>
                                <Typography variant="subtitle2" className={classes.userInfo}>Email: <b><a href={emailHtml}>{props.email}</a></b></Typography>
                            </Container>
                            <Container className={classes.expandColumn}>
                                <Typography variant="subtitle2" className={classes.userInfo}>Role: <b>{props.role}</b></Typography>
                                <Typography variant="subtitle2" className={classes.userInfo}>Affiliation: <b>{props.affiliation}</b></Typography>
                                <Typography variant="subtitle2" className={classes.userInfo}>Account Lifespan: <b>{fDate.getTimeSinceYearsDays(date)}</b></Typography>
                            </Container>
                        </Container>
                        <Divider />
                        <Container className={classes.expandFooter}>
                            <Typography variant="subtitle2" className={classes.userInfo} align="center">Member Since: <b>{date.toString()}</b></Typography>
                            <Typography variant="subtitle2" className={classes.userInfo} align="center">Admin Permissions: <Checkbox className={classes.checkbox} size="small" checked={props.admin} onChange={handleAdminToggle}/></Typography>
                        </Container>
                    </CardContent>
                </Collapse>
                { 
                    // Build Admin Confirm Dialog
                    buildAdminConfirm()
                }
            </Card>
        </Slide>
    )
}