import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, FormControlLabel, Switch, FormControl, InputAdornment, FormHelperText, OutlinedInput, makeStyles } from '@material-ui/core';
import { InlineMath, BlockMath } from 'react-katex';
// import { SettingsContext } from '../contexts/SettingsContext'; Commented out to fix warnings in console - TRC
import '../fonts/font.css'; // Barlow font import - TRC

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%"
    },
    container: {
        width: "100%"
    },
    gridTitle: {
        fontWeight: "bold",
        margin: "2%",
        marginBottom: "3%",
        fontFamily: "Barlow",
    },
    paper: {
        padding: theme.spacing(2),
        height: "100%",
        textAlign: "center",
        marginLeft: "20%",
        marginRight: "20%"
    },
    control: {
        margin: "1%"
    },
    defaultSwitch: {
        margin: "2%"
    },
    input: {
        width: 125
    },

}));

/**
 * Create an Likelihood Settings element onscreen
 * 
 * @author Kaan Turkmen
 * @editor Thomas Campbell
 * @comments Thomas Campbell
 * @param {*} props All required prop information {listed below}
 *      alpha: Alpha Value Variable
 *      beta: Beta Value Variable
 *      gamma: Gamma Value Variable
 *      setAlpha: Alpha Setter Callback
 *      setBeta: Beta Setter Callback
 *      setGamma: Gamma Setter Callback
 *      setWarning: Warning Status Setter Callback
 *      setSuccess: Success Status Setter Callback
 * @returns Likelihood settings onscreen
 */
export default function LikelihoodSettings(props) {
    const classes = useStyles();
    // const settings = useContext(SettingsContext); Commented out to fix warnings in console - TRC
    const [likelihoodDefaults, toggleLikelihoodDefaults] = useState(true);

    // Event Callbacks for Setting using Props - Comment: TRC, Author: KT
    const onAlphaUpdate = (e) => props.setAlpha(e.target.value);
    const onBetaUpdate = (e) => props.setBeta(e.target.value);
    const onGammaUpdate = (e) => props.setGamma(e.target.value);

    // UseEffect for toggle of default usage - Comment: TRC, Author: KT
    useEffect(() => {
        if (likelihoodDefaults) {
            props.setAlpha(0.75);
            props.setBeta(0.25);
            props.setGamma(0);
        }
    }, [likelihoodDefaults]);

    // Master Return Statement - Comment: TRC, Author: KT
    return (
        <Paper className={classes.paper} elevation={3}>
            <Box>
                <Typography variant="subtitle1" align="center" className={classes.gridTitle}>Exploitation Likelihood</Typography>
            </Box>
            <Box>
                <BlockMath math="\!\rho(v) \! = \! \frac{\left(1 \! -e^{-\alpha \cdot \sqrt{t(v)}}\right) \! \cdot \! \left(1 \! -e^{-\beta \cdot Exploitability(v)}\right)}{e^{\gamma \cdot |IDS_k(v)|}}\!\!\!" />
            </Box>
            <Box className={classes.defaultSwitch}>
                <FormControlLabel
                    control={<Switch color="primary" checked={likelihoodDefaults} onChange={() => toggleLikelihoodDefaults(!likelihoodDefaults)} name="likelihoodSwitch" />}
                    label="Use default values"
                />
            </Box>
            <Box>
                <FormControl className={classes.control}>
                    <OutlinedInput
                        id="standard-adornment-weight"
                        onChange={onAlphaUpdate}
                        startAdornment={<InputAdornment position="start"><InlineMath>\alpha</InlineMath></InputAdornment>}
                        aria-describedby="standard-weight-helper-text"
                        inputProps={{
                            'aria-label': 'weight',
                        }}
                        disabled={likelihoodDefaults}
                        value={props.alpha}
                        margin="dense"
                        className={classes.input}
                    />
                    <FormHelperText id="standard-weight-helper-text">{"[0, +∞)"}</FormHelperText>
                </FormControl>
                <FormControl className={classes.control}>
                    <OutlinedInput
                        id="standard-adornment-weight"
                        onChange={onBetaUpdate}
                        startAdornment={<InputAdornment position="start"><InlineMath>\beta</InlineMath></InputAdornment>}
                        aria-describedby="standard-weight-helper-text"
                        inputProps={{
                            'aria-label': 'weight',
                        }}
                        disabled={likelihoodDefaults}
                        value={props.beta}
                        margin="dense"
                        className={classes.input}
                    />
                    <FormHelperText id="standard-weight-helper-text">{"[0, +∞)"}</FormHelperText>
                </FormControl>
                <FormControl className={classes.control}>
                    <OutlinedInput
                        id="standard-adornment-weight"
                        onChange={onGammaUpdate}
                        startAdornment={<InputAdornment position="start"><InlineMath>\gamma</InlineMath></InputAdornment>}
                        aria-describedby="standard-weight-helper-text"
                        inputProps={{
                            'aria-label': 'weight',
                        }}
                        disabled={likelihoodDefaults}
                        value={props.gamma}
                        margin="dense"
                        className={classes.input}
                    />
                    <FormHelperText id="standard-weight-helper-text">{"[0, 1]"}</FormHelperText>
                </FormControl>
            </Box>
        </Paper>
    )
}