import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Container, Typography, Grid, Button, Divider } from '@material-ui/core';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import BibTexDialog from '../components/BibTexDialog';
import masonLogo from '../images/mason-logo.png';
import darpaLogo from '../images/darpa-logo.png';
import csisLogo from '../images/csis-logo.png';
import parcLogo from '../images/parc-logo.png';
import mvsfLogoWhite from '../images/mvsf-logo-white.png';
import ReactPlayer from 'react-player/youtube';
import { contactURL } from '../urls';
import '../fonts/font.css';
import { InlineMath, BlockMath } from 'react-katex';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    container: {
        width: "100%",
    },
    content: {
        width: "84%",
        // backgroundColor: "yellow"
    },
    title: {
        fontWeight: "bold",
        marginTop: "3%",
        marginBottom: "3%",
        fontSize: 26,
        fontFamily: "Barlow",
    },
    subtitle: {
        fontWeight: "bold",
        marginBottom: "2%"
    },
    tempText: {
        fontStyle: "italic"
    },
    logoContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "4%",
        marginTop: '4%',
    },
    logoText: {
        fontFamily: "Barlow",
        fontWeight: "bold",
    },
    videoContainer: {
        display: 'flex',
        justifyContent: 'center',
        height: '540px',
        marginTop: '5%',
        marginBottom: '5%'
    }
}));

/**
 * The about page located at: https://mvsf.gmu.edu/about
 * 
 * @comments Thomas Campbell
 * @param {*} props
 *      history: Browser history
 * @returns About page
 */
export default function About(props) {
    const classes = useStyles();
    const [bibtexDialog, toggleBibtexDialog] = useState(false)
    const [bibtex, setBibtex] = useState("")

    const openDialog = (articleId) => {
        if (articleId === 1) {
            setBibtex(`@article{iganibo2021vulnerability,
                title={Vulnerability Metrics for Graph-based Configuration Security},
                author={Iganibo, Ibifubara and Albanese, Massimiliano and Mosko, Marc and Bier, Eric and Brito, Alejandro E},
                year={2021}
              }`)
        } else if (articleId === 2) {
            setBibtex(`@inproceedings{soroush2020sciborg,
                title={SCIBORG: Secure Configurations for the IoT Based on optimization and Reasoning on Graphs},
                author={Soroush, Hamed and Albanese, Massimiliano and Mehrabadi, Milad Asgari and Iganibo, Ibifubara and Mosko, Marc and Gao, Jason H and Fritz, David J and Rane, Shantanu and Bier, Eric},
                booktitle={2020 IEEE Conference on Communications and Network Security (CNS)},
                pages={1--10},
                year={2020},
                organization={IEEE}
              }`)
        }
        toggleBibtexDialog(true);
    }

    return (
        <Box className={classes.root}>
            <Navbar>
                <Button color="inherit" onClick={() => props.history.push(contactURL)}>Contact Us</Button>
            </Navbar>
            <Container className={classes.container} disableGutters>
                <Typography variant="h5" align="center" className={classes.title} paragraph>About</Typography>
                <hr />
                <Container className={classes.content}>
                    <Container className={classes.logoContainer}>
                        <Typography variant="h3" className={classes.logoText}>MVSF</Typography>
                        <Typography variant="h6" className={classes.logoText}>Mason Vulnerability Scoring Framework</Typography>
                    </Container>
                    <Typography paragraph>
                        The Mason Vulnerability Scoring Framework is a web application that publishes monthly rankings of all <a href="https://cwe.mitre.org" target="_blank">Common Weaknesses Enumeration</a> (CWE) categories and allows users to generate custom rankings by tuning several parameters used in the calculation of CWE scores. Mason CWE Scores rely on vulnerability metrics [1] developed in collaboration with <a href="https://www.parc.com" target="_blank">Palo Alto Research Center</a> (PARC), as part of the SCIBORG project [2], within the context of the <a href="https://www.darpa.mil/program/configuration-security" target="_blank">DARPA Configuration Security</a> (ConSec) program.
                    </Typography>
                    <Typography paragraph>
                        Rankings generated by the Mason Vulnerability Scoring Framework have been shown to exhibit high correlation with MITRE’s yearly rankings of the <a href="https://cwe.mitre.org/" target="_blank">CWE Top 25 Most Dangerous Software Weaknesses</a>, but are updated monthly using current data about Common Vulnerabilities and Exposures (CVE) from the <a href="https://nvd.nist.gov/" target="_blank">National Vulnerability Database</a> (NVD), and are not limited to the top 25 CWEs. The tool has an intuitive graphical interface that allows the user to visualize trends in the severity of individual CWEs. The tool also allows users to generate custom rankings by fine-tuning several parameters that are used in the calculation of the underlying CVE-level vulnerability metrics and overall CWE scores.
                    </Typography>
                    {/* <Typography className={classes.tempText} paragraph>[Paragraph to be added once the video is available. Alternatively, the video could be embedded into the page.]</Typography> */}
                    <Typography paragraph>For a demonstration of how to use the Mason Vulnerability Scoring Framework, please watch this video.</Typography>
                    <Container className={classes.videoContainer}>
                        <ReactPlayer url='https://www.youtube.com/watch?v=LCt3u2DnaIA' width='100%' height='100%' />
                    </Container>
                    <Typography className={classes.subtitle} variant="subtitle1">Technical Details</Typography>
                    <Typography paragraph>
                        To compute the MVSF CVE score we multiply the <i>Exploitation Likelihood</i> score and the <i>Exposure Factor</i> score of that CVE, and to compute the overall score of a CWE, 
                        we first compute two scores for each CVE in that CWE category, namely its <i>exploitation likelihood</i> and its <i>exposure factor</i>. The overall score of a CWE is then computed 
                        as the product of the number of vulnerabilities mapped to that CWE, their average likelihood, and their average exposure factor. Differently from MITRE’s score, it is not normalized, 
                        but this is irrelevant for the purpose of ranking CWEs.
                    </Typography>
                    <Typography paragraph>
                        We define the <i>exploitation likelihood</i> (or simply <i>likelihood</i>) of a vulnerability as the probability that an attacker will attempt to exploit that vulnerability, if certain conditions are met.
                        We argue that the following variables are the main factors influencing an attacker’s decision to exploit a
                        given vulnerability, but the framework can be easily extended to include additional variables:
                        <br /><br />• The vulnerability’s exploitability score, as captured by CVSS.
                        <br />• The amount of time since information about the vulnerability became public.
                        <br />• The number of known Intrusion Detection System (IDS) rules associated with the
                        vulnerability.
                    </Typography>
                    <Typography paragraph>
                        The CVSS Exploitability score captures how easy it is to exploit a vulnerability, based
                        on different features captured by various metrics, most notably Access Vector (AV) and
                        Access Complexity (AC). The Access Vector metric reflects the context in which a vulnerability can be exploited. Its value is higher for vulnerabilities that can be exploited
                        remotely, and are therefore more likely to be exploited as the number of potential attackers is larger than the number of potential attackers that could exploit a vulnerability
                        requiring physical access to the vulnerable host. The Attack Complexity metric reflects
                        the amount of effort and resources required for a successful attack. Its value is higher for
                        exploits that require little or no effort, and are therefore more likely to be exploited.
                        The time passed since details about the vulnerability were made public also plays
                        a role in determining the likelihood of exploitation. In fact, the longer a vulnerability
                        has been known, the more exploits may have been developed by the hacker community. While it is true that the likelihood that patches have been developed also increases
                        with time, it is well-known that patches are not applied promptly and consistently across
                        systems, thus giving attackers a window of opportunity to target known but unpatched
                        vulnerabilities. Finally, the number of known IDS rules may influence the attacker’s choice of vulnerabilities to exploit. With systems typically exposing multiple vulnerabilities, attackers
                        may choose to avoid exploits that are more easily detectable. Based on these considerations, we define the exploitation likelihood
                        as a function <InlineMath>\rho: V \rightarrow [0,1]</InlineMath>, defined as:
                        <BlockMath math="\!\rho(v) \! = \! \frac{\left(1 \! -e^{-\alpha \cdot \sqrt{t(v)}}\right) \! \cdot \! \left(1 \! -e^{-\beta \cdot Exploitability(v)}\right)}{e^{\gamma \cdot |IDS_k(v)|}}\!\!\!" />
                        where <InlineMath>t(v)</InlineMath> is the time since vulnerability <InlineMath>v</InlineMath> was discovered, <InlineMath>Exploitability(v)</InlineMath> is the the
                        CVSS <i>Exploitability</i> score of <InlineMath>v</InlineMath>, and <InlineMath>IDS_k(v)</InlineMath> is the set of known IDS rules associated with
                        <InlineMath>~v</InlineMath>.
                    </Typography>
                    <Typography paragraph>
                        Each variable contributes to the overall likelihood as a multiplicative factor between
                        0 and 1 that is formulated to account for <i>diminishing returns</i>. Factors corresponding to
                        variables that contribute to increasing the likelihood are of the form <InlineMath math="1 - e^{-c \cdot f(x)}" />,
                        where <InlineMath>x</InlineMath> is the variable, <InlineMath>f()</InlineMath> is a function such that
                        <InlineMath math="~x_1 < x_2 \rightarrow f(x_1) < f(x_2)" />, and <InlineMath>c</InlineMath> is a constant.
                        Similarly, factors corresponding to variables that contribute to decreasing the likelihood are of the form
                        <InlineMath math="~e^{-c \cdot f(x)} = \frac{1}{e^{c \cdot f(x)}}" />.
                    </Typography>
                    <Typography paragraph>
                        The <i>exposure factor</i> (EF)  – as typically defined in risk analysis terminology – represents the relative
                        damage that an undesirable event – the exploitation of a vulnerability in our case – would cause to the affected asset.
                        The single loss expectancy (SLE) of such an incident is then computed as the product between its exposure factor
                        and the asset value (AV), that is SLE = EF <InlineMath>\times</InlineMath> AV.
                    </Typography>
                    <Typography paragraph>
                        Factors influencing the exposure factor include the CVSS impact score – which in turn considers the impact
                        on confidentiality, integrity, and availability – and the number of deployed IDS rules that can potentially
                        mitigate the consequences of an exploit. Formally, the exposure factor of a vulnerability v is defined as:
                    </Typography>
                    <Typography paragraph>
                        <BlockMath math="ef(v,h) = \frac{1-e^{-c\cdot impact(v)}}{e^{\delta \cdot |IDS_d(v)|}}" />
                    </Typography>
                    <Typography className={classes.subtitle} variant="subtitle1">Acknowledgements</Typography>
                    <Typography paragraph>The Mason Vulnerability Scoring Framework was developed as part of the SCIBORG project with Palo Alto Research Center (PARC), with funding from DARPA’s Configuration Security program under contract number FA8750-18-2-0147.</Typography>
                    <Typography paragraph>The framework was developed and is maintained by George Mason University, under the supervision of <a href="https://csis.gmu.edu/albanese/index.php" target="_blank">Dr. Massimiliano Albanese</a>. Several students contributed to the development of the various components of the framework: <a href="https://www.linkedin.com/in/ibifubara-fuby-iganibo/" target="_blank">Ibifubara Iganibo</a>, <a href="https://www.linkedin.com/in/kaan-t-0a4762134/" target="_blank">Kaan Turkmen</a>, and <a href="https://www.linkedin.com/in/thomasrichardcampbell/" target="_blank">Thomas Campbell.</a></Typography>
                    <Typography paragraph>The development of this application was funded in part by the <a href="https://www.cci-novanode.org/ " target="_blank" >Commonwealth Cyber Initiative (CCI), Northern Virginia (NoVa) Node</a> under the Undergraduate Research Assistant (URA) program.</Typography>
                    <Typography className={classes.subtitle} variant="subtitle1">References</Typography>
                    <Typography paragraph>[1] Iganibo, I., Albanese, M., Mosko, M., Bier, E. & Brito, A.E. "Vulnerability Metrics for Graph-Based Configuration Security". In Proceedings of the 18th International Conference on Security and Cryptography (SECRYPT 2021), SciTePress, July 6-8, 2021. <a href="https://doi.org/10.5220/0010559402590270" target="_blank">doi: 10.5220/0010559402590270</a> <Button onClick={() => openDialog(1)}>BibTeX</Button></Typography>
                    <Typography paragraph>[2] Soroush, H., Albanese, M., Asgari Mehrabadi, M., Iganibo, I., Mosko, M., Gao, J.H., Fritz, D.J., Rane, S. & Bier, E. "SCIBORG: Secure Configurations for the IoT Based on Optimization and Reasoning on Graphs". In Proceedings of the 8th IEEE Conference on Communications and Network Security (CNS 2020), IEEE, 2020. [Best Paper Award] <a href="https://doi.org/10.1109/CNS48642.2020.9162256" target="_blank">doi: 10.1109/CNS48642.2020.9162256</a> <Button onClick={() => openDialog(2)}>BibTeX</Button></Typography>
                    <hr />
                    <Typography paragraph><b>Disclaimer:</b> CVE and CWE are trademarks of The MITRE Corporation.</Typography>
                    <Grid container style={{ marginBottom: "5%", marginTop: "5%" }} spacing={10} alignItems="center" justifyContent="center">
                        <Grid item xs>
                            <a href="https://gmu.edu" target="_blank">
                                <img src={masonLogo} style={{ marginBottom: "5%" }} width="100%" height="50%" />
                            </a>
                        </Grid>
                        <Grid item xs>
                            <a href="https://www.parc.com/" target="_blank">
                                <img src={parcLogo} style={{ marginTop: "20%" }} width="100%" height="50%" />
                            </a>
                        </Grid>
                        <Grid item xs>
                            <a href="https://www.darpa.mil/" target="_blank">
                                <img src={darpaLogo} width="100%" height="50%" />
                            </a>
                        </Grid>
                        <Grid item xs>
                            <a href="https://csis.gmu.edu" target="_blank">
                                <img src={csisLogo} width="80%" height="40%" />
                            </a>
                        </Grid>
                    </Grid>
                </Container>
            </Container>
            <Footer />
            <BibTexDialog open={bibtexDialog} text={bibtex} onClose={toggleBibtexDialog} />
        </Box>
    )
}
