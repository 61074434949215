// API_URL Settings
// export const API_URL = "http://3.143.243.207" // AWS
// export const API_URL = "http://localhost" //  Local
// export const API_URL = "https://ist-mvsf.vsnet.gmu.edu" // Production
export const API_URL = process.env.REACT_APP_BACKEND_URL ?? "https://mvsf.gmu.edu"

export const CAPTCHA_SITE_KEY = "6LcsXhgdAAAAAJ7uRzfUsvRzZatYpD7iRMmoDi3d"
export const CAPTCHA_SECRET_KEY = "6LcsXhgdAAAAAE_rm5mtR5XlgEQnlkyu0SbPCGfW"

// Header and Footer Settings/Variables
export const FOOTER_MARGIN_INT = 20
export const FOOTER_HEIGHT_INT = 320
export const HEADER_HEIGHT_INT = 64

export const FOOTER_MARGIN = FOOTER_MARGIN_INT + "px"
export const FOOTER_HEIGHT = FOOTER_HEIGHT_INT + "px"
export const FOOTER_HEIGHT_W_MARGIN = FOOTER_HEIGHT_INT + FOOTER_MARGIN_INT + "px"
export const TOTAL_HF_HEIGHT = FOOTER_HEIGHT_INT + FOOTER_MARGIN_INT + HEADER_HEIGHT_INT + "px"

export const MAP_HEIGHT = "55vh"
export const MAP_WIDTH = 1000
