import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Grid, ListItem, ListItemText, Link } from '@material-ui/core';
import { EmailOutlined, RoomOutlined, PhoneOutlined, LanguageOutlined } from '@material-ui/icons';
import mvsfLogoBlack from '../images/mvsf-logo-black.png';
import * as urls from '../urls';
import { FOOTER_HEIGHT, FOOTER_MARGIN,  } from '../settings';

const useStyles = makeStyles((theme) => ({
    container: {
        width: "100%",
        // Get footer height from settings
        height: FOOTER_HEIGHT,
        backgroundColor: "black",
        color: "white",
        // Get top margin height from settings
        marginTop: FOOTER_MARGIN,
        paddingTop: "1%",
        paddingBottom: "2%",
    },
    link: {
        textDecoration: "none",
        color: "white",
        width: "100%"
    },
    icon: {
        marginRight: "5%"
    },
    iconBox: {
        display: "flex",
        marginTop: "2%",
        alignItems: 'center',
    },
    disclaimerSection: {
        textAlign: "center",
    }
}))

/**
 * Create website-wide footer
 * 
 * @author Kaan Turkmen
 * @editor Thomas Campbell
 * @comments Thomas Campbell
 * @returns Footer at bottom of a page
 */
export default function Footer() {
    const classes = useStyles();

    // Master Return Statement - Comment: TRC, Author: KT
    return (
        <Grid container className={classes.container}>
            <Grid item xs={2} />
            <Grid item xs={3}>
                <ListItem button key="footer-historical">
                    <Link href="/" className={classes.link}><ListItemText>Home</ListItemText></Link>
                </ListItem>
                <ListItem button key="footer-about">
                    <Link href={urls.aboutURL} className={classes.link}>
                        <ListItemText>About</ListItemText>
                    </Link>
                </ListItem>
                <ListItem button key="footer-custom">
                    <Link href={urls.historicalRankingsURL} className={classes.link}>
                        <ListItemText>Historical Rankings</ListItemText>
                    </Link>
                </ListItem>
                <ListItem button key="footer-custom">
                    <Link href={urls.liveRankingsURL} className={classes.link}>
                        <ListItemText>Live Rankings</ListItemText>
                    </Link>
                </ListItem>
                <ListItem button key="footer-generate">
                    <Link href={urls.customRankingsURL} className={classes.link}>
                        <ListItemText>Custom Rankings</ListItemText>
                    </Link>
                </ListItem>
                {/* <ListItem button key="footer-documents">
                    <Link href="/documents" className={classes.link}>
                        <ListItemText>Documents</ListItemText>
                    </Link>
                </ListItem> */}
            </Grid>
            <Grid item xs={2}>
                <Typography variant="subtitle1" style={{ fontWeight: 'bold', marginLeft: "15%" }}>Contact Us</Typography>
                <Box className={classes.iconBox}>
                    <RoomOutlined className={classes.icon} />
                    <Typography>Center for Secure Information Systems<br />
                        Research Hall, Suite 417<br />
                        George Mason University<br />
                        4400 University Dr.<br />
                        Fairfax, VA 22030<br />
                        United States of America</Typography>
                </Box>
            </Grid>
            <Grid item xs={2}>
                <Box className={classes.iconBox}>
                    <a href="mailto:malbanes@gmu.edu" style={{ textDecoration: "none", color: "inherit", marginRight: "5%" }}>
                        <EmailOutlined className={classes.icon} />
                    </a>
                    <Typography>malbanes@gmu.edu</Typography>
                </Box>
                <Box className={classes.iconBox}>
                    <PhoneOutlined className={classes.icon} />
                    <Typography> +1 (703) 993-1629</Typography>
                </Box>
                <Box className={classes.iconBox}>
                    <LanguageOutlined className={classes.icon} />
                    <Link href="https://csis.gmu.edu" target="_href" style={{ textDecoration: "none", color: "inherit", display: "flex", alignItems: "center" }}>
                        <Typography style={{ marginLeft: "5%" }}>csis.gmu.edu</Typography>
                    </Link>
                </Box>
                <Box style={{ marginRight: "30%", marginTop: "5%" }}>
                        <img src={mvsfLogoBlack} height="100%" width="100%" />
                    </Box>
            </Grid>
            <Grid item xs={3} />
            <Grid item xs className={classes.disclaimerSection}>
                <Typography paragraph><i>Disclaimer:</i>    CVE and CWE are trademarks of The MITRE Corporation.</Typography>
            </Grid>
        </Grid>
    )
}
